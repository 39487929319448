import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { GROUP_TYPE_PAID_PLAN } from '../../../constants/manage-category-members';
import withTranslate from '../../../hoc/with-translate';
import withFontClassName from '../../../hoc/with-font-class-name';
import { getCategoryById } from '../../../../recent-posts-widget/selectors/categories-selectors';
import { getMetaSiteId } from '../../../../common/store/instance-values/instance-values-selectors';
import ManageCategoryMembersListItem from './manage-category-members-list-item';
import ManageCategoryMembersBaseModal from './manage-category-members-base-modal-component';
import { getAllPaidPlans } from '../../../selectors/paid-plans-selectors';

class ManageCategoryMembersPlansModal extends Component {
  render() {
    const { category, metaSiteId, paidPlans, fetchPaidPlans, closeModal } = this.props;
    const managePaidPlansLink = `https://www.wix.com/dashboard/${metaSiteId}/membership/`;
    const createNewPaidPlanLink = `https://www.wix.com/dashboard/${metaSiteId}/membership/setup`;

    return (
      <ManageCategoryMembersBaseModal
        closeModal={closeModal}
        category={category}
        groupType={GROUP_TYPE_PAID_PLAN}
        createNewGroupLink={createNewPaidPlanLink}
        manageGroupsLink={managePaidPlansLink}
        titleTextKey="manage-category-members-plans-modal.title"
        bottomCtaTextKey="manage-category-members-modal.manage-plans-link"
        onGroupAdded={fetchPaidPlans}
        emptyStateTitleTextKey="manage-category-members-plans-empty-state.you-dont-have-any-plans"
        emptyStateDescriptionTextKey="manage-category-members-plans-empty-state.to-create-plans-go-to"
        emptyStateCtaTextKey="manage-category-members-plans-empty-state.cta"
        searchEmptyStateTextKey="manage-category-members-plans-search-empty-state.no-results-found"
        renderListItem={(group, formApi) => (
          <ManageCategoryMembersListItem
            isPlan
            group={group}
            paidPlan={paidPlans.find(p => p.id === group.id)}
            formApi={formApi}
            category={category}
          />
        )}
      />
    );
  }
}

ManageCategoryMembersPlansModal.propTypes = {
  closeModal: PropTypes.func,
  fetchPaidPlans: PropTypes.func,
  category: PropTypes.object,
  metaSiteId: PropTypes.string,
  paidPlans: PropTypes.array,
  t: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  category: getCategoryById(state, ownProps.categoryId),
  fetchPaidPlans: actions.fetchPaidPlans,
  metaSiteId: getMetaSiteId(state),
  paidPlans: getAllPaidPlans(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withFontClassName,
  withTranslate,
)(ManageCategoryMembersPlansModal);
