import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { DiscardIcon } from '../../icons/discard-icon';
import { ModalCentered } from '../base';
import Confirmation from '../../confirmation';

import withTranslate from '../../../hoc/with-translate';

export const DiscardCategoryChangesModal = ({ closeModal, discardChanges, t }) => (
  <ModalCentered onClose={closeModal}>
    <Confirmation
      icon={DiscardIcon}
      title={t('discard-category-changes-modal.title')}
      confirm={t('discard-category-changes-modal.discard')}
      cancel={t('discard-category-changes-modal.cancel')}
      onConfirm={discardChanges}
      onCancel={closeModal}
    >
      <p>{t('discard-category-changes-modal.description')}</p>
    </Confirmation>
  </ModalCentered>
);

DiscardCategoryChangesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  discardChanges: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps) => ({
  discardChanges: () =>
    ownProps.closeModal({
      shouldCloseManager: ownProps.shouldCloseManager,
      isForumData: ownProps.isForumData,
    }),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(DiscardCategoryChangesModal);
