import React from 'react';
import PropTypes from 'prop-types';
import { HorizontalSeparator } from '../../separator';
import Button from '../../button';
import styles from './categories-manager-footer-buttons.scss';

const CategoriesManagerFooterButtons = ({
  onClickLeft,
  onClickRight,
  leftText,
  rightText,
  isLeftMuted = false,
  isRightMuted = false,
  isRightLoading = false,
  leftTooltipText,
  rightTooltipText,
}) => (
  <div className={styles.footer} data-hook="categories-manager-footer-buttons">
    <HorizontalSeparator />
    <div className={styles.buttons}>
      {onClickLeft && (
        <Button
          onClick={onClickLeft}
          isMuted={isLeftMuted}
          tooltipText={leftTooltipText}
          isSecondary
          className={styles.buttonLeft}
          data-hook="leftButton"
        >
          {leftText}
        </Button>
      )}
      {onClickRight && (
        <Button
          onClick={onClickRight}
          isMuted={isRightMuted}
          disabled={isRightMuted}
          tooltipText={rightTooltipText}
          isLoading={isRightLoading}
          data-hook="rightButton"
        >
          {rightText}
        </Button>
      )}
    </div>
  </div>
);

CategoriesManagerFooterButtons.propTypes = {
  onClickLeft: PropTypes.func,
  onClickRight: PropTypes.func,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  isLeftMuted: PropTypes.bool,
  isRightMuted: PropTypes.bool,
  isRightLoading: PropTypes.bool,
  leftTooltipText: PropTypes.string,
  rightTooltipText: PropTypes.string,
};

export default CategoriesManagerFooterButtons;
