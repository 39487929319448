import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { ModalCentered } from '../base';
import Confirmation from '../../confirmation/confirmation';
import withTranslate from '../../../hoc/with-translate';

export const MoveCategoryConfirmation = ({ closeModal, moveCategory, t, child, parent }) => (
  <ModalCentered onClose={closeModal}>
    <Confirmation
      confirm={t('move-category-confirmation.confirm')}
      cancel={t('move-category-confirmation.cancel')}
      title={t('move-category-confirmation.title')}
      onConfirm={moveCategory}
      onCancel={closeModal}
    >
      <p>{t('move-category-confirmation.are-you-sure', { child, parent })}</p>
    </Confirmation>
  </ModalCentered>
);

MoveCategoryConfirmation.propTypes = {
  closeModal: PropTypes.func.isRequired,
  moveCategory: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps) => ({
  moveCategory: () => ownProps.closeModal(true),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(MoveCategoryConfirmation);
