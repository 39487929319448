import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { MODAL_TYPE_CATEGORIES_MANAGER } from '../../components/modals/categories-manager/categories-manager-modal-type';
import { MODAL_TYPE_DELETE_CATEGORY } from '../../components/modals/delete-category-modal/delete-category-modal-type';
import { MODAL_TYPE_UNFOLLOW_CATEGORY } from '../../components/modals/unfollow-category-modal/unfollow-category-modal-type';
import { SubscribeIcon } from '../../components/icons/subscribe-icon';
import { DeleteIcon } from '../../components/icons/delete-icon';
import { EditIcon } from '../../components/icons/edit-icon';
import { GearIcon } from '../../components/icons/gear-icon';
import ActionButton from '../../components/action-button';
import { getCategoryCount } from '../../selectors/categories-selectors';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../hoc/with-translate';
import withAuth from '../../hoc/with-auth';
import ensureAuth from '../../hoc/ensure-auth';
import withDividerColor from '../../hoc/with-divider-color';
import styles from './category-actions.scss';
import { isPrivate } from '../../../common/services/category-privacy-utils';
import { MANAGE_MEMBERS } from '../../actions/categories-manager-actions';
import { LockEmptyIcon } from '../../components/icons/lock-empty-icon';

const ActionButtonAuth = ensureAuth(ActionButton);

export class CategoryActions extends Component {
  componentDidMount() {
    this.props.actionsOpened({ type: null });
  }

  componentWillUnmount() {
    this.props.actionsClosed({ type: null });
  }

  handleSubscribe = () => {
    const {
      category: { _id },
      subscribe,
      buttonClicked,
    } = this.props;
    buttonClicked({ action: 'follow_category', flag: 1 });

    return subscribe(_id);
  };

  handleUnsubscribe = () => {
    const {
      category: { _id },
      buttonClicked,
    } = this.props;
    buttonClicked({ action: 'follow_category', flag: 0 });

    this.props.openModal(MODAL_TYPE_UNFOLLOW_CATEGORY, { categoryId: _id });
  };

  handleEdit = () => {
    this.props.buttonClicked({ action: 'edit_category' });
    this.props.openModal(MODAL_TYPE_CATEGORIES_MANAGER, { categoryId: this.props.category._id });
  };

  handleDelete = () => {
    this.props.buttonClicked({ action: 'delete_category' });
    this.props.openModal(MODAL_TYPE_DELETE_CATEGORY, { categoryId: this.props.category._id });
  };

  handleManageCategories = () => {
    this.props.buttonClicked({ action: 'manage_category' });
    this.props.openModal(MODAL_TYPE_CATEGORIES_MANAGER, { categoryId: null });
  };

  render() {
    const {
      canRender,
      category,
      t,
      forPublicUser,
      isOneCategoryRemaining,
      isOwner,
      hideFollowButton,
      dividerColor,
    } = this.props;
    const followButton =
      !hideFollowButton &&
      canRender('subscribe', 'category', category, can => {
        const action = category.isSubscribed ? this.handleUnsubscribe : this.handleSubscribe;
        const ActionComponent = can ? ActionButton : ActionButtonAuth;
        return (
          <ActionComponent
            dataHook="category-actions__subscriptions"
            onClick={can ? action : forPublicUser(action)}
          >
            <SubscribeIcon />
            {t(
              category.isSubscribed
                ? 'category-actions.unsubscribe-category'
                : 'category-actions.subscribe-category',
            )}
          </ActionComponent>
        );
      });

    const editButton = isOwner ? (
      <ActionButton dataHook="category-actions__edit" onClick={this.handleEdit}>
        <EditIcon />
        {t('category-actions.edit-category')}
      </ActionButton>
    ) : null;

    const deleteButton =
      isOwner && !isOneCategoryRemaining ? (
        <ActionButton dataHook="category-actions__delete" onClick={this.handleDelete}>
          <DeleteIcon />
          {t('category-actions.delete-category')}
        </ActionButton>
      ) : null;

    const manageCategoriesButton = canRender('edit', 'category', can => {
      return can ? (
        <ActionButton dataHook="category-actions__manage" onClick={this.handleManageCategories}>
          <GearIcon />
          {t('breadcrumbs-actions.manage-categories')}
        </ActionButton>
      ) : null;
    });

    const manageMembersButton = canRender('edit', 'category', can => {
      return isPrivate(category) && can ? (
        <ActionButton
          dataHook="category-actions__manage-members"
          onClick={this.handleManageMembers}
        >
          <LockEmptyIcon className="forum-icon-fill" />
          {t('category-actions.manage-category-members')}
        </ActionButton>
      ) : null;
    });

    const divider = manageCategoriesButton && (
      <div className={styles.divider} style={{ backgroundColor: dividerColor }} />
    );
    const shouldRenderDivider =
      (followButton || editButton || deleteButton) && manageCategoriesButton;

    return (
      <div>
        {followButton}
        {editButton}
        {manageMembersButton}
        {deleteButton}
        {shouldRenderDivider && divider}
        {manageCategoriesButton}
      </div>
    );
  }

  handleManageMembers = () => {
    this.props.buttonClicked({ action: 'manage_category_members' });
    this.props.openModal(MODAL_TYPE_CATEGORIES_MANAGER, {
      categoryId: this.props.category._id,
      openPanels: [MANAGE_MEMBERS],
    });
  };
}

CategoryActions.propTypes = {
  dividerColor: PropTypes.string,
  category: PropTypes.object.isRequired,
  openModal: PropTypes.func,
  subscribe: PropTypes.func,
  canRender: PropTypes.func,
  actionsOpened: PropTypes.func,
  actionsClosed: PropTypes.func,
  buttonClicked: PropTypes.func,
  t: PropTypes.func,
  forPublicUser: PropTypes.func,
  isOwner: PropTypes.bool,
  isOneCategoryRemaining: PropTypes.bool,
  hideFollowButton: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  isOneCategoryRemaining: getCategoryCount(state) <= 1,
  subscribe: actions.subscribeToCategory,
  openModal: actions.openModal,
  actionsOpened: actions.actionsOpened,
  actionsClosed: actions.actionsClosed,
  buttonClicked: data => actions.buttonClicked({ name: 'action_click', type: 'post', ...data }),
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withDividerColor,
  withPermissions,
  withTranslate,
  withAuth,
)(CategoryActions);
