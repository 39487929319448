import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import { VoteUpIcon, VoteUpIconMobile } from './vote-up-icon';
import { VoteDownIcon, VoteDownIconMobile } from './vote-down-icon';
import { compactNumber } from '../../services/format-number';
import { getVoteCount } from '../../selectors/comment-selectors';
import { getLanguage } from '../../../common/store/basic-params/basic-params-selectors';
import withDeviceType from '../../hoc/with-device-type';
import ensureAuth from '../../hoc/ensure-auth';
import styles from './comment-vote.scss';

const voteType = {
  UP: 'up',
  DOWN: 'down',
};

const getVoteType = (isUpvoted, isDownvoted) => {
  if (isUpvoted) {
    return voteType.UP;
  }
  if (isDownvoted) {
    return voteType.DOWN;
  }
  return null;
};

class CommentVote extends Component {
  state = {
    voteCount: this.props.voteCount,
    voted: getVoteType(this.props.isUpvoted, this.props.isDownvoted),
  };

  vote = type => () => {
    const { voteCount, voted } = this.state;
    const { upvoteComment, downvoteComment, unvoteComment } = this.props;
    const add = type === voteType.UP ? 1 : -1;

    if (voted !== null && type === voted) {
      unvoteComment();
      return this.setState({ voteCount: voteCount - add, voted: null });
    }

    if (type === voteType.UP) {
      upvoteComment();
    } else {
      downvoteComment();
    }

    if (voted !== null && type !== voted) {
      return this.setState({ voteCount: voteCount + add * 2, voted: type });
    }
    this.setState({ voteCount: voteCount + add, voted: type });
  };

  renderVoteIcon = type => {
    const { voted } = this.state;
    const { isMobile } = this.props;
    const Icon =
      type === voteType.UP
        ? isMobile
          ? VoteUpIconMobile
          : VoteUpIcon
        : isMobile
        ? VoteDownIconMobile
        : VoteDownIcon;
    const IconSecure = ensureAuth(Icon);
    const onClick = this.vote(type);
    const isVoted = voted === type;
    return (
      <button className={styles.voteIcon}>
        <IconSecure
          className={classNames(
            { 'button-hover-fill': !isMobile },
            isVoted ? 'button-fill' : 'icon-fill',
          )}
          onClick={onClick}
        />
      </button>
    );
  };

  render() {
    const { voteCount } = this.state;
    const { language } = this.props;
    const compactNumberLocal = compactNumber(language);
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          {this.renderVoteIcon(voteType.UP)}
          <span
            className={classNames(styles.voteCount, 'forum-text-color', 'forum-content-small-font')}
          >
            {compactNumberLocal(voteCount)}
          </span>
          {this.renderVoteIcon(voteType.DOWN)}
        </div>
      </div>
    );
  }
}

CommentVote.propTypes = {
  comment: PropTypes.object,
  language: PropTypes.string,
  isMobile: PropTypes.string,
  upvoteComment: PropTypes.func,
  downvoteComment: PropTypes.func,
  unvoteComment: PropTypes.func,
  voteCount: PropTypes.number,
  isUpvoted: PropTypes.bool,
  isDownvoted: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const { postId, _id: commentId, isUpvoted, isDownvoted } = ownProps.comment;

  return {
    language: getLanguage(state),
    upvoteComment: () => actions.upvoteComment({ postId, commentId }),
    downvoteComment: () => actions.downvoteComment({ postId, commentId }),
    unvoteComment: () => actions.unvoteComment({ postId, commentId }),
    voteCount: getVoteCount(ownProps.comment),
    isUpvoted,
    isDownvoted,
  };
};

export default flowRight(connect(mapRuntimeToProps), withDeviceType)(CommentVote);
