/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DragIconTall } from '../../icons/drag-icon-tall';
import { ExpandIcon } from '../../icons/expand-icon';
import { CollapseIcon } from '../../icons/collapse-icon';
import styles from './manage-categories-card-dnd-part.scss';

class ManageCategoriesCardDndPart extends Component {
  state = {
    isHovering: false,
  };

  onMouseIn = () => this.setState({ isHovering: true });
  onMouseOut = () => this.setState({ isHovering: false });

  render() {
    const {
      isParent,
      showChildren,
      hideChildren,
      isChildrenHidden,
      iconsColor,
      darkIconColor,
      iconActiveColor,
      connectDragSource,
      isDragPreview,
      showDropPlaceholder,
    } = this.props;
    const dragIcon = (
      <div>
        <DragIconTall
          className={styles.dragIcon}
          style={{ fill: isDragPreview ? iconActiveColor : iconsColor }}
        />
      </div>
    );
    return (
      <div className={styles.container}>
        {isParent && !isDragPreview && !showDropPlaceholder && (
          <div
            onMouseEnter={this.onMouseIn}
            onMouseLeave={this.onMouseOut}
            className={classNames(styles.showHideIcon, 'default-forum-card-background-color')}
            onClick={isChildrenHidden ? showChildren : hideChildren}
          >
            {isChildrenHidden ? (
              <ExpandIcon
                style={{ fill: this.state.isHovering ? iconActiveColor : darkIconColor }}
              />
            ) : (
              <CollapseIcon
                style={{ fill: this.state.isHovering ? iconActiveColor : darkIconColor }}
              />
            )}
          </div>
        )}
        {isDragPreview ? dragIcon : connectDragSource(dragIcon)}
      </div>
    );
  }
}

ManageCategoriesCardDndPart.propTypes = {
  isParent: PropTypes.bool,
  showChildren: PropTypes.func,
  hideChildren: PropTypes.func,
  connectDragSource: PropTypes.func,
  isChildrenHidden: PropTypes.bool,
  iconsColor: PropTypes.string,
  darkIconColor: PropTypes.string,
  iconActiveColor: PropTypes.string,
  isDragPreview: PropTypes.bool,
  showDropPlaceholder: PropTypes.bool,
};

export default ManageCategoriesCardDndPart;
