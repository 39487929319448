import { getOffsetForIndex, hasDataForIndex } from './site-members-selectors';

const createCache = () => {
  const c = {};
  return {
    get: args => {
      const match = c[args.join('')];
      if (match && match.timeout > Date.now()) {
        return match.value;
      } else {
        return null;
      }
    },
    add: (args, value, duration = 10000) => {
      c[args.join('')] = {
        value,
        timeout: Date.now() + duration,
      };
    },
  };
};

const localCache = createCache();

export const fetchMembersBucketForIndex = (fetchSiteMembersList, state) => (query, index) => {
  const offsetForIndex = getOffsetForIndex(index);
  if (hasDataForIndex(state, query, index) || localCache.get([query, offsetForIndex])) {
    return;
  }

  localCache.add([query, offsetForIndex], true);
  return fetchSiteMembersList({ query, offset: offsetForIndex });
};
