import React from 'react';
import PropTypes from 'prop-types';
import ensureAuth from '../../hoc/ensure-auth';
import withTranslate from '../../hoc/with-translate';
import classNames from 'classnames';
import { QUESTION } from '../../constants/post-types';
import { CREATE_NEW_QUESTION } from '../../constants/interactions';
import { QuestionIcon } from '../icons/question-icon';
import CreatePostHandler from '../../containers/create-post-handler';
import Button from '../button';
import ProtectedButton from '../../containers/protected-button';
import styles from './create-question-button.scss';

const ButtonSecure = ensureAuth(Button);

const QuestionPostButton = ({ categorySlug, t, isSmall = true, isExtended }) => {
  const savedAction = {
    action: CREATE_NEW_QUESTION,
    args: [categorySlug ? `/${categorySlug}/create-question` : '/create-question'],
  };
  const renderExtended = () => (
    <ProtectedButton actionDetails={savedAction} role="menuitem">
      <div
        className={classNames(
          'forum-text-color',
          'button-hover-color',
          'button-hover-fill',
          'button-hover-stroke',
          'forum-icon-fill',
          'forum-icon-stroke',
          styles.container,
        )}
      >
        <div className={styles.icon}>
          <QuestionIcon />
        </div>
        <div className={styles.text}>
          <span className={styles.title}>{t('create-post-button.create-new-question-title')}</span>
          <span className={styles.info}>{t('create-post-button.create-new-question-info')}</span>
        </div>
      </div>
    </ProtectedButton>
  );
  return (
    <CreatePostHandler categorySlug={categorySlug} postType={QUESTION}>
      {isExtended ? (
        renderExtended()
      ) : (
        <ButtonSecure data-hook="create-question" actionDetails={savedAction} isSmall={isSmall}>
          {t('create-post-button.create-new-question')}
        </ButtonSecure>
      )}
    </CreatePostHandler>
  );
};

QuestionPostButton.propTypes = {
  categorySlug: PropTypes.string,
  t: PropTypes.func.isRequired,
  isExtended: PropTypes.bool,
  isSmall: PropTypes.bool,
};

export default withTranslate(QuestionPostButton);
