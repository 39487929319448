import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ArrowDown } from '../icons/arrow-down';
import withFontClassName from '../../hoc/with-font-class-name';
import withDeviceType from '../../hoc/with-device-type';
import styles from './accordion-item.scss';
import { HorizontalSeparator } from '../separator';

class AccordionItem extends Component {
  constructor(props) {
    super(props);
    this.button = React.createRef();
  }

  state = {
    height: this.props.isOpen ? '100%' : 0,
  };

  updateHeight(oldHeight) {
    const height = `${this.contentElement.scrollHeight}px`;
    if (oldHeight !== height) {
      this.setState({ height });
    }
  }

  componentDidMount() {
    this.updateHeight(this.state.height);
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateHeight(prevState.height);
  }

  renderArrow = () => {
    const className = this.props.isOpen ? styles.arrowUp : undefined;
    return (
      <span className={classNames(styles.arrow, className)}>
        <ArrowDown />
      </span>
    );
  };

  render() {
    const {
      title,
      content,
      isOpen,
      toggle,
      contentFontClassName,
      isDesktop,
      showSeparator,
    } = this.props;
    const { height } = this.state;
    return (
      <div className={styles.container} key={title}>
        <button
          className={classNames(
            styles.titleContainer,
            'forum-text-color',
            'forum-icon-fill',
            { 'button-hover-color': isDesktop },
            { 'button-hover-fill': isDesktop },
          )}
          aria-expanded={isOpen}
          onClick={() => toggle(this.button.current)}
          role="region"
          ref={this.button}
        >
          <div
            className={classNames(styles.title, 'forum-content-medium-font', contentFontClassName)}
          >
            {title}
          </div>
          {this.renderArrow()}
        </button>
        <div
          className={classNames(styles.contentWrapper)}
          style={{ height: isOpen ? height : 0, visibility: isOpen ? 'visible' : 'hidden' }}
          ref={contentElement => (this.contentElement = contentElement)}
          aria-hidden={!isOpen}
          role="region"
        >
          <div className={styles.content}>{content}</div>
        </div>
        {showSeparator && <HorizontalSeparator />}
      </div>
    );
  }
}

AccordionItem.defaultProps = {
  isOpen: false,
  showSeparator: true,
};

AccordionItem.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  showSeparator: PropTypes.bool,
};

export default withDeviceType(withFontClassName(AccordionItem));
