import { noop } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AccordionItem from './accordion-item';

class Accordion extends Component {
  state = {
    itemStates: this.props.items.map(item => ({ title: item.title, isOpen: item.isOpen })),
  };

  toggle = title => element => {
    this.setState(
      {
        itemStates: this.state.itemStates.map(item =>
          item.title === title ? { ...item, isOpen: !item.isOpen } : item,
        ),
      },
      () => {
        const { onExpandItem = noop } = this.props;
        const item = this.state.itemStates.find(i => i.title === title);
        item.isOpen && onExpandItem(element, this.state.itemStates.indexOf(item));
      },
    );
  };

  isOpen = title => this.state.itemStates.find(item => item.title === title).isOpen;

  render() {
    const { items } = this.props;
    return items.map((item, index) => (
      <AccordionItem
        title={item.title}
        content={item.content}
        isOpen={this.isOpen(item.title)}
        toggle={this.toggle(item.title)}
        showSeparator={index !== items.length - 1}
      />
    ));
  }
}

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      content: PropTypes.node.isRequired,
      isOpen: PropTypes.bool.isRequired,
    }),
  ),
  onExpandItem: PropTypes.func,
};

export default Accordion;
