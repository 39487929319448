import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { GatewayDest } from 'react-gateway';

const TextEditorMobileToolbar = ({ theme }) => (
  <div
    className={classNames(get(theme, 'container'), 'text-editor-mobile-toolbar')}
    style={get(theme, 'containerStyle')}
  >
    <div style={{ height: 50 }}>
      <GatewayDest name="mobile-header" />
    </div>
  </div>
);

TextEditorMobileToolbar.propTypes = {
  theme: PropTypes.object,
};

export default TextEditorMobileToolbar;
