import { isNil, get, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { CREATE_POST } from '../../constants/interactions';
import { getCurrentUserId } from '../../../common/store/current-user/current-user-selectors';
import {
  getCategoryIdBySlug,
  getAllCategoryCount,
  getAllCategories,
} from '../../selectors/categories-selectors';
import PostPageLayout from '../../components/post-page-layout';
import PostForm from '../../components/post-form';
import {
  convertContentStateToContent,
  convertPostContentToContentState,
} from '../../services/post-utils';
import { MODAL_TYPE_CHOOSE_CATEGORY } from '../../components/modals/choose-category-modal/choose-category-modal-type';
import { MODAL_TYPE_DISCARD_POST } from '../../components/modals/discard-post-modal/discard-post-modal-type';
import { ROUTE_CREATE_QUESTION, ROUTE_CATEGORY_CREATE_QUESTION } from '../../constants/routes';
import { QUESTION, DISCUSSION } from '../../constants/post-types';
import { getRouteParams, getRoute } from '../../../common/router/router-selectors';
import postFormSettings from '../../services/post-form-settings';
import { withFastForm } from '../../../common/components/fast-form';
import {
  enableBrowserRefreshPrompt,
  disableBrowserRefreshPrompt,
} from '../../../common/services/browser-refresh-prompt-service';

export class PostCreatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        ownerId: props.ownerId,
        categoryId: props.categoryId,
        postType: this.isQuestionPostType() ? QUESTION : DISCUSSION,
      },
    };
  }

  componentDidMount() {
    enableBrowserRefreshPrompt();
  }

  componentWillUnmount() {
    disableBrowserRefreshPrompt();
  }

  isQuestionPostType = () =>
    this.props.route === ROUTE_CREATE_QUESTION ||
    this.props.route === ROUTE_CATEGORY_CREATE_QUESTION;

  isInCategory = () => !isNil(this.props.categoryId);

  handleSubmit = ({ values: postRaw }) => {
    this.props.interactionStarted(CREATE_POST);
    this.props
      .createPostAndNavigateToItPromisified(convertContentStateToContent(postRaw))
      .then(response => {
        if (response && !response.isSuccessful && response.status === 403) {
          this.props.fastForm.stopSubmit();
        }
      });
  };

  handleSubmitButtonClick = submit => {
    const { categoryId, openModal, fastForm } = this.props;

    if (isNil(categoryId) && isNil(fastForm.values.categoryId)) {
      openModal(MODAL_TYPE_CHOOSE_CATEGORY).then(r => {
        this.props.fastForm.changeValue('categoryId')(r.categoryId, () => {
          submit();
        });
      });
    } else {
      submit();
    }
  };

  handleCancel = isModified => {
    const { categorySlug } = this.props.params;
    if (isModified) {
      return this.props.openModal(MODAL_TYPE_DISCARD_POST, {
        categorySlug: this.isInCategory() ? categorySlug : null,
      });
    } else {
      return this.props.goBack();
    }
  };

  render() {
    return (
      <PostPageLayout className="post-create-page">
        <PostForm
          onSubmit={this.handleSubmit}
          onSubmitButtonClick={this.handleSubmitButtonClick}
          onCancel={this.handleCancel}
          formInitialValues={convertPostContentToContentState(this.state.initialValues)}
          showCategorySelect={!this.isInCategory()}
          postType={this.isQuestionPostType() ? QUESTION : DISCUSSION}
        />
      </PostPageLayout>
    );
  }
}

PostCreatePage.propTypes = {
  createPostAndNavigateToItPromisified: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  submit: PropTypes.func.isRequired,
  ownerId: PropTypes.string.isRequired,
  categoryId: PropTypes.string,
  post: PropTypes.object,
  params: PropTypes.object,
  interactionStarted: PropTypes.func,
  fastForm: PropTypes.object,
  route: PropTypes.string,
};

const mapDispatchToProps = (state, ownProps, actions) => {
  const categoryCount = getAllCategoryCount(state);
  const categories = getAllCategories(state);
  const params = getRouteParams(state);
  let categoryId = getCategoryIdBySlug(state, params.categorySlug);

  if (isNil(categoryId) && categoryCount === 1) {
    categoryId = get(categories[0], '_id', null);
  }

  return {
    params,
    route: getRoute(state),
    ownerId: getCurrentUserId(state),
    categoryId,
    createPostAndNavigateToItPromisified: actions.createPostAndNavigateToItPromisified,
    goBack: () => actions.goBack(),
    openModal: actions.openModal,
    interactionStarted: actions.interactionStarted,
  };
};

export default flowRight(
  withFastForm(postFormSettings, { skipInitialize: true }),
  connect(mapDispatchToProps),
)(PostCreatePage);
