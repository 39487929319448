import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputRadio from '../input-radio';
import { isQACategoriesExperimentEnabled } from '../../selectors/experiments-selectors';
import styles from './radio-list.scss';
import withTranslate from '../../hoc/with-translate';
import { connect } from '../../../common/components/runtime-context';

class RadioList extends Component {
  render() {
    const { id, options, onChange, t, isQACategoriesEnabled } = this.props;
    return (
      <div
        id={id}
        className={classNames(styles.radioGroup, 'forum-card-border-color')}
        role="radiogroup"
      >
        {options.map(
          (
            {
              value,
              label,
              isIndented,
              Icon,
              isDisabled,
              isWrongPostType,
              isCurrentCategory,
              postTypes,
            },
            i,
          ) => (
            <div
              key={i}
              style={{ paddingLeft: isIndented ? '12px' : 'auto' }}
              className={classNames(styles.row, 'forum-card-border-color')}
            >
              <div className={styles.input}>
                <InputRadio
                  name={name}
                  value={value}
                  onChange={() => onChange(value)}
                  checked={value === this.props.value}
                  text={label}
                  tabIndex={String(i - 1)}
                  disabled={isDisabled}
                />
                {Icon && <Icon className={styles.icon} />}
              </div>
              <div>
                {isQACategoriesEnabled && isCurrentCategory && t('category-radio.current-category')}
                {isQACategoriesEnabled &&
                  !isCurrentCategory &&
                  isWrongPostType &&
                  t(`category-radio.${postTypes[0]}-only`)}
              </div>
            </div>
          ),
        )}
      </div>
    );
  }
}

RadioList.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  isCurrentCategory: PropTypes.bool,
  isWrongPostType: PropTypes.bool,
  postTypes: PropTypes.array,
  t: PropTypes.func,
  isQACategoriesEnabled: PropTypes.bool,
};

const mapRuntimeToProps = state => ({
  isQACategoriesEnabled: isQACategoriesExperimentEnabled(state),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(RadioList);
