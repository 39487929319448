import { flowRight } from 'lodash';
import classNames from 'classnames';
import Textarea from 'react-textarea-autosize';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { APP_TEXT_COLOR_PATH } from '../../../constants/wix-params';
import CategorySettingsInputLabel from './category-settings-input-label';
import withSettingsColor from '../../../hoc/with-settings-color';
import styles from './category-settings-text-input-with-label.scss';

class CategorySettingsTextInputWithLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
      value: this.props.value,
    };
  }

  componentWillReceiveProps({ value }) {
    this.setState({ value });
  }

  getBorderStyle = hasFocus => {
    const { borderColorFocused, borderColorNotFocused } = this.props;

    return {
      [true]: { border: `1px solid ${borderColorFocused}` },
      [false]: { border: `1px solid ${borderColorNotFocused}` },
    }[hasFocus];
  };

  onValueChange = ev => {
    const value = ev.target.value.slice(0, this.props.limit);
    this.props.updateOnEveryKeypress && this.props.onChange && this.props.onChange(value);
    this.setState({ value });
  };

  showLabel = () => this.setState({ isFocused: true });

  hideLabelAndEmitValue = () => {
    this.setState({ isFocused: false });
    const forcedValue = this.props.forceLastValue ? this.props.initialValue : this.state.value;
    this.props.onChange(
      typeof this.state.value === 'string' && this.state.value.trim()
        ? this.state.value
        : forcedValue,
      true,
    );
  };

  render() {
    const {
      labelText,
      tooltipText,
      id,
      limit,
      isTextArea,
      minRows,
      placeholder,
      wrapperClassName,
      dataHook,
    } = this.props;

    const props = {
      'data-hook': dataHook,
      id,
      className: classNames(styles.input, styles.padded, { [styles.textArea]: isTextArea }),
      value: this.state.value,
      onFocus: this.showLabel,
      onBlur: this.hideLabelAndEmitValue,
      onChange: this.onValueChange,
      style: this.getBorderStyle(this.state.isFocused),
      placeholder,
    };

    return (
      <div className={wrapperClassName}>
        <CategorySettingsInputLabel
          htmlFor={id}
          labelText={labelText}
          tooltipText={tooltipText}
          limit={limit}
          value={this.state.value || ''}
          showInfo={this.state.isFocused}
        />
        {isTextArea ? <Textarea {...props} minRows={minRows} /> : <input type="text" {...props} />}
      </div>
    );
  }
}

CategorySettingsTextInputWithLabel.propTypes = {
  onChange: PropTypes.func,
  labelText: PropTypes.string,
  tooltipText: PropTypes.string,
  wrapperClassName: PropTypes.string,
  id: PropTypes.string,
  limit: PropTypes.number,
  value: PropTypes.any,
  borderColorFocused: PropTypes.string,
  borderColorNotFocused: PropTypes.string,
  placeholder: PropTypes.string,
  dataHook: PropTypes.string,
  minRows: PropTypes.number,
  isTextArea: PropTypes.bool,
  forceLastValue: PropTypes.bool,
  updateOnEveryKeypress: PropTypes.bool,
  initialValue: PropTypes.string,
};

export default flowRight(
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'borderColorFocused',
    alpha: 0.4,
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.4,
    fallbackColor: '#000000',
  }),
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'borderColorNotFocused',
    alpha: 0.2,
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.2,
    fallbackColor: '#000000',
  }),
)(CategorySettingsTextInputWithLabel);
