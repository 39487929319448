import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import ButtonGroup from '../button-group';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './confirmation.scss';
import ModalHeader from '../modals/base/modal-header';

const Confirmation = ({
  title,
  children,
  confirm,
  onConfirm,
  cancel,
  onCancel,
  contentFontClassName,
  dataHook,
}) => {
  return (
    <div
      className={classNames(contentFontClassName, 'default-desktop-header-text-color')}
      data-hook={dataHook}
    >
      <ModalHeader className="confirmation__title" dataHook="confirmation__title">
        {title}
      </ModalHeader>
      {children && (
        <div className={styles.content} data-hook="confirmation__content">
          {children}
        </div>
      )}
      <ButtonGroup
        primaryButtonText={confirm}
        primaryButtonProps={{ onClick: () => onConfirm() }}
        secondaryButtonText={cancel}
        secondaryButtonProps={{ onClick: () => onCancel() }}
        isCentered
      />
    </div>
  );
};

Confirmation.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  confirm: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancel: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  dataHook: PropTypes.string,
};

export default withFontClassName(Confirmation);
