import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Header from '../../components/header';
import HeaderBackButton from '../../components/header-back-button';
import SearchHeader from './search-header';
import withFontClassName from '../../hoc/with-font-class-name';

const SearchMobileHeader = ({ contentFontClassName, params, ...otherProps }) => (
  <SearchHeader
    component={Header}
    left={<HeaderBackButton text={''} />}
    inputClassName={classNames('forum-text-color', contentFontClassName)}
    borderClassName="forum-text-background-color"
    iconClassName="forum-icon-fill"
    showWidget={false}
    params={params}
    {...otherProps}
  />
);

SearchMobileHeader.propTypes = {
  contentFontClassName: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
};

export default withFontClassName(SearchMobileHeader);
