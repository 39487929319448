import { withSentryErrorBoundary } from 'native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import { withStyles } from 'native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import AppRoot from './containers/app-root';
import InvalidAppStatePage from './components/invalid-app-state-page';
import { withStateReceiver } from '../common/services/state-optimizer/with-state-receiver';
import { withWebpackPublicPath } from '../common/hoc/with-webpack-public-path';
import { withLayoutUpdate } from '../common/hoc/with-layout-update';
import { SENTRY_COMPONENT_SCRIPT_DSN } from '../common/constants/sentry-viewer-dsn';
import { withInvalidAppState } from '../common/hoc/with-invalid-app-state';

const isProduction = process.env.NODE_ENV === 'production';
const withStylesConfig = {
  ltrCssPath: isProduction ? ['forum-app-viewer.min.css'] : ['forum-app-viewer.css'],
  rtlCssPath: isProduction ? ['forum-app-viewer.rtl.min.css'] : ['forum-app-viewer.rtl.css'],
};

const SENTRY_CONFIG = {
  dsn: SENTRY_COMPONENT_SCRIPT_DSN,
  config: {
    environment: `ooi-forum-app-${process.env.NODE_ENV}`,
  },
};

const FallbackPage = withStyles(InvalidAppStatePage, withStylesConfig);

export default {
  component: withSentryErrorBoundary(
    withLayoutUpdate(
      withInvalidAppState(
        withWebpackPublicPath(withStateReceiver(withStyles(AppRoot, withStylesConfig))),
        FallbackPage,
      ),
    ),
    SENTRY_CONFIG,
    FallbackPage,
  ),
};
