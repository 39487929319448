import React from 'react';
import { flowRight } from 'lodash';
import withSettingsColor from '../../hoc/with-settings-color';
// import { Checkbox as CheckboxWUT } from 'wix-ui-tpa/Checkbox';
// import styles from './checkbox.st.css';

function Checkbox({
  checked,
  onChange,
  borderColor,
  checkMarkColor,
  borderColorDisabled,
  checkMarkColorDisabled,
  disabled,
}) {
  return (
    <div style={{ cursor: 'pointer', position: 'relative' }}>
      <input
        checked={checked}
        onChange={onChange}
        style={{
          margin: 0,
          position: 'absolute',
          cursor: 'inherit',
          opacity: 0,
          width: '100%',
          height: '100%',
        }}
        disabled={disabled}
        type="checkbox"
      />
      <span
        style={{
          width: '16px',
          height: '16px',
          border: '1px solid',
          borderColor: disabled ? borderColorDisabled : borderColor,
          bordeRadius: '1px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {checked && (
          <svg
            style={{
              width: '8px',
              height: '7px',
              fill: disabled ? checkMarkColorDisabled : checkMarkColor,
            }}
            viewBox="0 0 10 8"
            fill="currentColor"
            width="10"
            height="8"
          >
            <path d="M3.5 5.2L1.2 3 0 4.2 3.5 7.8 10 1.2 8.8 0z" />
          </svg>
        )}
      </span>
    </div>
  );
}

export default flowRight(
  withSettingsColor({
    propName: 'borderColor',
    siteColorFallback: 'color-5',
    fallbackColor: 'rgba(51, 51, 51)',
  }),
  withSettingsColor({
    propName: 'borderColorDisabled',
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.6,
    fallbackColor: 'rgba(51, 51, 51)',
  }),
  withSettingsColor({
    propName: 'checkMarkColor',
    siteColorFallback: 'color-8',
    fallbackColor: 'rgba(51, 51, 51)',
  }),
  withSettingsColor({
    propName: 'checkMarkColorDisabled',
    siteColorFallback: 'color-8',
    siteColorAlpha: 0.6,
    fallbackColor: 'rgba(51, 51, 51)',
  }),
)(Checkbox);
