import { get } from 'lodash';
import { SITE_MEMBERS_PAGINATION_FETCH_LIMIT } from './site-members-constants';

export function isFetchingForQuery(state) {
  return query => {
    const offsets = get(state.siteMembers.fetching, query, []);
    return offsets.length > 0;
  };
}

export function isFetchingForQueryAtIndex(state, query, index) {
  const offsets = get(state.siteMembers.fetching, query, []);
  const fetchingOffset = getMatchingOffset(offsets, index);
  return fetchingOffset !== undefined ? true : false;
}

export function hasDataForIndex(state, query, index) {
  const isFetching = isFetchingForQueryAtIndex(state, query, index);
  if (isFetching) {
    return true;
  }
  const hasFetched = getSiteMemberAtIndex(state)(query, index);
  return hasFetched ? true : false;
}

export function getSiteMemberAtIndex(state) {
  return (query, index) => {
    const resultForQuery = get(state.siteMembers.members, query, {});
    const fetchedPagesOffsets = Object.keys(resultForQuery).map(o => parseInt(o, 10));
    const offset = getMatchingOffset(fetchedPagesOffsets, index);
    if (offset === undefined) {
      return null;
    } else {
      const memberId = resultForQuery[offset][index - offset];
      return state.siteMembers.membersEntitiesById[memberId];
    }
  };
}

export function getTotalSiteMembersCountForQuery(state) {
  return query => get(state.siteMembers.membersTotal, query, null);
}

function getMatchingOffset(offsets, index) {
  const bucket = getOffsetForIndex(index);
  return offsets.find(o => o === bucket);
}

export function getOffsetForIndex(index) {
  return (
    Math.floor(index / SITE_MEMBERS_PAGINATION_FETCH_LIMIT) * SITE_MEMBERS_PAGINATION_FETCH_LIMIT
  );
}
