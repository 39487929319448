import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { ModalCentered } from '../base';
import DeleteConfirmation from '../../delete-confirmation';
import withTranslate from '../../../hoc/with-translate';

export const DeleteCommentModal = ({ closeModal, deleteComment, t }) => (
  <ModalCentered onClose={closeModal}>
    <DeleteConfirmation
      title={t('delete-comment-modal.delete-comment')}
      message={t('delete-comment-modal.are-you-sure')}
      onConfirm={deleteComment}
      onCancel={closeModal}
    />
  </ModalCentered>
);

DeleteCommentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  postId: PropTypes.string.isRequired,
  commentId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps) => ({
  deleteComment: () =>
    ownProps.closeModal({
      postId: ownProps.postId,
      commentId: ownProps.commentId,
    }),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(DeleteCommentModal);
