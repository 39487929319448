import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight, size } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import PostListClassicHeader from '../post-list-classic-header';
import PostListItemClassic from '../post-list-item-classic';
import CreatePostButton from '../create-post-button';
import { HorizontalSeparator } from '../separator';
import { PaginationClassic } from '../pagination';
import { SortingSelectCategoryPageDesktop } from '../sorting-select-category';
import { FilterSelectPostTypeDesktop } from '../filter-select-post-type';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import Loader from '../loader';
import { isMultiplePostTypeCategory } from '../../services/categories-service';
import { isQACategoriesExperimentEnabled } from '../../selectors/experiments-selectors';
import { getIsViewsCountEnabled } from '../../selectors/app-settings-selectors';
import { POSTS_PER_PAGE } from '../../constants/pagination';
import styles from './post-list-classic.scss';

class PostListClassic extends Component {
  renderPagination() {
    return (
      <div className={styles.paginationContainer}>
        <PaginationClassic
          page={this.props.page}
          entityCount={this.props.entityCount}
          showPerPage={POSTS_PER_PAGE}
          onChange={this.handlePageChange}
          buildPageUrl={this.props.buildPageUrl}
          changePageOnClick={false}
        />
      </div>
    );
  }

  renderCreateNewPostButton() {
    const { category, showCreatePostAction } = this.props;
    return (
      showCreatePostAction && (
        <CreatePostButton categorySlug={category.slug} postTypes={category.postTypes} />
      )
    );
  }

  renderSortingSelect() {
    return (
      <div className={styles.selectContainer}>
        <SortingSelectCategoryPageDesktop />
      </div>
    );
  }

  renderFilterSelect() {
    return (
      <div className={styles.selectContainer}>
        <FilterSelectPostTypeDesktop />
      </div>
    );
  }

  hasControls() {
    const { entityCount, showCreatePostAction } = this.props;
    const hasPages = entityCount > POSTS_PER_PAGE;
    return showCreatePostAction || hasPages;
  }

  renderControls({ top }) {
    const { isQACategoriesEnabled, category } = this.props;
    return (
      <div
        className={classNames(styles.controlsContainer, styles.spacing)}
        data-hook="post-list-classic-controls"
      >
        {this.renderPagination()}
        <div className={styles.controls}>
          {isQACategoriesEnabled &&
            isMultiplePostTypeCategory(category) &&
            top &&
            this.renderFilterSelect()}
          {top && this.renderSortingSelect()}
          {this.renderCreateNewPostButton()}
        </div>
      </div>
    );
  }

  handlePageChange = ({ page, buttonType }) => {
    this.props.changePage({
      page,
      meta: {
        bi: {
          buttonType,
        },
      },
    });
    this.props.onPageChange(page);
  };

  renderSeparator(key = 'separator') {
    return (
      <tr key={key} aria-hidden="true">
        <td className={styles.separatorColumn} colSpan="100">
          <HorizontalSeparator />
        </td>
      </tr>
    );
  }

  renderTable() {
    const {
      posts,
      query,
      onLikeClick,
      isViewsCountEnabled,
      category,
      showCategoryLink,
    } = this.props;
    const hasControls = this.hasControls();

    return (
      <div className={styles.spacing}>
        <table className={classNames(styles.table, { [styles.single]: !hasControls })}>
          <PostListClassicHeader isViewsCountEnabled={isViewsCountEnabled} />
          <tbody>
            {posts.map(post => [
              this.renderSeparator(`separator-${post._id}`),
              <PostListItemClassic
                key={post._id}
                post={post}
                query={query}
                onLikeClick={onLikeClick}
                showCategoryLink={showCategoryLink}
                isMultiplePostTypeCategory={isMultiplePostTypeCategory(category)}
              />,
            ])}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { isLoading, borderWidth, contentFontClassName, isLoaded, posts } = this.props;
    const className = classNames(
      styles.container,
      'forum-card-background-color',
      'forum-card-border-color',
      contentFontClassName,
    );
    const hasControls = this.hasControls() && isLoaded;
    const hasBottomControls = size(posts) >= 7 && hasControls;

    return (
      <div className={className} style={{ borderWidth }} data-hook="post-list">
        {hasControls && this.renderControls({ top: true })}
        {hasControls && <HorizontalSeparator />}
        {isLoading ? <Loader /> : this.renderTable()}
        {hasBottomControls && <HorizontalSeparator />}
        {hasBottomControls && this.renderControls({ top: false })}
      </div>
    );
  }
}

PostListClassic.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  query: PropTypes.string,
  page: PropTypes.number,
  entityCount: PropTypes.number,
  borderWidth: PropTypes.number.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  showCreatePostAction: PropTypes.bool,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isViewsCountEnabled: PropTypes.bool,
  showCategoryLink: PropTypes.bool,
  buildPageUrl: PropTypes.func,
};

const mapRuntimeToPros = (state, ownProps, actions, host) => ({
  isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
  changePage: actions.changePage,
  isQACategoriesEnabled: isQACategoriesExperimentEnabled(state),
});

export default flowRight(
  connect(mapRuntimeToPros),
  withCardBorderWidth,
  withFontClassName,
  withTranslate,
)(PostListClassic);
