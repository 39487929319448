import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import BackButton from '../../components/header-back-button';
import HeaderButtons from '../../components/header-buttons';
import HeaderTitle from '../../components/header-title';
import Header from '../../components/header';
import { getCategories } from '../../selectors/categories-selectors';

const CategoryPageHeader = ({ categories, menu, params, currentCategorySlug }) => {
  const back = currentCategorySlug ? <BackButton /> : <HeaderTitle />;
  if (categories.length) {
    return categories.length === 1 ? (
      <Header left={<HeaderTitle />} right={<HeaderButtons params={params} />} menu={menu} />
    ) : (
      <Header left={back} right={<HeaderButtons params={params} />} menu={menu} />
    );
  }
};

CategoryPageHeader.propTypes = {
  params: PropTypes.object.isRequired,
  categories: PropTypes.array,
  menu: PropTypes.node,
  currentCategorySlug: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps) => ({
  categories: getCategories(state),
  currentCategorySlug: ownProps.params.categorySlug,
});

export default flowRight(connect(mapRuntimeToProps))(CategoryPageHeader);
