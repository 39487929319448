import React from 'react';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import { isDebug } from '../../../common/store/basic-params/basic-params-selectors';
import styles from './status-indicator.scss';

const makeMessage = text => <div className={styles.status}>{text}</div>;

const StatusIndicator = ({ isDebug }) => {
  const isDevEnv = process.env.NODE_ENV !== 'production';
  return isDevEnv || isDebug ? (
    <div className={styles.container}>
      {isDevEnv && makeMessage('Local Forum')}
      {isDebug && makeMessage('Forum - Frameless')}
    </div>
  ) : null;
};

const mapRuntimeToProps = state => ({
  isDebug: isDebug(state),
});

export default flowRight(connect(mapRuntimeToProps))(StatusIndicator);
