import { flowRight, isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../../common/components/runtime-context';
import classNames from 'classnames';
import { ModalFromRight } from '../base';
import PostForm from '../../post-form';
import PostCreateModalHeader from './post-create-modal-header';
import { withFastForm } from '../../../../common/components/fast-form';
import { convertContentStateToContent } from '../../../services/post-utils';
import { MODAL_TYPE_CHOOSE_CATEGORY } from '../choose-category-modal/choose-category-modal-type';
import { QUESTION, DISCUSSION } from '../../../constants/post-types';
import { ROUTE_CREATE_QUESTION, ROUTE_CATEGORY_CREATE_QUESTION } from '../../../constants/routes';
import postFormSettings from '../../../services/post-form-settings';
import { getResolvedModals } from '../../../../common/modals/framework/store/modal-selectors';
import { getRouteParams, getRoute } from '../../../../common/router/router-selectors';
import { getCategoryIdBySlug } from '../../../selectors/categories-selectors';
import { getCurrentUserId } from '../../../../common/store/current-user/current-user-selectors';
import styles from './post-create-modal-component.scss';

export const POST_CREATE_MODAL_CLOSE = 'CLOSE';
export const POST_CREATE_MODAL_SUBMIT = 'SUBMIT';

export class PostCreateModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValues: {
        ownerId: props.ownerId,
        categoryId: props.categoryId,
        postType: this.isQuestionPostType() ? QUESTION : DISCUSSION,
      },
      modalResolvedCategoryId: null,
    };
  }

  componentDidUpdate(prevProps) {
    // submit the form
    if (prevProps.fastForm.isSubmitting !== this.props.fastForm.isSubmitting) {
      this.submit(this.props.fastForm);
    }
  }

  isInCategory = () => !isNil(this.props.categoryId);
  submit = ({ values: postRaw }) => {
    const post = convertContentStateToContent(postRaw);
    this.props.createPostAndNavigateToItPromisified(post).then(({ isSuccessful }) => {
      if (!isSuccessful) {
        this.props.fastForm.stopSubmit();
      }
    });
  };
  isQuestionPostType = () =>
    this.props.route === ROUTE_CREATE_QUESTION ||
    this.props.route === ROUTE_CATEGORY_CREATE_QUESTION;

  showCategorySelectModal = () =>
    this.props.openModal(MODAL_TYPE_CHOOSE_CATEGORY).then(r => {
      this.props.fastForm.changeValue('categoryId')(r.categoryId, () => {
        this.submit(this.props.fastForm);
      });
    });

  render() {
    const { closeModal, goBack } = this.props;

    return (
      <ModalFromRight
        className={classNames(styles.modalContainer, 'post-create-page')}
        onClose={closeModal}
      >
        <PostCreateModalHeader
          isInCategory={this.isInCategory()}
          overrideSubmit={this.isInCategory() ? undefined : this.showCategorySelectModal}
          onBack={goBack}
        />
        <PostForm
          onSubmitButtonClick={submit => submit()}
          onCancel={goBack}
          formInitialValues={this.state.initialValues}
          modalResolvedCategoryId={this.state.modalResolvedCategoryId}
          postType={this.isQuestionPostType() ? QUESTION : DISCUSSION}
        />
      </ModalFromRight>
    );
  }
}

PostCreateModal.propTypes = {
  ownerId: PropTypes.string.isRequired,
  categoryId: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  createPostAndNavigateToItPromisified: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  post: PropTypes.object,
  resolvedModal: PropTypes.array,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const params = getRouteParams(state);
  const categoryId = getCategoryIdBySlug(state, params.categorySlug);

  return {
    openModal: actions.openModal,
    route: getRoute(state),
    createPostAndNavigateToItPromisified: actions.createPostAndNavigateToItPromisified,
    ownerId: getCurrentUserId(state),
    categoryId,
    resolvedModal: getResolvedModals(state),
    goBack: () => {
      ownProps.closeModal();
      actions.navigateWithinForum(categoryId ? `/${params.categorySlug}` : '/');
    },
  };
};

export default flowRight(
  withFastForm(postFormSettings, { skipInitialize: true }),
  connect(mapRuntimeToProps),
)(PostCreateModal);
