import { chain, isString, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import ButtonGroup from '../button-group';
import ModalHeader from '../modals/base/modal-header';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import styles from './move-to-category.scss';
import RadioList from '../radio-list';
import { MembersIcon } from '../icons/members-icon';
import { LockEmptyIcon } from '../icons/lock-empty-icon';
import { MODAL_TYPE_MOVE_POST_CONFIRMATION } from '../modals/move-post-confirmation-modal/move-post-confirmation-modal-type';
import { connect } from '../../../common/components/runtime-context';
import { CATEGORY_TYPE_MEMBERS_ONLY, CATEGORY_TYPE_PRIVATE } from '../../constants/category-types';
import { MovePostConfirmationTypes } from '../modals/move-post-confirmation-modal/move-post-confirmation-modal-component';
import {
  isMembersOnly,
  isPrivate,
  isPublic,
} from '../../../common/services/category-privacy-utils';
import withPermissions from '../../hoc/with-permissions';

class MoveToCategory extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);

    const categories = this.props.categories.filter(
      category => category._id !== this.props.currentCategoryId,
    );
    this.state = {
      categoryId: categories.length ? categories[0]._id : null,
    };
  }

  handleCategoryChange(categoryId) {
    this.setState({ categoryId });
  }

  showConfirmationModal(type) {
    this.props.openModal(
      MODAL_TYPE_MOVE_POST_CONFIRMATION,
      {
        categoryId: this.state.categoryId,
        postId: this.props.postId,
        postType: this.props.postType,
        type,
      },
      { origin: this.props.origin },
    );
  }

  findCategory(id) {
    return this.props.categories.find(c => c._id === id);
  }

  getConfirmationType() {
    const currentCategory = this.findCategory(this.props.currentCategoryId);
    const movingToCategory = this.findCategory(this.state.categoryId);
    if (!isPrivate(currentCategory) && isPrivate(movingToCategory))
      return MovePostConfirmationTypes.private;
    else if (!isPublic(currentCategory) && isPublic(movingToCategory))
      return MovePostConfirmationTypes.public;
    else if (isPrivate(currentCategory) && isMembersOnly(movingToCategory))
      return MovePostConfirmationTypes.membersOnly;
  }

  handleMove() {
    const { onClick } = this.props;
    const confirmationType = this.getConfirmationType();
    confirmationType
      ? this.showConfirmationModal(confirmationType)
      : onClick(this.state.categoryId);
  }

  handleCancelClick() {
    this.props.onCancel();
  }

  getIconByCategoryType(category) {
    const categoryType = category.type || (category.isPrivate && CATEGORY_TYPE_MEMBERS_ONLY);
    switch (categoryType) {
      case CATEGORY_TYPE_MEMBERS_ONLY:
        return MembersIcon;
      case CATEGORY_TYPE_PRIVATE:
        return LockEmptyIcon;
      default:
        return;
    }
  }

  render() {
    const { contentFontClassName, t, categories, currentCategoryId, postType, can } = this.props;
    const className = classNames(
      contentFontClassName,
      'default-desktop-header-text-color',
      'move-to-category',
    );
    const options = chain(categories)
      .map(category => {
        const isWrongPostType = !category.postTypes.includes(postType);
        const isCurrentCategory = currentCategoryId === category._id;
        const isDisabled =
          !can('read', 'category', category) || isCurrentCategory || isWrongPostType;

        return {
          label: category.label,
          value: category._id,
          postTypes: category.postTypes,
          isIndented: isString(category.parentId),
          Icon: this.getIconByCategoryType(category),
          isDisabled,
          isCurrentCategory,
          isWrongPostType,
        };
      })
      .value();
    return (
      <div className={className}>
        <ModalHeader>{t('move-to-category.move-to-category')}</ModalHeader>
        <div className={styles.message}>{this.props.message}</div>
        <div className={styles.selectWrapper}>
          <RadioList
            options={options}
            value={this.state.categoryId}
            onChange={this.handleCategoryChange}
          />
        </div>
        <ButtonGroup
          primaryButtonText={t('move-to-category.move-post')}
          primaryButtonProps={{ onClick: this.handleMove }}
          secondaryButtonText={t('move-to-category.cancel')}
          secondaryButtonProps={{ onClick: this.handleCancelClick }}
          isCentered
        />
      </div>
    );
  }
}

MoveToCategory.propTypes = {
  openModal: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  currentCategoryId: PropTypes.string,
  postId: PropTypes.string,
  postType: PropTypes.string,
  message: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  openModal: actions.openModal,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withFontClassName,
  withTranslate,
  withPermissions,
)(MoveToCategory);
