import { get, flowRight } from 'lodash';
import mapProps from 'recompose/mapProps';
import { TextEditorMobileToolbar as TextEditorMobileToolbarUnstyled } from '../text-editor';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import withFontClassName from '../../hoc/with-font-class-name';
import getTheme from './get-theme';

const withTheme = (WrappedComponent, path, props) =>
  flowRight(
    withCardBackgroundColor,
    withFontClassName,
    mapProps(({ cardBackgroundColor, titleFontClassName, contentFontClassName, ...rest }) => {
      const themeConfig = { cardBackgroundColor, titleFontClassName, contentFontClassName };
      return {
        ...rest,
        ...props,
        theme: path ? get(getTheme(themeConfig), path) : getTheme(themeConfig),
      };
    }),
  )(WrappedComponent);

export const TextEditorMobileToolbar = withTheme(TextEditorMobileToolbarUnstyled, 'toolbar');
