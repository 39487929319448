import { flowRight, isEqual, cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../../common/components/runtime-context';
import withTranslate from '../../../hoc/with-translate';
import { getMembersGroupsByType } from '../../../selectors/members-groups-selectors';
import EmptyState from './manage-category-members-empty-state';
import Button from '../../button';
import { getIsMobile } from '../../../../common/store/basic-params/basic-params-selectors';
import ManageCategoryMembersModal from './manage-category-members-modal';

function sortGroupsByAccess(category, groups) {
  const groupsWithAccess = category.groups || [];

  const [access, noAccess] = groups.reduce(
    ([access, noAccess], group) => {
      if (groupsWithAccess.includes(group.id)) {
        access.push(group);
      } else {
        noAccess.push(group);
      }

      return [access, noAccess];
    },
    [[], []],
  );

  return access.concat(noAccess).map(g => g.id);
}

class ManageCategoryMembersBaseModal extends Component {
  state = {
    searchQuery: '',
    sortedGroupsIds: [],
    shouldPollForUpdates: true,
    pollingInterval: 15000,
    addedGroups: [],
    removedGroups: [],
  };

  componentDidMount() {
    this.setState({
      sortedGroupsIds: sortGroupsByAccess(this.props.category, this.props.groups.list),
    });
    this.originalGroups = cloneDeep(this.props.groups.list);
    this.originalCategoryGroups = cloneDeep(this.props.category.groups);
    this.fetchGroupsOnInterval();
  }

  componentWillUnmount() {
    this.setState({ shouldPollForUpdates: false });
    clearTimeout(this.pollingTimeoutId);
    clearTimeout(this.pollingStopTimeoutId);
  }

  componentDidUpdate(prevProps) {
    this.updateSortedList(prevProps.groups, this.props.groups);
  }

  updateSortedList = (prevgroups, groups) => {
    if (prevgroups.count > groups.count) {
      // user deleted group
      this.setState({
        sortedGroupsIds: this.state.sortedGroupsIds.filter(
          id => this.props.groups.byId[id] !== undefined,
        ),
      });
      this.props.onGroupRemoved();
    } else if (prevgroups.count < groups.count) {
      // user added group
      const prevGroupsByIds = prevgroups.byId;
      const newGroups = groups.list.filter(g => !prevGroupsByIds[g.id]).map(g => g.id);
      this.setState({ sortedGroupsIds: [...newGroups, ...this.state.sortedGroupsIds] });

      this.props.onGroupAdded();
    }
  };

  fetchGroupsOnInterval = () => {
    this.pollingTimeoutId = setTimeout(() => {
      if (this.state.shouldPollForUpdates) {
        this.props.fetchMembersGroupsListPromisified().then(() => {
          this.fetchGroupsOnInterval();
        });
      }
    }, this.state.pollingInterval);
    this.pollingStopTimeoutId = setTimeout(() => clearTimeout(this.pollingTimeoutId), 600000); // stop polling after 10min, so no DDOS if user leaves tab open
  };

  reducePollingInterval = () => this.setState({ pollingInterval: 5000 });

  closeModal = () => {
    this.props.closeModal({ itemsUpdated: this.areItemsUpdated() });
  };

  areItemsUpdated = () => {
    return !(
      isEqual(this.originalGroups, this.props.groups.list) &&
      isEqual(this.originalCategoryGroups, this.props.category.groups)
    );
  };

  formToggleAddGroup = groupId =>
    this.setState(state => ({
      addedGroups: state.addedGroups.includes(groupId)
        ? state.addedGroups.filter(id => id !== groupId)
        : state.addedGroups.concat(groupId),
    }));
  formToggleRemoveGroup = groupId =>
    this.setState(state => ({
      removedGroups: state.removedGroups.includes(groupId)
        ? state.removedGroups.filter(id => id !== groupId)
        : state.removedGroups.concat(groupId),
    }));
  formToggleCheckbox = (isGroupAssignedToCategory, groupId) =>
    isGroupAssignedToCategory
      ? this.formToggleRemoveGroup(groupId)
      : this.formToggleAddGroup(groupId);
  formGetCheckboxValue = (isGroupAssignedToCategory, groupId) => {
    if (isGroupAssignedToCategory) {
      return !this.state.removedGroups.includes(groupId);
    } else {
      return this.state.addedGroups.includes(groupId);
    }
  };
  formSubmit = () => {
    const { addedGroups, removedGroups } = this.state;
    const { category, categoryAddGroups, categoryRemoveGroups } = this.props;
    if (addedGroups.length > 0) {
      categoryAddGroups({
        groups: addedGroups,
        categoryId: category._id,
      });
    }
    if (removedGroups.length > 0) {
      categoryRemoveGroups({
        groups: removedGroups,
        categoryId: category._id,
      });
    }
  };

  render() {
    const {
      groups,
      renderListItem,
      manageGroupsLink,
      createNewGroupLink,
      titleTextKey,
      bottomCtaTextKey,
      emptyStateTitleTextKey,
      emptyStateCtaTextKey,
      emptyStateDescriptionTextKey,
      searchEmptyStateTextKey,
      t,
    } = this.props;
    const { sortedGroupsIds } = this.state;
    const groupsEntities = sortedGroupsIds.map(id => groups.byId[id]);
    const matchingGroups = groupsEntities.filter(
      g => g && g.title.toLowerCase().includes(this.state.searchQuery),
    );
    const matchingGroupsLength = matchingGroups.length;

    let content;

    if (sortedGroupsIds.length === 0) {
      content = (
        <EmptyState
          title={t(emptyStateTitleTextKey)}
          cta={
            <Button component="a" href={createNewGroupLink || manageGroupsLink} target="_blank">
              {t(emptyStateCtaTextKey)}
            </Button>
          }
          description={t(emptyStateDescriptionTextKey)}
        />
      );
    } else if (matchingGroupsLength === 0) {
      content = (
        <EmptyState
          title={t(searchEmptyStateTextKey)}
          description={t('manage-category-members-search-empty-state.try-another-search')}
        />
      );
    } else {
      content = (
        <div>
          {matchingGroups.map(group =>
            renderListItem(group, {
              formToggleCheckbox: this.formToggleCheckbox,
              getCheckboxValue: this.formGetCheckboxValue,
            }),
          )}
        </div>
      );
    }

    return (
      <ManageCategoryMembersModal
        closeModal={this.closeModal}
        titleTextKey={titleTextKey}
        bottomCtaTextKey={bottomCtaTextKey}
        manageGroupsLink={manageGroupsLink}
        formSubmit={() => {
          this.formSubmit();
          this.closeModal();
        }}
        searchQueryValue={this.state.searchQuery}
        searchQueryOnChange={value => this.setState({ searchQuery: value.toLowerCase() })}
        searchQueryCount={matchingGroupsLength}
        reducePollingInterval={this.reducePollingInterval}
        disablePrimaryButton={sortedGroupsIds.length === 0}
      >
        {content}
      </ManageCategoryMembersModal>
    );
  }
}

ManageCategoryMembersBaseModal.propTypes = {
  closeModal: PropTypes.func,
  fetchMembersGroupsListPromisified: PropTypes.func,
  groups: PropTypes.array,
  manageGroupsLink: PropTypes.string,
  createNewGroupLink: PropTypes.string,
  titleTextKey: PropTypes.string,
  bottomCtaTextKey: PropTypes.string,
  emptyStateTitleTextKey: PropTypes.string,
  emptyStateCtaTextKey: PropTypes.string,
  emptyStateDescriptionTextKey: PropTypes.string,
  searchEmptyStateTextKey: PropTypes.string,
  groupType: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isMobile: PropTypes.bool,
  t: PropTypes.func,
  onGroupAdded: PropTypes.func,
  onGroupRemoved: PropTypes.func,
  renderListItem: PropTypes.func,
  categoryAddGroups: PropTypes.func,
  categoryRemoveGroups: PropTypes.func,
  category: PropTypes.object,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  groups: getMembersGroupsByType(state, ownProps.groupType),
  fetchMembersGroupsListPromisified: actions.fetchMembersGroupsListPromisified,
  isMobile: getIsMobile(state),
  categoryAddGroups: actions.categoryAddGroups,
  categoryRemoveGroups: actions.categoryRemoveGroups,
});

ManageCategoryMembersBaseModal.defaultProps = {
  onGroupAdded: () => {},
  onGroupRemoved: () => {},
};

export default flowRight(connect(mapRuntimeToProps), withTranslate)(ManageCategoryMembersBaseModal);
