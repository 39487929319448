import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './page.scss';

const Page = ({ className, noSpacing, noSpacingTop, children }) => (
  <div
    className={classNames(
      styles.container,
      { [styles.noSpacing]: noSpacing, [styles.noSpacingTop]: noSpacingTop },
      className,
    )}
  >
    {children}
  </div>
);

Page.propTypes = {
  className: PropTypes.string,
  noSpacing: PropTypes.bool,
  noSpacingTop: PropTypes.bool,
  children: PropTypes.node,
};

export default Page;
