import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { MODAL_TYPE_CATEGORIES_MANAGER } from '../../components/modals/categories-manager/categories-manager-modal-type';
import { GearIcon } from '../../components/icons/gear-icon';
import ActionButton from '../../components/action-button';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../hoc/with-translate';

export class BreadcrumbsWidgetActions extends Component {
  handleManageCategories = () => {
    this.props.openModal(MODAL_TYPE_CATEGORIES_MANAGER, { categoryId: null });
  };

  render() {
    const { canRender, t } = this.props;

    const manageCategoriesButton = canRender('edit', 'category', can => {
      return can ? (
        <ActionButton dataHook="category-actions__manage" onClick={this.handleManageCategories}>
          <GearIcon className="forum-icon-fill" />
          {t('breadcrumbs-actions.manage-categories')}
        </ActionButton>
      ) : null;
    });

    return <div>{manageCategoriesButton}</div>;
  }
}

BreadcrumbsWidgetActions.propTypes = {
  openModal: PropTypes.func,
  canRender: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  openModal: actions.openModal,
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withPermissions,
  withTranslate,
)(BreadcrumbsWidgetActions);
