import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { getPostBySlug } from '../../../selectors/post-selectors';
import { ModalFromRight } from '../base';
import PostForm from '../../post-form';
import PostEditModalHeader from './post-edit-modal-header';
import styles from './post-edit-modal.scss';
import {
  convertContentStateToContent,
  convertPostContentToContentState,
} from '../../../services/post-utils';
import { getRouteParams } from '../../../../common/router/router-selectors';
import { withFastForm } from '../../../../common/components/fast-form';
import postFormSettings from '../../../services/post-form-settings';

export const POST_EDIT_MODAL_CLOSE = 'CLOSE';
export const POST_EDIT_MODAL_SUBMIT = 'SUBMIT';

export class PostEditModal extends Component {
  componentDidUpdate(prevProps) {
    // submit the form
    if (prevProps.fastForm.isSubmitting !== this.props.fastForm.isSubmitting) {
      this.submit(this.props.fastForm);
    }
  }

  submit = ({ values: editedPost }, fastForm) => {
    const post = convertContentStateToContent(editedPost);
    this.props
      .updatePostPromisified({ ...this.props.post, ...post })
      .catch(() => fastForm.stopSubmit());
  };

  render() {
    const { post } = this.props;

    return (
      <ModalFromRight className={styles.modalContainer} onClose={this.props.goBack}>
        <PostEditModalHeader onBack={this.props.goBack} />
        <PostForm
          formInitialValues={convertPostContentToContentState(post)}
          onSubmitButtonClick={submit => submit()}
          onCancel={this.props.goBack}
          postId={post._id}
        />
      </ModalFromRight>
    );
  }
}

PostEditModal.propTypes = {
  updatePostPromisified: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  post: PropTypes.object,
  fastForm: PropTypes.object,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const params = getRouteParams(state);

  return {
    post: getPostBySlug(state, params.postSlug),
    goBack: () => {
      ownProps.closeModal();
      actions.navigateWithinForum(`/${params.categorySlug}/${params.postSlug}`);
    },
    updatePostPromisified: actions.updatePostPromisified,
  };
};

export default flowRight(
  withFastForm(postFormSettings, { skipInitialize: true }),
  connect(mapRuntimeToProps),
)(PostEditModal);
