import React from 'react';

export const QuestionIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
    <g fillRule="evenodd">
      <circle fill="none" cx="10" cy="10" r="8.5" />
      <path
        fillRule="nonzero"
        d="M9.092 12.098c.008-.465.06-.832.158-1.102.098-.27.297-.568.598-.896l.767-.791c.328-.372.492-.77.492-1.196 0-.41-.107-.731-.322-.964-.215-.232-.527-.348-.937-.348-.399 0-.72.105-.961.316-.242.211-.364.494-.364.85H7.44c.008-.633.234-1.144.677-1.532.444-.39 1.02-.583 1.732-.583.738 0 1.313.198 1.725.594.412.397.618.94.618 1.632 0 .684-.316 1.358-.949 2.022l-.638.632c-.286.317-.428.772-.428 1.366H9.092zm-.047 1.857c0-.176.054-.323.161-.442.107-.12.267-.179.478-.179.21 0 .37.06.48.179a.63.63 0 0 1 .164.442.609.609 0 0 1-.164.437c-.11.115-.27.172-.48.172-.211 0-.37-.057-.478-.172a.615.615 0 0 1-.161-.437z"
      />
    </g>
  </svg>
);
