import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../../common/components/runtime-context';
import classNames from 'classnames';
import { ModalFromRight } from '../base';
import ManageCategories from './manage-categories';
import CategorySettings from './category-settings';
import {
  getCategoryInEditId,
  getOpenPanels,
  shouldShowLoader,
} from '../../../selectors/categories-manager-selectors';
import { isForumData } from '../../../services/categories-service';
import { scrollBy } from '../../../services/scroll-by';
import withDeviceType from '../../../hoc/with-device-type';
import Loader from '../../loader';
import styles from './categories-manager-modal-component.scss';

class CategoriesManagerModal extends Component {
  body = null;

  componentDidMount() {
    this.preventBackgroundScroll();
  }

  preventBackgroundScroll = () => {
    if (typeof document !== 'undefined' && this.props.isMobile) {
      this.body = document.getElementsByTagName('body')[0];
      this.prevScrollY = window.scrollY;
      setTimeout(() => this.body.classList.add(styles.noScroll), 200);
    }
  };

  allowBackgroundScroll = () => {
    if (this.body) {
      this.body.classList.remove(styles.noScroll);
      scrollBy(0, this.prevScrollY, false);
    }
  };

  closeModal = () =>
    isForumData(this.props.categoryInEditId)
      ? this.props.cancelForumDataChanges(true)
      : this.props.cancelChanges(true);

  handleCloseModal = () => {
    this.allowBackgroundScroll();
    this.props.closeModal();
  };

  render() {
    return (
      <ModalFromRight onClose={this.closeModal} backdropClassName={styles.backdrop}>
        <div className={classNames(styles.container, 'default-forum-card-background-color')}>
          {this.props.shouldShowLoader && (
            <div className={styles.loaderBackground}>
              <Loader />
            </div>
          )}
          {this.props.categoryInEditId ? (
            <CategorySettings
              categoryId={this.props.categoryInEditId}
              openPanels={this.props.openPanels}
            />
          ) : (
            <ManageCategories closeModal={this.handleCloseModal} />
          )}
        </div>
      </ModalFromRight>
    );
  }
}

CategoriesManagerModal.propTypes = {
  closeModal: PropTypes.func,
  cancelChanges: PropTypes.func,
  cancelForumDataChanges: PropTypes.func,
  categoryInEditId: PropTypes.string,
  openPanels: PropTypes.array,
  modalBackground: PropTypes.string,
  isMobile: PropTypes.bool,
  shouldShowLoader: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  categoryInEditId: getCategoryInEditId(state),
  openPanels: getOpenPanels(state),
  cancelChanges: actions.categoriesManagerCancelChanges,
  cancelForumDataChanges: actions.categoriesManagerCancelForumDataChanges,
  shouldShowLoader: shouldShowLoader(state),
});

export default flowRight(
  withDeviceType,
  connect(mapRuntimeToProps),
)(CategoriesManagerModal);
