import React from 'react';

const CopyIcon = props => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Popups-.-desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Notifications-Settings-Copy-2"
        transform="translate(-887.000000, -489.000000)"
        fill="#333333"
      >
        <g id="Group-10" transform="translate(430.000000, 255.000000)">
          <g id="Group-3" transform="translate(80.000000, 132.000000)">
            <g id="Icons" transform="translate(18.000000, 102.000000)">
              <path
                d="M366.3844,14.4848 C366.1604,14.2608 366.1604,13.8958 366.3844,13.6718 L372.6744,7.3808 C372.8994,7.1558 373.2644,7.1558 373.4884,7.3808 L375.0134,8.9058 C375.1214,9.0138 375.1814,9.1588 375.1814,9.3128 C375.1814,9.4658 375.1214,9.6098 375.0134,9.7188 L368.7234,16.0098 C368.6134,16.1178 368.4694,16.1778 368.3164,16.1778 C368.1624,16.1778 368.0184,16.1178 367.9084,16.0098 L366.3844,14.4848 Z M380.4801,1.8593 C381.6781,3.0583 382.3391,4.6523 382.3391,6.3483 C382.3391,8.0443 381.6781,9.6383 380.4801,10.8373 L376.5441,14.7723 C376.4321,14.8843 376.2851,14.9403 376.1381,14.9403 C375.9911,14.9403 375.8431,14.8843 375.7311,14.7723 L373.8621,12.9033 L378.0831,8.6823 C379.2991,7.4663 379.2991,5.4873 378.0831,4.2723 C376.8701,3.0573 374.8901,3.0553 373.6731,4.2723 L369.4521,8.4933 L367.5661,6.6083 C367.4581,6.4993 367.3981,6.3553 367.3981,6.2013 C367.3981,6.0473 367.4581,5.9033 367.5661,5.7953 L371.5011,1.8593 C372.7011,0.6603 374.2941,0.0003 375.9911,0.0003 C377.6861,0.0003 379.2811,0.6603 380.4801,1.8593 Z M371.9821,14.7836 L373.8541,16.6556 C373.9621,16.7636 374.0221,16.9086 374.0221,17.0626 C374.0221,17.2166 373.9611,17.3616 373.8531,17.4686 L369.8411,21.4816 C368.6021,22.7206 366.9761,23.3396 365.3491,23.3396 C363.7221,23.3396 362.0961,22.7206 360.8571,21.4826 C358.3811,19.0046 358.3811,14.9746 360.8571,12.4986 L364.8701,8.4856 C365.0881,8.2676 365.4651,8.2676 365.6841,8.4856 L367.5721,10.3726 L363.3851,14.5596 C362.1691,15.7756 362.1691,17.7536 363.3851,18.9696 C364.6001,20.1856 366.5791,20.1876 367.7951,18.9696 L371.9821,14.7836 Z"
                id="Fill-6"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CopyIcon;
