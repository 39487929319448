import React from 'react';

export const MoveRightIcon = props => (
  <svg
    width="19px"
    height="19px"
    viewBox="0 0 19 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3,12.2 L3,3.2 C3,3.08954305 3.08954305,3 3.2,3 L3.8,3 C3.91045695,3 4,3.08954305 4,3.2 L4,12 L13.6628599,12 L11.3795338,9.69852852 C11.301429,9.62042366 11.301429,9.49379066 11.3795338,9.41568581 L11.8037979,8.99142174 C11.8819028,8.91331688 12.0085358,8.91331688 12.0866406,8.99142174 L15.4305006,12.3573597 C15.4695531,12.3964121 15.4890793,12.4475966 15.4890793,12.4987811 C15.4890793,12.5499655 15.4695531,12.60115 15.4305006,12.6402024 L12.0866406,15.9914217 C12.0085358,16.0695266 11.8819028,16.0695266 11.8037979,15.9914217 L11.3795338,15.5671577 C11.301429,15.4890528 11.301429,15.3624198 11.3795338,15.284315 L13.6578137,13 L3.2,13 C3.08954305,13 3,12.9104569 3,12.8 L3,12.2 Z" />
  </svg>
);
