import React from 'react';

export const MembersIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path d="M8 10a3 3 0 0 1 3 3v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3a3 3 0 0 1 3-3h4zm0 1H4a2 2 0 0 0-1.995 1.85L2 13v3h8v-3a2 2 0 0 0-2-2zm7-3a3 3 0 0 1 3 3v3a1 1 0 0 1-1 1h-5v-1h5v-3a2 2 0 0 0-2-2h-4c-.322 0-.626.076-.895.21a2.96 2.96 0 0 0-.873-.201L9 9h-.236c.55-.614 1.347-1 2.236-1h4zM6 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 1a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7-3a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 1a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
  </svg>
);
