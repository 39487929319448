import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { ModalCentered } from '../base';
import Confirmation from '../../confirmation';
import withTranslate from '../../../hoc/with-translate';
import { MODAL_TYPE_MOVE_POST } from '../move-post-modal/move-post-modal-type';
import {
  CATEGORY_TYPE_MEMBERS_ONLY,
  CATEGORY_TYPE_PRIVATE,
  CATEGORY_TYPE_PUBLIC,
} from '../../../constants/category-types';

export const MovePostConfirmationTypes = {
  public: CATEGORY_TYPE_PUBLIC,
  membersOnly: CATEGORY_TYPE_MEMBERS_ONLY,
  private: CATEGORY_TYPE_PRIVATE,
};

export const MovePostConfirmationModal = ({ closeModal, t, movePost, type }) => (
  <ModalCentered onClose={closeModal}>
    <Confirmation
      title={t(`move-post-confirmation-modal.${type}.title`)}
      confirm={t('move-post-confirmation-modal.confirm')}
      cancel={t('move-post-confirmation-modal.cancel')}
      onConfirm={movePost}
      onCancel={closeModal}
    >
      <p>{t(`move-post-confirmation-modal.${type}.description`)}</p>
    </Confirmation>
  </ModalCentered>
);

MovePostConfirmationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
  postId: PropTypes.string,
  type: PropTypes.oneOf(Object.values(MovePostConfirmationTypes)),
  t: PropTypes.func,
};

MovePostConfirmationModal.defaultProps = {
  type: MovePostConfirmationTypes.private,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  movePost: () => {
    ownProps.closeModal();
    actions.closeModal({
      type: MODAL_TYPE_MOVE_POST,
      resolve: {
        categoryId: ownProps.categoryId,
        postId: ownProps.postId,
        postType: ownProps.postType,
      },
    });
  },
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(MovePostConfirmationModal);
