import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../../common/components/runtime-context';
import { ModalCentered } from '../base';
import { getAllCategories } from '../../../selectors/categories-selectors';
import MoveToCategory from '../../move-to-category';
import withTranslate from '../../../hoc/with-translate';

const MovePostModal = ({ categories, move, closeModal, categoryId, t, postId, postType }) => (
  <ModalCentered onClose={closeModal}>
    <MoveToCategory
      categories={categories}
      postId={postId}
      postType={postType}
      currentCategoryId={categoryId}
      onClick={move}
      onCancel={closeModal}
      message={t('move-post-modal.move-this-post')}
    />
  </ModalCentered>
);

MovePostModal.propTypes = {
  move: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  categoryId: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired,
  postType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps) => ({
  categories: getAllCategories(state) || [],
  move: categoryId => ownProps.closeModal({ categoryId, postId: ownProps.postId }),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(MovePostModal);
