import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import InfiniteList from '../infinite-list';
import PostListItem from '../post-list-item';
import CommentListItem from '../comment-search-list-item';
import styles from './search-results-list.scss';

const THRESHOLD = 400;

const itemRenderer = props => index => {
  // eslint-disable-next-line react/prop-types
  const { posts, query, onPostLikeClick, onCommentLikeClick } = props;
  const post = posts[index];
  const isPost = post.type === 'post';
  const Component = isPost ? PostListItem : CommentListItem;

  const postProps = {
    post,
    onLikeClick: onPostLikeClick,
  };

  const commentProps = {
    comment: post,
    onPostLikeClick,
    onCommentLikeClick,
  };

  const componentProps = {
    query,
    ...(isPost ? postProps : commentProps),
  };

  return (
    <div
      className={classNames(styles.listItemMobile, 'search-results-list__list-item')}
      key={post._id}
      id={post._id}
    >
      <Component type={PostListItem.SEARCH} {...componentProps} />
    </div>
  );
};

const SearchResultsMobileList = props => (
  <InfiniteList
    length={props.posts.length}
    itemRenderer={itemRenderer(props)} // Must pass props here otherwise it does not rerender after post change
    threshold={THRESHOLD}
  />
);

SearchResultsMobileList.propTypes = {
  onPostLikeClick: PropTypes.func.isRequired,
  onCommentLikeClick: PropTypes.func.isRequired,
  posts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
};

export default SearchResultsMobileList;
