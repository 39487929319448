import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ImgBackground, RENDER_IMAGE } from '@wix/communities-image-lib';
import MoreButton from '../../more-button';
import ManageCategoriesCardActions from './manage-categories-card-actions.js';
import withSettingsColor from '../../../hoc/with-settings-color';
import styles from './manage-categories-card.scss';

class ManageCategoriesCard extends Component {
  render() {
    const {
      title,
      image,
      onEdit,
      onDelete,
      onMoveToChild,
      onMoveToTop,
      borderColor,
      frontComponent,
      middleComponent,
      backComponent,
      isCollapsed,
    } = this.props;
    const border = `1px solid ${borderColor}`;
    return (
      <div
        style={{
          border,
          paddingLeft: frontComponent ? '0' : '38px',
          height: isCollapsed ? '76px' : '66px',
        }}
        className={classNames('default-forum-card-background-color')}
        onDoubleClick={onEdit}
      >
        <div className={styles.container}>
          <div className={styles.left}>
            {frontComponent}
            {image && (
              <ImgBackground
                className={styles.image}
                src={image}
                with={30}
                height={30}
                renderMore={RENDER_IMAGE}
              />
            )}
            <h2 className={styles.title} data-hook="category-card-title">
              {title}
            </h2>
            {middleComponent}
          </div>
          <div className={styles.right}>
            {backComponent}
            <MoreButton className={styles.moreButton} target={title} forceLTR>
              <ManageCategoriesCardActions
                onEdit={onEdit}
                onDelete={onDelete}
                onMoveToChild={onMoveToChild}
                onMoveToTop={onMoveToTop}
              />
            </MoreButton>
          </div>
        </div>
        {isCollapsed && <div className={styles.collapsedPart} style={{ borderTop: border }} />}
        {isCollapsed && <div className={styles.collapsedPart} style={{ borderTop: border }} />}
      </div>
    );
  }
}

ManageCategoriesCard.propTypes = {
  borderColor: PropTypes.string,
  frontComponent: PropTypes.node,
  middleComponent: PropTypes.node,
  backComponent: PropTypes.node,
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveToChild: PropTypes.func,
  onMoveToTop: PropTypes.func,
  isCollapsed: PropTypes.bool,
};

ManageCategoriesCard.propTypes = {};

export default flowRight(
  withSettingsColor({
    propName: 'borderColor',
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.2,
    fallbackColor: 'rgba(51, 51, 51, 0.2)',
  }),
)(ManageCategoriesCard);
