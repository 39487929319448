import PropTypes from 'prop-types';
import React from 'react';
import InfiniteList from '../infinite-list';
import Comment from '../comment-yak';
import styles from './comment-list.scss';

const THRESHOLD = 400;

const CommentList = ({ comments, onLikeClick, onReplyClick, editedCommentId, topCommentId }) => (
  <div id="comments" className={styles.commentList}>
    <InfiniteList
      length={comments.length}
      itemRenderer={itemRenderer({
        comments,
        onLikeClick,
        onReplyClick,
        editedCommentId,
        topCommentId,
      })}
      threshold={THRESHOLD}
    />
  </div>
);

function itemRenderer({ comments, onLikeClick, onReplyClick, editedCommentId, topCommentId }) {
  return index => {
    const comment = comments[index];
    const key = comment._id;
    const isTopComment = comment._id === topCommentId;

    return [
      renderComment({
        key,
        index,
        comment,
        onLikeClick,
        onReplyClick,
        isTopComment,
        topCommentId,
        editedCommentId,
      }),
    ];
  };
}

/* eslint-disable react/prop-types */
function renderComment({
  key,
  index,
  comment,
  onLikeClick,
  onReplyClick,
  isTopComment,
  topCommentId,
  editedCommentId,
}) {
  return (
    <Comment
      key={key}
      comment={comment}
      isTopComment={isTopComment}
      onLikeClick={onLikeClick}
      onReplyClick={onReplyClick}
      topCommentId={topCommentId}
      editedCommentId={editedCommentId}
    />
  );
}
/* eslint-enable react/prop-types */

CommentList.propTypes = {
  comments: PropTypes.array.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onReplyClick: PropTypes.func,
  editedCommentId: PropTypes.string,
  topCommentId: PropTypes.string,
};

export default CommentList;
