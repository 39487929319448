import { identity, memoize } from 'lodash';
import { fileToDataUrl, fixImageOrientation, resizeImage } from '../../services/process-image';

// this is used on component side, to wrap uploadImage action, it does some thing which need access to DOM
export const wrapUploadImageAction = memoize((uploadImage, isMobile) => (file, props) => {
  return fileToDataUrl(file)
    .then(fixImageOrientation)
    .then(isMobile ? resizeImage : identity)
    .then(imageData => {
      uploadImage(file, imageData, props);
      return imageData;
    });
});
