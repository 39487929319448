import PropTypes from 'prop-types';
import React from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import { handleEnterKeyUp } from '../../../services/accessibility';
import { XSmall } from '../../icons/x-small';
import styles from './modal.scss';
import withSettingsColor from '../../../hoc/with-settings-color';
import withTranslate from '../../../hoc/with-translate';

export const ModalLayout = ({
  className,
  cardBackgroundColor,
  borderColor,
  showCloseButton,
  onClose,
  children,
  t,
}) => {
  const renderCloseButton = onClose => (
    <button
      aria-label={t('modal.close')}
      className={classNames(styles.closeIcon, 'default-main-icon-fill', 'modal__close')}
      onClick={onClose}
      onKeyUp={handleEnterKeyUp(onClose)}
    >
      <XSmall />
    </button>
  );

  return (
    <div
      className={classNames(styles.content, className, 'default-forum-card-background-color')}
      data-hook="modal-layout"
      style={{ backgroundColor: cardBackgroundColor, borderColor }}
    >
      {showCloseButton ? renderCloseButton(onClose) : null}
      {children}
    </div>
  );
};

ModalLayout.propTypes = {
  className: PropTypes.string,
  cardBackgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  children: PropTypes.node,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
};

export default flowRight(
  withTranslate,
  withSettingsColor({
    propName: 'cardBackgroundColor',
    siteColorFallback: 'color-1',
    fallbackColor: '#ffffff',
  }),
  withSettingsColor({
    propName: 'borderColor',
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.2,
    fallbackColor: 'rgba(51, 51, 51, 0.2)',
  }),
)(ModalLayout);
