import React from 'react';
import PropTypes from 'prop-types';
import styles from './sticky-container.scss';

const HTML_TAG = 'HTML';
class StickyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();

    this.state = {
      topOffset: this.props.defaultOffset,
      refNodes: null,
    };
  }

  componentDidMount() {
    this.setRefNodes();
    document.addEventListener('scroll', this.handleOverlap);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleOverlap);
  }

  setRefNodes() {
    if (this.container.current) {
      this.setState({ refNodes: this.container.current.getElementsByTagName('*') });
    }
  }

  handleOverlap = () => {
    const element = this.getElementAtContainerPos();
    const isOverlaped = element && !this.checkIfBelongsToRefNodes(element);
    if (isOverlaped && element.tagName !== HTML_TAG) {
      const { height, top } = element.getBoundingClientRect();
      const topOffset = height + top + this.props.defaultOffset;
      this.setState({ topOffset });
    }
  };

  checkIfBelongsToRefNodes = element => {
    const { refNodes } = this.state;
    let status = false;
    Array.prototype.forEach.call(refNodes, node => {
      if (element === node) {
        status = true;
      }
    });
    return status;
  };

  getElementAtContainerPos = () => {
    const { left, top, width } = this.container.current.getBoundingClientRect();
    const middle = left + width / 2;
    let element = document.elementFromPoint(middle, top);
    if (element === null) {
      element = document.elementFromPoint(left, top);
    }
    return element;
  };

  render() {
    const { topOffset } = this.state;
    return (
      <div ref={this.container} style={{ top: `${topOffset}px` }} className={styles.container}>
        {this.props.children}
      </div>
    );
  }
}

StickyContainer.propTypes = {
  children: PropTypes.node,
  defaultOffset: PropTypes.number,
};

StickyContainer.defaultProps = {
  defaultOffset: 0,
};

export default StickyContainer;
