import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from '../../../../common/components/runtime-context';
import { COVER_TYPE_IMAGE } from '../../../constants/cover-types';
import ScrollableContainer from '../../scrollable-container';
import { HorizontalSeparator } from '../../separator';
import Header from '../../header';
import HeaderButton from '../../header-button';
import CategoriesManagerFooterButtons from './categories-manager-footer-buttons';
import ManageCategoriesCard from './manage-categories-card';
import ManageCategoriesList from './manage-categories-list';
import IconTooltip from '../../icon-tooltip';
import { EyeIcon } from '../../icons/eye-icon';
import { getAllCategories } from '../../../selectors/categories-selectors';
import { getForumData } from '../../../selectors/forum-data-selectors';
import withTranslate from '../../../hoc/with-translate';
import withDeviceType from '../../../hoc/with-device-type';
import withFontClassName from '../../../hoc/with-font-class-name';
import withSettingsColor from '../../../hoc/with-settings-color';
import styles from './manage-categories.scss';
import { MODAL_TYPE_DELETE_CATEGORY } from '../delete-category-modal/delete-category-modal-type';
import { withModalA11yProps } from '../base/with-modal-a11y-context';
import withAuth from '../../../hoc/with-auth';
import HeaderBodySeparator from '../base/header-body-separator';

class ManageCategories extends Component {
  editForumData = () => this.props.categoriesManagerEditForumData();

  editCategory = categoryId => {
    this.props.categoriesManagerEditCategory(categoryId);
  };

  deleteCategory = categoryId => {
    this.props.openModal(MODAL_TYPE_DELETE_CATEGORY, { categoryId });
  };

  createCategory = () => {
    const { createCategoryLocally, t } = this.props;
    createCategoryLocally({
      label: t('default-category-title'),
      description: t('default-category-description'),
    });
  };

  closeModal = () => this.props.closeModal();

  renderOnlyCategoryRemainingIcon = () => (
    <IconTooltip
      className={styles.mainPageHiddenIcon}
      text={this.props.t('manage-categories.main-page-hidden')}
      tooltipClassName={styles.tooltip}
    >
      <EyeIcon />
    </IconTooltip>
  );

  render() {
    const {
      t,
      isMobile,
      forumData,
      categories,
      contentFontClassName,
      titleFontClassName,
      textColor,
      titleId,
      isOwner,
    } = this.props;
    const createBtnTxt = isMobile
      ? 'manage-categories.create-category-button'
      : 'manage-categories.create-category-button-desktop';
    const closeBtnTxt = 'manage-categories.update-button';

    const isOnlyOneRootCategory =
      categories.length === 1 ||
      categories.reduce((count, c) => (c.parentId ? count : count + 1), 0) === 1;

    return (
      <div
        style={{ color: textColor }}
        className={classNames(styles.container, contentFontClassName)}
        data-hook="manage-categories"
      >
        {isMobile && (
          <Header
            right={
              <HeaderButton onClick={this.closeModal} text={closeBtnTxt} dataHook="rightButton" />
            }
          />
        )}
        <ScrollableContainer>
          <div>
            {isMobile && <HeaderBodySeparator />}
            <div className={styles.content}>
              {!isMobile && (
                <h3 id={titleId} className={classNames(styles.title, titleFontClassName)}>
                  {t('manage-categories.title')}
                </h3>
              )}
              {!isMobile && <HorizontalSeparator className={styles.separator} />}
              <h4 className={styles.subTitle}>{t('manage-categories.main-page')}</h4>
              <ManageCategoriesCard
                onEdit={this.editForumData}
                title={forumData.label}
                image={forumData.coverType === COVER_TYPE_IMAGE ? forumData.cover : null}
                middleComponent={isOnlyOneRootCategory && this.renderOnlyCategoryRemainingIcon()}
              />
              <div className={styles.subtitleWrap}>
                <h4 className={styles.subTitle}>{t('manage-categories.categories')}</h4>
                {isOwner && (
                  <button
                    className={classNames(
                      styles.createCategory,
                      contentFontClassName,
                      'button-color',
                    )}
                    onClick={this.createCategory}
                    data-hook="manage-categories__create-category"
                  >
                    {t(createBtnTxt)}
                  </button>
                )}
              </div>
              <ManageCategoriesList
                categories={categories}
                onEditCategory={this.editCategory}
                onDeleteCategory={this.deleteCategory}
                onReorderCategories={this.props.reorderCategories}
              />
            </div>
          </div>
        </ScrollableContainer>
        {!isMobile && (
          <CategoriesManagerFooterButtons
            onClickRight={this.closeModal}
            rightText={t(closeBtnTxt)}
          />
        )}
      </div>
    );
  }
}

ManageCategories.propTypes = {
  categories: PropTypes.array,
  t: PropTypes.func,
  categoriesManagerEditCategory: PropTypes.func,
  categoriesManagerEditForumData: PropTypes.func,
  createCategoryLocally: PropTypes.func,
  closeModal: PropTypes.func,
  reorderCategories: PropTypes.func,
  openModal: PropTypes.func,
  isMobile: PropTypes.bool,
  forumData: PropTypes.object,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  textColor: PropTypes.string,
  titleId: PropTypes.string.isRequired,
  isOwner: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  categories: getAllCategories(state),
  forumData: getForumData(state),
  categoriesManagerEditCategory: actions.categoriesManagerEditCategory,
  categoriesManagerEditForumData: actions.categoriesManagerEditForumData,
  createCategoryLocally: actions.categoriesManagerCreateCategoryLocally,
  reorderCategories: actions.categoriesManagerReorderCategories,
  openModal: actions.openModal,
});

export default flowRight(
  withAuth,
  withModalA11yProps,
  withDeviceType,
  withTranslate,
  withFontClassName,
  connect(mapRuntimeToProps),
  withSettingsColor({
    propName: 'textColor',
    siteColorFallback: 'color-5',
    fallbackColor: 'rgba(51, 51, 51, 0.8)',
  }),
)(ManageCategories);
