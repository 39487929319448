import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../../common/components/runtime-context';
import {
  GROUP_TYPE_BADGE,
  GROUP_TYPE_BADGE_WITH_PERMISSION,
} from '../../../constants/manage-category-members';
import withTranslate from '../../../hoc/with-translate';
import withFontClassName from '../../../hoc/with-font-class-name';
import { getCategoryById } from '../../../../recent-posts-widget/selectors/categories-selectors';
import { getMetaSiteId } from '../../../../common/store/instance-values/instance-values-selectors';
import ManageCategoryMembersListItem from './manage-category-members-list-item';
import { getAllBadges } from '../../../selectors/badge-selectors';
import ManageCategoryMembersBaseModal from './manage-category-members-base-modal-component';

class ManageCategoryMembersBadgesModal extends Component {
  render() {
    const { category, metaSiteId, badges, fetchBadges, closeModal } = this.props;
    const manageBadgesLink = `https://www.wix.com/dashboard/${metaSiteId}/badge-definitions`;

    return (
      <ManageCategoryMembersBaseModal
        closeModal={closeModal}
        category={category}
        groupType={[GROUP_TYPE_BADGE, GROUP_TYPE_BADGE_WITH_PERMISSION]}
        createNewGroupLink={manageBadgesLink}
        manageGroupsLink={manageBadgesLink}
        titleTextKey="manage-category-members-badges-modal.title"
        bottomCtaTextKey="manage-category-members-modal.manage-badges-link"
        onGroupAdded={fetchBadges}
        emptyStateTitleTextKey="manage-category-members-badges-empty-state.you-dont-have-any-badges"
        emptyStateDescriptionTextKey="manage-category-members-badges-empty-state.to-create-badges-go-to"
        emptyStateCtaTextKey="manage-category-members-badges-empty-state.cta"
        searchEmptyStateTextKey="manage-category-members-badges-search-empty-state.no-results-found"
        renderListItem={(group, formApi) => (
          <ManageCategoryMembersListItem
            isBadge
            group={group}
            category={category}
            formApi={formApi}
            badge={badges.find(b => b.id === group.id)}
          />
        )}
      />
    );
  }
}

ManageCategoryMembersBadgesModal.propTypes = {
  closeModal: PropTypes.func,
  fetchBadges: PropTypes.func,
  category: PropTypes.object,
  metaSiteId: PropTypes.string,
  badges: PropTypes.array,
  t: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  category: getCategoryById(state, ownProps.categoryId),
  fetchBadges: actions.fetchBadges,
  metaSiteId: getMetaSiteId(state),
  badges: getAllBadges(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withFontClassName,
  withTranslate,
)(ManageCategoryMembersBadgesModal);
