import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import withPermissions from '../../hoc/with-permissions';
import withAuth from '../../hoc/with-auth';
import ActionButton from '../action-button/action-button';
import ensureAuth from '../../hoc/ensure-auth';
import { MODAL_TYPE_UNFOLLOW_CATEGORY } from '../modals/unfollow-category-modal/unfollow-category-modal-type';
import { FOLLOW_CATEGORY } from '../../constants/interactions';

const ActionButtonAuth = ensureAuth(ActionButton);

export class FollowButton extends Component {
  handleSubscribe = () => {
    const {
      category: { _id },
      subscribeToCategory,
      buttonClicked,
    } = this.props;
    buttonClicked({ action: 'follow_category', flag: 1 });

    subscribeToCategory(_id);
  };

  handleUnsubscribe = () => {
    const {
      category: { _id },
      buttonClicked,
      openModal,
    } = this.props;
    buttonClicked({ action: 'follow_category', flag: 0 });
    openModal(MODAL_TYPE_UNFOLLOW_CATEGORY, { categoryId: _id });
  };

  render() {
    const { canRender, category, forPublicUser, children, className, size } = this.props;
    return canRender('subscribe', 'category', category, can => {
      const action = category.isSubscribed ? this.handleUnsubscribe : this.handleSubscribe;
      const ActionComponent = can ? ActionButton : ActionButtonAuth;
      return (
        <ActionComponent
          isButton
          dataHook="follow-button"
          onClick={can ? action : forPublicUser(action)}
          className={className}
          size={size}
          actionDetails={{ action: FOLLOW_CATEGORY, args: [category._id] }}
        >
          {children}
        </ActionComponent>
      );
    });
  }
}

FollowButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  category: PropTypes.object.isRequired,
  canRender: PropTypes.func.isRequired,
  subscribeToCategory: PropTypes.func.isRequired,
  buttonClicked: PropTypes.func.isRequired,
  forPublicUser: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  size: PropTypes.string,
};

FollowButton.SIZE_SMALL = ActionButton.SIZE_SMALL;

const mapRuntimeToProps = (state, ownProps, actions) => ({
  subscribeToCategory: actions.subscribeToCategory,
  openModal: actions.openModal,
  buttonClicked: actions.buttonClicked,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withPermissions,
  withAuth,
)(FollowButton);
