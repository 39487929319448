import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReadOnlyInput from '../read-only-input';
import ButtonGroup from '../button-group';
import withTranslate from '../../hoc/with-translate';
import { POST_LINK_COPIED } from '../messages/message-types';

let Clipboard;
try {
  Clipboard = require('clipboard');
} catch (e) {}
import styles from './share-link.scss';
import { connect } from '../../../common/components/runtime-context';

class ShareLink extends Component {
  state = {
    isClipboardSupported: false,
  };

  componentDidMount() {
    const isSupported = Clipboard.isSupported();
    this.setState({
      isClipboardSupported: isSupported,
    });

    if (isSupported) {
      this.clipboard = new Clipboard(`.${styles.container} [data-clipboard-text]`);
    }
  }

  handleClick = () => {
    const { closeModal, showMessage } = this.props;
    setTimeout(() => showMessage(POST_LINK_COPIED), 100);
    closeModal();
  };

  render() {
    const { t, onCancel, url } = this.props;
    const { isClipboardSupported } = this.state;
    let primaryButtonProps;

    if (isClipboardSupported) {
      primaryButtonProps = {
        'data-clipboard-text': url,
      };
    } else {
      primaryButtonProps = {
        style: {
          display: 'none',
        },
      };
    }
    primaryButtonProps.onClick = this.handleClick;

    return (
      <div className={styles.container}>
        <div className={styles.input}>
          <ReadOnlyInput value={url} autoFocus />
        </div>
        <ButtonGroup
          primaryButtonText={t('share-link.copy')}
          primaryButtonProps={primaryButtonProps}
          secondaryButtonText={t('share-link.cancel')}
          secondaryButtonProps={{ onClick: onCancel }}
          isCentered
        />
      </div>
    );
  }
}

ShareLink.propTypes = {
  url: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  t: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  showMessage: actions.showMessage,
});

export default connect(mapRuntimeToProps)(withTranslate(ShareLink));
