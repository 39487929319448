import React from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import Link from '../link/internal-link';
import PostPageSidebarCategoryListItem from './post-page-sidebar-category-list-item';
import { getSubcategories } from '../../services/categories-service';
import withTranslate from '../../hoc/with-translate';
import styles from './post-page-sidebar-category-list.scss';

const HOME = '/';
const CATEGORIES_LIMIT = 5;

const PostPageSidebarCategoryList = ({ categories, allCategories, t }) => {
  const renderCategories = () => {
    const items = [];
    categories.slice(0, CATEGORIES_LIMIT).forEach(category => {
      const totalCategories = items.reduce((acc, cur) => acc + cur.subcategories.length + 1, 0);
      if (totalCategories < CATEGORIES_LIMIT) {
        const item = {
          category,
          subcategories: getSubcategories(allCategories, category._id).slice(
            0,
            CATEGORIES_LIMIT - totalCategories - 1,
          ),
        };
        items.push(item);
      }
    });

    return items.map(item => (
      <PostPageSidebarCategoryListItem
        category={item.category}
        subcategories={item.subcategories}
      />
    ));
  };
  return (
    <section
      className={classNames(
        styles.categoryListContainer,
        'forum-card-border-color',
        'forum-text-color',
      )}
    >
      <h4 className={styles.title}>{t('post-page-sidebar-category-list.title')}</h4>
      {categories && renderCategories()}
      <Link to={HOME} className={'button-color'}>
        {t('post-page-sidebar-category-list.see-all-categories')}
      </Link>
    </section>
  );
};

PostPageSidebarCategoryList.propTypes = {
  categories: PropTypes.array.isRequired,
  allCategories: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default flowRight(withTranslate)(PostPageSidebarCategoryList);
