import React from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import styles from './modal-header.scss';
import withFontClassName from '../../../hoc/with-font-class-name';
import { withModalA11yProps } from './with-modal-a11y-context';

const ModalHeader = ({
  children,
  titleFontClassName,
  className,
  dataHook,
  titleId,
  isLeftAligned,
}) => (
  <h2
    id={titleId}
    className={classNames(
      styles.title,
      titleFontClassName,
      className,
      { [styles.leftAligned]: isLeftAligned },
      'default-desktop-header-text-color',
    )}
    data-hook={dataHook}
  >
    {children}
  </h2>
);

ModalHeader.propTypes = {
  children: PropTypes.any,
  titleFontClassName: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataHook: PropTypes.string,
  titleId: PropTypes.string,
  isLeftAligned: PropTypes.bool,
};

export default flowRight(
  withModalA11yProps,
  withFontClassName,
)(ModalHeader);
