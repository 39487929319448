import React from 'react';

export const DragIconTall = props => (
  <svg
    width="6px"
    height="22px"
    viewBox="0 0 6 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0,0 L2,0 L2,2 L0,2 L0,0 Z M0,5 L2,5 L2,7 L0,7 L0,5 Z M4,0 L6,0 L6,2 L4,2 L4,0 Z M4,5 L6,5 L6,7 L4,7 L4,5 Z M0,10 L2,10 L2,12 L0,12 L0,10 Z M4,10 L6,10 L6,12 L4,12 L4,10 Z M0,15 L2,15 L2,17 L0,17 L0,15 Z M4,15 L6,15 L6,17 L4,17 L4,15 Z M0,20 L2,20 L2,22 L0,22 L0,20 Z M4,20 L6,20 L6,22 L4,22 L4,20 Z"
      id="path-1"
    />
  </svg>
);
