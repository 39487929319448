import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import FooterPostsItemDesktop from '../footer-posts-item-desktop';
import withTranslate from '../../hoc/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './footer-posts-desktop.scss';
import { TYPE_NEW_POSTS } from './types';

const FooterPostsDesktop = ({ t, titleFontClassName, posts, className, prefetchPost, type }) => {
  return (
    <div className={classNames(styles.container, className)} data-hook="footer-posts">
      <div className={classNames(titleFontClassName, 'new-posts-text-color', styles.title)}>
        {t(type === TYPE_NEW_POSTS ? 'footer-posts.title' : 'footer-posts.related-posts')}
      </div>
      <ul className={styles.postsList}>
        {posts.map(post => (
          <li key={post._id} className={styles.postListItem}>
            <FooterPostsItemDesktop post={post} prefetchPost={prefetchPost} />
          </li>
        ))}
      </ul>
    </div>
  );
};

FooterPostsDesktop.propTypes = {
  t: PropTypes.func,
  titleFontClassName: PropTypes.string,
  posts: PropTypes.array,
  classNames: PropTypes.string,
  prefetchPost: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export default flowRight(
  withTranslate,
  withFontClassName,
)(FooterPostsDesktop);
