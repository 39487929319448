import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import CreatePostHandler from '../../containers/create-post-handler';
import CreatePostCard from '../create-post-card-yak';
import ensureAuth from '../../hoc/ensure-auth';
import { QUESTION } from '../../constants/post-types';
import { CREATE_NEW_POST, CREATE_NEW_QUESTION } from '../../constants/interactions';
import { MODAL_TYPE_CREATE_POST_MOBILE } from '../modals/create-post-modal/create-post-modal-type';

const SecureCreatePostCard = ensureAuth(CreatePostCard);

const CreatePostCta = ({ categorySlug, isMultiplePostTypeCategory, postTypes, openModal }) => {
  const isQuestion = !isMultiplePostTypeCategory && postTypes.includes(QUESTION);
  const pageSlug = isQuestion ? 'create-question' : 'create-post';

  return (
    <div>
      {isMultiplePostTypeCategory ? (
        <CreatePostCard
          isMultiplePostTypeCategory={isMultiplePostTypeCategory}
          showQuestionLabel={false}
          onClick={() => openModal(MODAL_TYPE_CREATE_POST_MOBILE, { categorySlug })}
        />
      ) : (
        <CreatePostHandler categorySlug={categorySlug}>
          <SecureCreatePostCard
            actionDetails={{
              action: isQuestion ? CREATE_NEW_QUESTION : CREATE_NEW_POST,
              args: [categorySlug ? `/${categorySlug}/${pageSlug}` : `${pageSlug}`],
            }}
            isMultiplePostTypeCategory={isMultiplePostTypeCategory}
            showQuestionLabel={isQuestion}
          />
        </CreatePostHandler>
      )}
    </div>
  );
};

CreatePostCta.propTypes = {
  categorySlug: PropTypes.string,
  isMobileHeaderEnabled: PropTypes.bool,
};

CreatePostCta.defaultProps = {
  isMultiplePostTypeCategory: true,
  postTypes: [],
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  openModal: actions.openModal,
});

export default flowRight(connect(mapRuntimeToProps))(CreatePostCta);
