import PropTypes from 'prop-types';
import React from 'react';
import { AlertIcon } from '../icons/alert-icon';
import Confirmation from '../confirmation';
import withTranslate from '../../hoc/with-translate';

export const DemoConfirmation = ({ title, message, confirmButton, onConfirm, onCancel, t }) => (
  <Confirmation
    icon={AlertIcon}
    title={title}
    confirm={confirmButton}
    onConfirm={onConfirm}
    cancel={t('demo-confirmation.cancel-button')}
    onCancel={onCancel}
  >
    <p>{message}</p>
  </Confirmation>
);

DemoConfirmation.propTypes = {
  t: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  confirmButton: PropTypes.string,
  message: PropTypes.string,
};

export default withTranslate(DemoConfirmation);
