import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { ModalFromRight } from '../base';
import {
  convertContentStateToContent,
  convertPostContentToContentState,
} from '../../../services/post-utils';
import CommentForm from '../../comment-form';
import { getAnyComment } from '../../../selectors/comment-selectors';
import { getPostBySlug } from '../../../selectors/post-selectors';
import styles from './comment-edit-modal.scss';
import Header from '../../header';
import HeaderBackButton from '../../header-back-button';
import { TextEditorMobileToolbar } from '../../styled-text-editor';
import { CommentPublishButton } from '../../../containers/publish-button/publish-button';
import commentFormSettings from '../../../services/comment-form-settings';
import { withFastForm } from '../../../../common/components/fast-form';

export class CommentEditModal extends Component {
  componentDidUpdate(prevProps) {
    // submit the form
    if (prevProps.fastForm.isSubmitting !== this.props.fastForm.isSubmitting) {
      this.submit(this.props.fastForm);
    }
  }

  goBack = () => this.props.closeModal();
  submit = ({ values: editedComment }) => {
    const comment = convertContentStateToContent(editedComment);
    this.props.closeModal({
      ...this.props.comment,
      ...comment,
    });
  };

  render() {
    const { comment, closeModal } = this.props;
    const formName = `edit-comment`;

    return (
      <ModalFromRight className={styles.modalContainer} onClose={closeModal}>
        <Header
          left={<HeaderBackButton onClick={this.goBack} buttonComponent="button" />}
          right={<CommentPublishButton text="post-edit-page-header.update" formName={formName} />}
          menu={<TextEditorMobileToolbar identifier="comment" />}
        />
        <CommentForm
          formName={formName}
          formInitialValues={convertPostContentToContentState(comment)}
          onSubmit={this.submit}
          onCancel={this.goBack}
        />
      </ModalFromRight>
    );
  }
}

CommentEditModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  comment: PropTypes.object,
  commentId: PropTypes.string,
  postId: PropTypes.string,
  fastForm: PropTypes.object,
};

const mapRuntimeToProps = (state, ownProps) => {
  let postId;
  if (ownProps.postId) {
    postId = ownProps.postId;
  } else {
    postId = getPostBySlug(state, ownProps.postSlug)._id;
  }
  return {
    comment: getAnyComment(state, ownProps.commentId, postId),
  };
};

export default flowRight(
  withFastForm({ ...commentFormSettings, formName: 'edit-comment' }, { skipInitialize: true }),
  connect(mapRuntimeToProps),
)(CommentEditModal);
