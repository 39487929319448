import PropTypes from 'prop-types';
import React from 'react';
import { ModalFromBottom } from '../base';
import { HorizontalSeparator } from '../../separator';
import DiscussionPostButton from '../../create-discussion-button';
import QuestionPostButton from '../../create-question-button';
import styles from './create-post-modal-component.scss';

const CreatePostMobileModal = ({ closeModal, categorySlug }) => {
  const handleClick = () => closeModal();
  return (
    <ModalFromBottom onClose={closeModal}>
      <div className={styles.modalContainer}>
        <div onClick={handleClick}>
          <DiscussionPostButton categorySlug={categorySlug} isExtended />
        </div>
        <HorizontalSeparator />
        <div onClick={handleClick}>
          <QuestionPostButton categorySlug={categorySlug} isExtended />
        </div>
      </div>
    </ModalFromBottom>
  );
};

CreatePostMobileModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  categorySlug: PropTypes.string.isRequired,
};

export default CreatePostMobileModal;
