import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { LIST_ITEM } from './item-types';
import styles from './list-item.scss';

const isIndented = (monitor, targetComponent, isIndented, indentationDistance) => {
  const targetRectBound = findDOMNode(targetComponent).firstChild.getBoundingClientRect();
  const sourceClientOffset = monitor.getSourceClientOffset();

  if (isIndented) {
    return sourceClientOffset
      ? sourceClientOffset.x - targetRectBound.x > -indentationDistance
      : true;
  } else {
    return sourceClientOffset
      ? sourceClientOffset.x - targetRectBound.x > indentationDistance
      : false;
  }
};

const cardSource = {
  beginDrag(props) {
    props.onDragStart(props.index);
    return {
      id: props.id,
      index: props.index,
      isParent: props.isParent,
    };
  },
  endDrag(props) {
    props.onDragEnd(props.index);
  },
};

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    let hoverIndex = props.index;
    const isSourceParent = monitor.getItem().isParent;
    const isTargetParent = props.isParent;
    const canBeChildAtTarget = props.canBeChildAtTarget;
    const canBeParentAtTarget = props.canBeParentAtTarget; // when not last child

    const currentIsChild = props.isChild;
    let nextIsChild = isIndented(monitor, component, currentIsChild, props.childrenIndentation);

    if (isSourceParent && currentIsChild) {
      return;
    }

    if (isTargetParent && isSourceParent && hoverIndex > dragIndex) {
      // move one group below another
      hoverIndex += props.childrenCount;
    } else if (isTargetParent && hoverIndex > dragIndex) {
      // prevent parent from being replaced
      return;
    }

    if (!nextIsChild && currentIsChild && !canBeParentAtTarget) {
      // prevent from child becoming parent in a middle of other children
      return;
    }

    if (
      dragIndex === hoverIndex &&
      (currentIsChild === nextIsChild ||
        (currentIsChild !== nextIsChild &&
          !canBeChildAtTarget &&
          !currentIsChild &&
          isSourceParent))
    ) {
      return;
    }

    if (dragIndex !== hoverIndex && currentIsChild && !nextIsChild) {
      // prevent from child becoming parent in a middle of other children
      nextIsChild = true;
    }

    props.moveCard({
      dragIndex,
      hoverIndex,
      isChild: canBeChildAtTarget && !isSourceParent ? nextIsChild : false, // TODO: canBeChildAtTarget && !isSourceParent should be one?
      dragId: monitor.getItem().id,
      newIndexCallback: index => (monitor.getItem().index = index),
    });
  },
};

const targetCollect = connect => ({
  connectDropTarget: connect.dropTarget(),
});

const sourceCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

class ListItem extends PureComponent {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }

  renderTreeConnector = () => {
    const {
      isChild,
      isFirstChild,
      childrenIndentation,
      childConnectorColor = 'black',
      childConnectorMargin,
    } = this.props;
    return isChild ? (
      <div
        className={styles.treeConnector}
        style={{
          width: `${childrenIndentation / 2}px`,
          height: `calc(${isFirstChild ? 50 : 100}% + ${childConnectorMargin}px)`,
          left: `${childrenIndentation / 2}px`,
          borderBottom: `1px solid ${childConnectorColor}`,
          borderLeft: `1px solid ${childConnectorColor}`,
        }}
      />
    ) : null;
  };

  moveToChild = () => {
    const { moveToChild, index, id } = this.props;
    moveToChild(index, id);
  };
  moveToParent = () => {
    const { moveToParent, index, id } = this.props;
    moveToParent(index, id);
  };

  render() {
    const {
      isDragging,
      connectDragSource,
      connectDropTarget,
      renderListItem,
      data,
      isChild,
      isParent,
      isChildrenHiddenUser,
      index,
      showChildrenUser,
      hideChildrenUser,
      canBeChildAtTarget,
      childrenIndentation,
      listItemClassName,
    } = this.props;

    return connectDropTarget(
      <div
        className={classNames(listItemClassName)}
        style={{
          paddingLeft: isChild ? `${childrenIndentation}px` : '0',
        }}
      >
        {renderListItem(data, {
          connectDragSource,
          isParent,
          isChild,
          showDropPlaceholder: isDragging,
          showChildren: () => showChildrenUser(index),
          hideChildren: () => hideChildrenUser(index),
          isChildrenHidden: isChildrenHiddenUser,
          moveToParent: isChild ? this.moveToParent : null,
          moveToChild: !isChild && canBeChildAtTarget && !isParent ? this.moveToChild : null,
        })}
        {this.renderTreeConnector(false)}
      </div>,
    );
  }
}

ListItem.propTypes = {
  connectDragSource: PropTypes.func,
  connectDragPreview: PropTypes.func,
  connectDropTarget: PropTypes.func,
  index: PropTypes.number,
  dataLength: PropTypes.number,
  isDragging: PropTypes.bool,
  id: PropTypes.any,
  moveCard: PropTypes.func,
  renderListItem: PropTypes.func,
  data: PropTypes.object,
  isChild: PropTypes.bool,
  isFirstChild: PropTypes.bool,
  isHidden: PropTypes.bool,
  isChildrenHiddenUser: PropTypes.bool,
  canBeChildAtTarget: PropTypes.bool,
  isParent: PropTypes.bool,
  childrenCount: PropTypes.number,
  canBeParentAtTarget: PropTypes.bool,
  onDragStart: PropTypes.func,
  hideChildrenUser: PropTypes.func,
  showChildrenUser: PropTypes.func,
  moveToChild: PropTypes.func,
  moveToParent: PropTypes.func,
  onDragEnd: PropTypes.func,
  childrenIndentation: PropTypes.number,
  listItemClassName: PropTypes.string,
  listItemCollapsedClassName: PropTypes.string,
  childConnectorColor: PropTypes.string,
  childConnectorMargin: PropTypes.number,
};
/* eslint-disable new-cap */
export default DropTarget(LIST_ITEM, cardTarget, targetCollect)(
  DragSource(LIST_ITEM, cardSource, sourceCollect)(ListItem),
);
