import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import FooterPostsMobile from './footer-posts-mobile';
import FooterPostsDesktop from './footer-posts-desktop';
import NoPosts from './no-posts';
import Loader from '../loader';
import { getIsFooterPostsLoading } from '../../selectors/is-loading-selectors';
import withDeviceType from '../../hoc/with-device-type';
import { isExperimentEnabled } from '../../selectors/experiments-selectors';
import { EXPERIMENT_RELATED_POSTS } from '../../constants/experiments';
import { getRelatedAndNewPosts, getNewPosts } from '../../selectors/post-selectors';
import { TYPE_NEW_POSTS, TYPE_RELATED_POSTS } from './types';

const FooterPosts = ({ isMobile, showEmptyState, posts, isLoading, prefetchPost, type }) => {
  if (isLoading) {
    return <Loader />;
  }

  if (posts.length === 0) {
    return showEmptyState ? <NoPosts /> : null;
  }

  return isMobile ? (
    <FooterPostsMobile type={type} posts={posts} />
  ) : (
    <FooterPostsDesktop type={type} posts={posts} prefetchPost={prefetchPost} />
  );
};

FooterPosts.TYPE_NEW_POSTS = TYPE_NEW_POSTS;
FooterPosts.TYPE_RELATED_POSTS = TYPE_RELATED_POSTS;

FooterPosts.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  showEmptyState: PropTypes.bool.isRequired,
  posts: PropTypes.array,
  prefetchPost: PropTypes.bool,
  type: PropTypes.string,
};

FooterPosts.defaultProps = {
  showEmptyState: false,
  prefetchPost: false,
  type: TYPE_NEW_POSTS,
};

const mapRuntimeToProps = (state, { type }) => {
  const isRelatedPostEnabled =
    isExperimentEnabled(state, EXPERIMENT_RELATED_POSTS) && type === TYPE_RELATED_POSTS;
  let posts;
  if (isRelatedPostEnabled) {
    posts = getRelatedAndNewPosts(state);
  } else {
    type = TYPE_NEW_POSTS;
    posts = getNewPosts(state);
  }

  return {
    isLoading: getIsFooterPostsLoading(state),
    posts,
    type,
  };
};

export default flowRight(withDeviceType, connect(mapRuntimeToProps))(FooterPosts);
