import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './read-only-input.scss';
import withFontClassName from '../../hoc/with-font-class-name';

class ReadOnlyInput extends Component {
  static propTypes = {
    value: PropTypes.string,
    autoFocus: PropTypes.bool,
    contentFontClassName: PropTypes.string.isRequired,
  };

  componentDidMount() {
    if (!this.props.autoFocus) {
      return;
    }

    // Needed to focus field on iOS
    try {
      const event = document.createEvent('TouchEvent');
      event.initEvent('touchstart', true, true);
      this.input.dispatchEvent(event);
    } catch (e) {
      this.handleTouchStart();
    }
  }

  handleTouchStart = () => {
    this.input.focus();
    this.input.setSelectionRange(0, this.input.value.length);
  };

  setInput = element => {
    this.input = element;
  };

  render() {
    return (
      <div className={styles.container}>
        <input
          className={classNames(styles.input, this.props.contentFontClassName, 'forum-text-color')}
          type="text"
          ref={this.setInput}
          value={this.props.value}
          onTouchStart={this.handleTouchStart}
          onClick={this.handleTouchStart}
          readOnly
          data-hook="input-field"
          aria-label={this.props.value}
        />
        <div className={classNames(styles.borders, 'forum-text-border-color')} />
      </div>
    );
  }
}

export default withFontClassName(ReadOnlyInput);
