import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { LAYOUT_TYPE_PINBOARD } from '../../constants/layout-types';
import Page from '../../components/page';
import CategoryList from '../../components/category-list';
import CategoryListDesktop from '../../components/category-list-desktop';
import CategoryListClassic from '../../components/category-list-classic';
import FooterPosts from '../../components/footer-posts';
import { getCategories } from '../../selectors/categories-selectors';
import {
  getIsViewsCountEnabled,
  getIsFooterPostsEnabled,
} from '../../selectors/app-settings-selectors';
import { getMainPageLayoutType } from '../../selectors/layout-selectors';
import forDevice from '../../hoc/for-device';
import ForumDescription from '../../components/forum-description';
import forDesktopOnly from '../../hoc/for-desktop-only';
import EmptyLayout from '../../components/layout/empty-layout';
import LargeSpacingLayout from '../../components/layout/large-spacing-layout';
import styles from './categories-page.scss';

const Layout = forDevice(EmptyLayout, LargeSpacingLayout);
const List = forDevice(CategoryList, CategoryListDesktop);
const ForumDescriptionDesktopOnly = forDesktopOnly(ForumDescription);

export class CategoriesPage extends Component {
  render() {
    const { categories, layoutType, isViewsCountEnabled, isFooterPostsEnabled } = this.props;
    return (
      <Page>
        <Layout>
          <ForumDescriptionDesktopOnly />
          {layoutType === LAYOUT_TYPE_PINBOARD ? (
            <List isViewsCountEnabled={isViewsCountEnabled} categories={categories} />
          ) : (
            <CategoryListClassic
              isViewsCountEnabled={isViewsCountEnabled}
              categories={categories}
            />
          )}
          {isFooterPostsEnabled && (
            <div className={styles.footerPosts}>
              <FooterPosts showEmptyState={true} prefetchPost={true} />
            </div>
          )}
        </Layout>
      </Page>
    );
  }
}

CategoriesPage.propTypes = {
  categories: PropTypes.array,
  isViewsCountEnabled: PropTypes.bool.isRequired,
  layoutType: PropTypes.number,
  redirectToCategory: PropTypes.func,
  isFooterPostsEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  categories: getCategories(state),
  layoutType: getMainPageLayoutType(state, host.style),
  isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
  isFooterPostsEnabled: getIsFooterPostsEnabled(state, host.style),
  redirectToCategory: categorySlug => actions.navigateWithinForum(`/${categorySlug}`),
});

export default flowRight(connect(mapRuntimeToProps))(CategoriesPage);
