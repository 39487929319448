import PropTypes from 'prop-types';
import React, { Component } from 'react';

import styles from './scrollable-container.scss';

class ScrollableContainer extends Component {
  componentDidMount = () => {
    this.scrollableContainer.scrollTop = 0;
  };

  setScrollableContainer = element => (this.scrollableContainer = element);

  render() {
    const { children } = this.props;

    return (
      <div className={styles.container} ref={this.setScrollableContainer}>
        {children}
      </div>
    );
  }
}

ScrollableContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScrollableContainer;
