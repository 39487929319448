import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import PostHeader from '../post-header';
import PostTitle from '../post-title';
import PostSubTitle from '../post-sub-title';
import PostFooter from '../post-footer';
import ContentExcerpt from '../content-excerpt';
import { getCategory } from '../../selectors/categories-selectors';
import { isQACategoriesExperimentEnabled } from '../../selectors/experiments-selectors';
import { isMultiplePostTypeCategory } from '../../services/categories-service';
import contentStateToString from '../../services/content-state-to-string';
import Link from '../link/internal-link';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withPermissions from '../../hoc/with-permissions';
import withAuth from '../../hoc/with-auth';
import withTranslate from '../../hoc/with-translate';
import styles from './footer-posts-item-desktop.scss';

const FooterPostsItem = ({
  category,
  post,
  query,
  borderWidth,
  titleFontClassName,
  contentFontClassName,
  forPublicUser,
  onLikeClick,
  showCategoryLink,
  fetchPostPageData,
  prefetchPost,
  isQACategoriesEnabled,
}) => {
  const className = classNames(
    styles.container,
    contentFontClassName,
    'forum-text-color',
    'forum-card-background-color',
    'forum-card-border-color',
    'post-list-item',
  );

  const postLink = `/${category.slug}/${post.slug}`;
  const content = contentStateToString(post.content);
  const isEmptyContent = content.trim() === '';

  return (
    <div
      className={className}
      style={{ borderWidth }}
      role="article"
      data-hook="post-list-item"
      onMouseEnter={() => prefetchPost && fetchPostPageData(post.slug)}
    >
      <PostHeader
        type={PostHeader.FOOTER_POST}
        post={post}
        showPinIcon={false}
        showLockIcon={false}
        showTopComment={false}
        showMoreButton={false}
        showLastActivityDate={true}
        showDateBelowAvatar={true}
        showAvatarBadges={true}
        showStrippedBadges={true}
        truncateBadgeTitle={true}
        isQACategoriesEnabled={isQACategoriesEnabled}
      />
      <div className={styles.titleAndContent}>
        <PostTitle
          className={classNames(titleFontClassName, 'forum-text-color', styles.title)}
          type={PostTitle.FOOTER_POST}
          title={post.title}
          query={query}
          to={postLink}
          post={post}
          isQACategoriesEnabled={isQACategoriesEnabled}
          clamp={2}
        />
        <PostSubTitle
          post={post}
          category={category}
          isQACategoriesEnabled={isQACategoriesEnabled}
          showCategoryLink={isQACategoriesEnabled ? false : showCategoryLink}
          showPostType={isMultiplePostTypeCategory(category)}
        />
        {!isEmptyContent && (
          <div className={classNames(styles.content)}>
            <ContentExcerpt
              type={ContentExcerpt.FOOTER_POST}
              content={content}
              query={query}
              isContentConverted
              clamp={2}
            />
          </div>
        )}
      </div>
      <div>
        <PostFooter
          type={PostFooter.FOOTER_POST}
          forPublicUser={forPublicUser}
          onLikeClick={onLikeClick}
          post={post}
          Link={Link}
          postLink={postLink}
          isViewsCountEnabled={false}
          showSeparator={true}
          isQACategoriesEnabled={isQACategoriesEnabled}
        />
      </div>
    </div>
  );
};

FooterPostsItem.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  t: PropTypes.func,
  category: PropTypes.object,
  post: PropTypes.object.isRequired,
  query: PropTypes.string,
  type: PropTypes.string,
  borderWidth: PropTypes.number,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  can: PropTypes.func,
  navigation: PropTypes.object,
  forPublicUser: PropTypes.func.isRequired,
  showCategoryLink: PropTypes.bool,
  fetchPostPageData: PropTypes.func.isRequired,
  prefetchPost: PropTypes.bool,
};

FooterPostsItem.defaultProps = {
  showCategoryLink: false,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  return {
    category: getCategory(state, ownProps.post.categoryId),
    onLikeClick: actions.incrementPostLikeCount,
    fetchPostPageData: actions.fetchPostPageData,
    isQACategoriesEnabled: isQACategoriesExperimentEnabled(state),
  };
};

export default flowRight(
  withCardBorderWidth,
  withFontClassName,
  withPermissions,
  withAuth,
  withTranslate,
  connect(mapRuntimeToProps),
)(FooterPostsItem);
