import { isString, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../../common/components/runtime-context';
import classNames from 'classnames';
import { ModalCentered, ModalHeader } from '../base';
import { getAllCategories } from '../../../selectors/categories-selectors';
import RadioList from '../../radio-list';
import ButtonGroup from '../../button-group';
import withTranslate from '../../../hoc/with-translate';
import withFontClassName from '../../../hoc/with-font-class-name';
import styles from './choose-category-modal-component.scss';
import {
  CATEGORY_TYPE_MEMBERS_ONLY,
  CATEGORY_TYPE_PRIVATE,
} from '../../../constants/category-types';
import { MembersIcon } from '../../icons/members-icon';
import { LockEmptyIcon } from '../../icons/lock-empty-icon';
import withPermissions from '../../../hoc/with-permissions';
import { getRoute } from '../../../../common/router/router-selectors';
import { ROUTE_CREATE_POST } from '../../../constants/routes';
import { DISCUSSION, QUESTION } from '../../../constants/post-types';

class ChooseCategoryModal extends Component {
  state = {
    categoryId: null,
  };

  handleCategoryChange = categoryId => this.setState({ categoryId });

  handleChoose = () => this.props.choose(this.state.categoryId);

  getIconByCategoryType(category) {
    const categoryType = category.type || (category.isPrivate && CATEGORY_TYPE_MEMBERS_ONLY);
    switch (categoryType) {
      case CATEGORY_TYPE_MEMBERS_ONLY:
        return MembersIcon;
      case CATEGORY_TYPE_PRIVATE:
        return LockEmptyIcon;
      default:
        return;
    }
  }

  render() {
    const { categories, closeModal, contentFontClassName, can, t, isDiscussionRoute } = this.props;
    const postType = isDiscussionRoute ? DISCUSSION : QUESTION;
    const options = categories.map(category => {
      const isWrongPostType = !category.postTypes.includes(postType);
      const isDisabled = !can('read', 'category', category) || isWrongPostType;
      return {
        label: category.label,
        value: category._id,
        postTypes: category.postTypes,
        isIndented: isString(category.parentId),
        Icon: this.getIconByCategoryType(category),
        isDisabled,
        isCurrentCategory: false,
        isWrongPostType,
      };
    });

    return (
      <ModalCentered onClose={closeModal}>
        <div
          className={classNames(contentFontClassName, 'default-desktop-header-text-color')}
          data-hook="choose-category-modal"
        >
          <ModalHeader>{t('choose-category-modal.title')}</ModalHeader>
          <p className={styles.subtitle}>{t('choose-category-modal.subtitle')}</p>
          <div className={styles.selectWrapper}>
            <RadioList
              options={options}
              value={this.state.categoryId}
              onChange={this.handleCategoryChange}
            />
          </div>
          <ButtonGroup
            primaryButtonText={t('post-form.publish')}
            primaryButtonProps={{
              onClick: this.handleChoose,
              disabled: this.state.categoryId === null,
            }}
            secondaryButtonText={t('move-to-category.cancel')}
            secondaryButtonProps={{ onClick: () => closeModal() }}
            isCentered
          />
        </div>
      </ModalCentered>
    );
  }
}

ChooseCategoryModal.propTypes = {
  choose: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
  isDiscussionRoute: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps) => ({
  categories: getAllCategories(state),
  choose: categoryId => ownProps.closeModal({ categoryId }),
  isDiscussionRoute: getRoute(state) === ROUTE_CREATE_POST,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withFontClassName,
  withTranslate,
  withPermissions,
)(ChooseCategoryModal);
