import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { EditIcon } from '../../icons/edit-icon';
import { DeleteIcon } from '../../icons/delete-icon';
import { MoveUpIcon } from '../../icons/move-up-icon';
import { MoveRightIcon } from '../../icons/move-right-icon';
import ActionButton from '../../action-button';
import withTranslate from '../../../hoc/with-translate';
import styles from './manage-categories-card-actions.scss';
import withAuth from '../../../hoc/with-auth';

export class ManageCategoriesCardActions extends Component {
  render() {
    const { onEdit, onDelete, onMoveToChild, onMoveToTop, t, isOwner } = this.props;

    const moveToChildButton = (
      <ActionButton dataHook="manage-categories-actions__move-to-child" onClick={onMoveToChild}>
        <MoveRightIcon className="forum-icon-fill" />
        {t('manage-categories-actions.move-to-child')}
      </ActionButton>
    );

    const moveToTopButton = (
      <ActionButton dataHook="manage-categories-actions__move-to-top" onClick={onMoveToTop}>
        <MoveUpIcon className="forum-icon-fill" />
        {t('manage-categories-actions.move-to-top')}
      </ActionButton>
    );

    const editButton = (
      <ActionButton dataHook="manage-categories-actions__edit" onClick={onEdit}>
        <EditIcon className="forum-icon-fill" />
        {t('manage-categories-actions.edit')}
      </ActionButton>
    );

    const deleteButton = (
      <ActionButton dataHook="manage-categories-actions__delete" onClick={onDelete}>
        <DeleteIcon className="forum-icon-fill" />
        {t('manage-categories-actions.delete')}
      </ActionButton>
    );

    return (
      <div className={styles.container}>
        {onMoveToChild && moveToChildButton}
        {onMoveToTop && moveToTopButton}
        {onEdit && editButton}
        {isOwner && onDelete && deleteButton}
      </div>
    );
  }
}

ManageCategoriesCardActions.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveToChild: PropTypes.func,
  onMoveToTop: PropTypes.func,
  isOwner: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

// prettier-ignore
export default flowRight(
  withTranslate,
  withAuth,
)(ManageCategoriesCardActions);
