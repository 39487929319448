import React from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import PostLink from '../link/post-link';
import withTranslate from '../../hoc/with-translate';
import styles from './post-page-sidebar-new-posts.scss';

const PostPageSidebarNewPosts = ({ posts, showRelatedPosts, t }) => {
  return posts.length === 0 ? null : (
    <section
      className={classNames(styles.container, 'forum-card-border-color', 'forum-text-color')}
    >
      <h4 className={styles.title}>
        {t(
          showRelatedPosts
            ? 'post-page-sidebar-new-posts.related-posts'
            : 'post-page-sidebar-new-posts.title',
        )}
      </h4>
      {posts.map(post => (
        <PostLink
          className={classNames(styles.post, 'forum-text-color', 'forum-link-hover-color')}
          post={post}
        >
          {post.title}
        </PostLink>
      ))}
    </section>
  );
};

PostPageSidebarNewPosts.propsTypes = {
  posts: PropTypes.array,
};

export default flowRight(withTranslate)(PostPageSidebarNewPosts);
