import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import NotificationButton from '../notification-button';
import SearchButton from '../search-button';
import ProtectedButton from '../../containers/protected-button';
import HeaderAvatarImage from '../header-avatar-image';
import ProfileActionsButton from '../profile-actions-button';
import MoreButton from '../more-button';
import CategoryActions from '../../containers/category-actions';
import ForumActions from '../../containers/breadcrumbs-widget/breadcrumbs-widget-actions';
import MoreIconCenter from '../icons/more-icon-center';
import NotificationsLink from '../link/notifications-link';
import PrivateProfileActions from '../../containers/private-profile-actions';
import { getIsLoginWidgetEnabled } from '../../selectors/app-settings-selectors';
import { getCategoryBySlug } from '../../selectors/categories-selectors';
import withAuth from '../../hoc/with-auth';
import withTranslate from '../../hoc/with-translate';
import withLayoutType from '../../hoc/with-layout-type';
import withPermissions from '../../hoc/with-permissions';
import styles from './header-buttons.scss';

export const HeaderButtons = ({
  currentUser,
  isAuthenticated,
  t,
  isLoginWidgetEnabled,
  category = {},
  canRender,
}) => (
  <div className={classNames(styles.container, 'header-buttons')}>
    <div className={styles.searchButtonSpacing} data-hook="search-button">
      <SearchButton />
    </div>
    {isLoginWidgetEnabled && renderNotificationButton(isAuthenticated, NotificationsLink)}
    {renderActions(isAuthenticated, category, canRender)}
    {isLoginWidgetEnabled && renderAvatar(isAuthenticated, currentUser, t)}
  </div>
);

function renderNotificationButton(isAuthenticated, NotificationsLink) {
  if (isAuthenticated) {
    return (
      <div className={styles.notificationButtonSpacing}>
        <NotificationButton
          component={NotificationsLink}
          iconClassName="forum-icon-fill"
          bubbleClassName="button-background-color"
        />
      </div>
    );
  }
}

function renderAvatar(isAuthenticated, currentUser, t) {
  if (isAuthenticated) {
    return (
      <ProfileActionsButton
        actionsContainerClassName={styles.profileActions}
        position={ProfileActionsButton.POSITION_RIGHT}
        icon={<HeaderAvatarImage user={currentUser} />}
      >
        <PrivateProfileActions user={currentUser} />
      </ProfileActionsButton>
    );
  }

  return (
    <ProtectedButton
      tagName={'span'}
      className={classNames(styles.signUp, 'forum-text-color', 'header-buttons__sign-up')}
    >
      {t('header-buttons.sign-up')}
    </ProtectedButton>
  );
}

function renderActions(isAuthenticated, category, canRender) {
  if (!isAuthenticated) {
    return null;
  }

  const icon = <MoreIconCenter />;
  const manageCategoriesButton = canRender('edit', 'category', can =>
    can ? <ForumActions /> : null,
  );
  const Actions = category._id ? <CategoryActions category={category} /> : manageCategoriesButton;

  if (!Actions) {
    return null;
  }

  return (
    <div className={classNames(styles.actions, 'forum-icon-fill')}>
      <MoreButton icon={icon}>{Actions}</MoreButton>
    </div>
  );
}

HeaderButtons.propTypes = {
  currentUser: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  t: PropTypes.func,
  isLoginWidgetEnabled: PropTypes.bool,
  params: PropTypes.object.isRequired,
  category: PropTypes.object,
  canRender: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isLoginWidgetEnabled: getIsLoginWidgetEnabled(state, host.style),
  category: ownProps.params.categorySlug
    ? getCategoryBySlug(state, ownProps.params.categorySlug)
    : {},
});

export default flowRight(
  connect(mapRuntimeToProps),
  withAuth,
  withTranslate,
  withLayoutType,
  withPermissions,
)(HeaderButtons);
