import PropTypes from 'prop-types';
import React from 'react';
import { ModalCentered } from '../base';
import ShareForm from '../../share-form';
import withTranslate from '../../../hoc/with-translate';
import { MODAL_TYPE_SHARE_COMMENT } from './share-comment-modal-type';

const ShareCommentModal = ({ closeModal, commentId, t }) => (
  <ModalCentered onClose={closeModal}>
    <ShareForm
      closeModal={closeModal}
      title={t('share-comment-modal.share-comment')}
      path={`/main/comment/${commentId}`}
      modalType={MODAL_TYPE_SHARE_COMMENT}
    />
  </ModalCentered>
);

ShareCommentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  commentId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslate(ShareCommentModal);
