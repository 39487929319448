import { flowRight, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { EXPERIMENT_FORCE_USER_OPEN_SETTINGS } from '../../constants/experiments';
import {
  DEMO_MODE_EDITOR,
  DEMO_MODE_PREVIEW,
  DEMO_MODE_SITE,
} from '../../components/messages/message-types';
import messages from '../../components/messages/messages';
import Message from '../../components/messages/message';
import { isExperimentEnabled } from '../../selectors/experiments-selectors';
import { isTemplate } from '../../services/is-template';
import { getViewMode } from '../../../common/store/basic-params/basic-params-selectors';
import { getIsDemoMode } from '../../../common/store/instance-values/instance-values-selectors';
import { getSectionUrl } from '../../../common/store/location/location-selectors';
import withTranslate from '../../hoc/with-translate';
import { VIEW_MODE_SITE } from '../../../common/constants/view-modes';

const getMessageType = viewMode => {
  if (viewMode === 'editor') {
    return DEMO_MODE_EDITOR;
  } else if (viewMode === 'preview') {
    return DEMO_MODE_PREVIEW;
  } else {
    return DEMO_MODE_SITE;
  }
};

const DemoMessageRoot = ({
  viewMode,
  isTemplate,
  isDemoMode,
  isForceUserOpenSettingsEnabled,
  wasAppSettingsOpened,
}) => {
  if (
    (isDemoMode ||
      (isForceUserOpenSettingsEnabled
        ? !wasAppSettingsOpened && viewMode !== VIEW_MODE_SITE
        : false)) &&
    !isTemplate
  ) {
    const messageType = getMessageType(viewMode);
    const message = messages[messageType];
    return <Message message={message} alignment="center" />;
    // @todo: s.dubinskas: fix positioning
  }
  return null;
};

DemoMessageRoot.propTypes = {
  viewMode: PropTypes.string,
  isTemplate: PropTypes.bool,
  isDemoMode: PropTypes.bool,
  isForceUserOpenSettingsEnabled: PropTypes.bool,
  wasAppSettingsOpened: PropTypes.bool,
  t: PropTypes.func,
};

const mapStateToProps = (state, ownProps, actions, host) => ({
  viewMode: getViewMode(state),
  isDemoMode: getIsDemoMode(state),
  isForceUserOpenSettingsEnabled: isExperimentEnabled(state, EXPERIMENT_FORCE_USER_OPEN_SETTINGS),
  wasAppSettingsOpened: !isEmpty(host.style.styleParams.colors),
  isTemplate: isTemplate(getSectionUrl(state)),
});

export default flowRight(
  connect(mapStateToProps),
  withTranslate,
)(DemoMessageRoot);
