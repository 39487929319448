import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import AppComponent from '../../components/app';
import AppLoaded from '../../components/app-loaded';
import withTranslate from '../../hoc/with-translate';
import StatusIndicator from '../../components/status-indicator';

export class App extends Component {
  render() {
    const { t } = this.props;
    const app = get(this.props.categories, 'length') ? (
      <AppComponent {...this.props} />
    ) : (
      <div>{t('app.loading')}</div>
    );
    return [app, <AppLoaded />, <StatusIndicator />];
  }
}

App.propTypes = {
  categories: PropTypes.array,
};

const mapRuntimeToProps = state => ({
  categories: state.categories,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(App);
