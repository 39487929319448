import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from '../../checkbox';

import withTranslate from '../../../hoc/with-translate';
import styles from './manage-category-members-list-item.scss';
import { Badge } from '../../../containers/badge';
import withFontClassName from '../../../hoc/with-font-class-name';
import withSettingsColor from '../../../hoc/with-settings-color';
import { getValidityLabel } from '../../../services/get-validity-label';

const ManageCategoryMembersListItem = ({
  category,
  group,
  borderColor,
  isBadge,
  isRole,
  isPlan,
  badge,
  paidPlan,
  formApi,
  t,
}) => {
  const isGroupAssignedToCategory = category.groups && category.groups.indexOf(group.id) > -1;

  let leftContent;

  if (isBadge) {
    leftContent = (
      <React.Fragment>
        <h4 className={styles.title}>
          {badge ? <Badge badge={badge} size="small" onClick={null} /> : group.title}
        </h4>
        <p className={styles.description}>
          {t('manage-category-members-list-item.members-count', { count: group.membersCount || 0 })}
        </p>
      </React.Fragment>
    );
  }

  if (isRole) {
    leftContent = (
      <React.Fragment>
        <h4 className={styles.title}>{group.title}</h4>
        <p className={styles.description}>
          {t('manage-category-members-list-item.members-count', { count: group.membersCount || 0 })}
        </p>
      </React.Fragment>
    );
  }

  if (isPlan) {
    const details = [];
    if (paidPlan) {
      const { paymentOptions } = paidPlan;
      const { price, freeTrialDays, validFor } = paymentOptions;
      details.push(
        <span>
          {price.amount} {price.currency}
        </span>,
      );
      details.push(<span> &middot; </span>);
      details.push(
        <span>
          {t(getValidityLabel(paymentOptions), { amount: get(validFor, 'period.amount') })}
        </span>,
      );
      if (freeTrialDays) {
        details.push(<span> &middot; </span>);
        details.push(<span>{t('paid-plan.free-trial-days', { freeTrialDays })}</span>);
      }
    }

    leftContent = (
      <React.Fragment>
        <h4 className={styles.title}>
          {group.title}{' '}
          <span className={styles.membersCount}>
            {t('manage-category-members-list-item.members-count', {
              count: group.membersCount || 0,
            })}
          </span>
        </h4>
        <p className={styles.description}>{details}</p>
      </React.Fragment>
    );
  }
  const handleAddToggle = () => formApi.formToggleCheckbox(isGroupAssignedToCategory, group.id);
  return (
    <div onClick={handleAddToggle} className={styles.container} style={{ borderColor }}>
      <div className={styles.checkboxWrapper}>
        <Checkbox checked={formApi.getCheckboxValue(isGroupAssignedToCategory, group.id)} />
      </div>
      <div className={styles.left}>{leftContent}</div>
    </div>
  );
};

ManageCategoryMembersListItem.propTypes = {
  category: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  badge: PropTypes.object,
  paidPlan: PropTypes.object,
  contentFontClassName: PropTypes.func,
  isBadge: PropTypes.bool,
  isRole: PropTypes.bool,
  isPlan: PropTypes.bool,
  formApi: PropTypes.object,
};

export default flowRight(
  withTranslate,
  withFontClassName,
  withSettingsColor({
    propName: 'borderColor',
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.2,
    fallbackColor: 'rgba(51, 51, 51, 0.2)',
  }),
)(ManageCategoryMembersListItem);
