import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '../link/internal-link';
import ArrowSubcategoriesIcon from '../icons/arrow-subcategories-icon';
import styles from './post-page-sidebar-category-list-item.scss';

const PostPageSidebarCategoryListItem = ({ category, subcategories }) => {
  const categoryLink = `/${category.slug}`;

  const renderSubcategories = () => {
    return (
      <div className={styles.subcategoryList}>
        {subcategories.map(subcategory => {
          const subCategoryLink = `/${subcategory.slug}`;
          return (
            <Link
              className={classNames(styles.link, 'forum-text-color', 'forum-link-hover-color')}
              to={subCategoryLink}
            >
              <ArrowSubcategoriesIcon
                className={classNames(styles.subcategoryIcon, 'forum-icon-fill')}
              />
              <span className={styles.label}>{subcategory.label}</span>
            </Link>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <Link
        className={classNames(styles.link, 'forum-text-color', 'forum-link-hover-color')}
        to={categoryLink}
      >
        <span className={styles.label}> {category.label}</span>
      </Link>
      {subcategories && renderSubcategories()}
    </div>
  );
};

PostPageSidebarCategoryListItem.propTypes = {
  subcategories: PropTypes.array.isRequired,
  category: PropTypes.object.isRequired,
};

export default PostPageSidebarCategoryListItem;
