import { flowRight } from 'lodash';
import React, { Component } from 'react';
import withTranslate from '../../../hoc/with-translate';
import { FixedSizeList } from 'react-window';
import { getAllBadges } from '../../../selectors/badge-selectors';
import withSettingsColor from '../../../hoc/with-settings-color';
import withFontClassName from '../../../hoc/with-font-class-name';
import { getMembersGroups } from '../../../selectors/members-groups-selectors';
import withDirection from 'react-with-direction';
import { connect } from '../../../../common/components/runtime-context';
import withDeviceType from '../../../hoc/with-device-type';
import { SiteMembersListItem } from './manage-category-members-sm-list-item';

class SiteMembersList extends Component {
  render() {
    const { loadData, isMobile } = this.props;
    return (
      <FixedSizeList
        height={isMobile ? Math.min(window.innerHeight - 140, 500) : 310}
        width={560}
        itemCount={this.props.totalSiteMembersCount}
        itemSize={56}
        onItemsRendered={loadData}
        itemData={this.props}
      >
        {SiteMembersListItem}
      </FixedSizeList>
    );
  }
}

export default flowRight(
  withTranslate,
  withFontClassName,
  withDirection,
  withDeviceType,
  withSettingsColor({
    propName: 'borderColor',
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.2,
    fallbackColor: 'rgba(51, 51, 51, 0.2)',
  }),
  connect(state => ({
    badges: getAllBadges(state),
    groups: getMembersGroups(state),
  })),
)(SiteMembersList);
