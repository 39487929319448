import PropTypes from 'prop-types';
import React from 'react';
import DesktopHeader from '../desktop-header';
import Breadcrumbs from '../../components/breadcrumbs';
import SearchHeader from './search-header';

const SearchDesktopHeader = ({ routes, params, ...props }) => (
  <SearchHeader
    component={DesktopHeader}
    left={<Breadcrumbs routes={routes} params={params} />}
    inputClassName="desktop-header-search-text-color desktop-header-search-font"
    borderClassName="desktop-header-search-border-background-color"
    iconClassName="desktop-header-search-icon-fill"
    params={params}
    {...props}
  />
);

SearchDesktopHeader.propTypes = {
  routes: PropTypes.array,
  params: PropTypes.object.isRequired,
};

export default SearchDesktopHeader;
