import { debounce, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import SearchInput from '../../components/search-input';
import BreadcrumbsWidget from '../breadcrumbs-widget';
import { SEARCH_RESULTS } from '../../constants/page-names';
import { decodeQuery, encodeQuery } from '../../services/query-encoding';
import { MIN_QUERY_LENGTH } from '../../actions/search';

export const DEBOUNCE_WAIT = 800;

export class SearchHeader extends Component {
  componentDidMount() {
    this.props.whenWorkerInitialized.then(() => {
      this.props.pageOpened({ page: SEARCH_RESULTS });
    });
  }

  handleQueryChange = debounce(query => this.props.onQueryChange(query), DEBOUNCE_WAIT);

  render() {
    const {
      component: Component,
      left,
      query,
      inputClassName,
      iconClassName,
      borderClassName,
      showWidget,
      params,
      ...props
    } = this.props;

    const searchInput = (
      <SearchInput
        isExpanded
        isFullWidth={!showWidget}
        value={query}
        onChange={this.handleQueryChange}
        inputClassName={inputClassName}
        iconClassName={iconClassName}
        borderClassName={borderClassName}
      />
    );

    let right;
    if (showWidget) {
      right = <BreadcrumbsWidget searchInput={searchInput} params={params} />;
    } else {
      right = searchInput;
    }

    return <Component left={left} right={right} {...props} />;
  }
}

SearchHeader.propTypes = {
  component: PropTypes.any,
  left: PropTypes.node,
  query: PropTypes.string.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  borderClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  pageOpened: PropTypes.func,
  whenWorkerInitialized: PropTypes.func,
  showWidget: PropTypes.bool,
  params: PropTypes.object.isRequired,
};

SearchHeader.defaultProps = {
  showWidget: true,
};

const mapRuntimeToProps = (state, ownProps, actions, host, whenWorkerInitialized) => ({
  query: decodeURIComponent(decodeQuery(get(ownProps, 'params.query', ''))),
  onQueryChange: query => {
    actions.clearSearchResults();
    if (query && query.length >= MIN_QUERY_LENGTH) {
      actions.setIsLoading('search', '', true);
      actions.search({ query: query });
      actions.navigateWithinForum(`/search${query ? `/${encodeQuery(query)}` : ''}`);
    }
  },
  pageOpened: actions.pageOpened,
  whenWorkerInitialized,
});

export default connect(mapRuntimeToProps)(SearchHeader);
