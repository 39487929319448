import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';
import { IS_CHILDREN_USER_HIDDEN } from './list-container';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 10000,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  direction: 'ltr',
};

function getItemStyles(props) {
  const { currentOffset } = props;
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate3d(${x}px, ${y}px, 0)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

class CustomDragLayer extends Component {
  renderItem(item) {
    const { data, renderListItemPreview } = this.props;
    const itemData = data.find(a => a.id === item.id);
    return renderListItemPreview(itemData, {
      isChildrenHidden: itemData[IS_CHILDREN_USER_HIDDEN],
    });
  }

  render() {
    const { item, isDragging } = this.props;
    if (!isDragging) {
      return null;
    }

    return (
      <div style={layerStyles}>
        <div style={getItemStyles(this.props)}>
          <div style={{ direction: 'ltr', display: 'inline-block' }}>{this.renderItem(item)}</div>
        </div>
      </div>
    );
  }
}

CustomDragLayer.propTypes = {
  item: PropTypes.object,
  currentOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  isDragging: PropTypes.bool.isRequired,
  data: PropTypes.array,
  renderListItemPreview: PropTypes.func,
};

function collect(monitor) {
  return {
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  };
}

/* eslint-disable new-cap */
export default DragLayer(collect)(CustomDragLayer);
