import PropTypes from 'prop-types';
import React from 'react';
import { ModalCentered } from '../base';
import DemoConfirmation from '../../demo-confirmation';
import withTranslate from '../../../hoc/with-translate';

export const DemoModal = ({ viewMode, isTemplate, closeModal, t }) => {
  let type = viewMode;
  if (viewMode === 'site' && isTemplate) {
    type = 'template';
  }

  return (
    <ModalCentered onClose={closeModal}>
      <DemoConfirmation
        title={t('demo-modal.demo-mode')}
        message={t(`demo-modal.message-${type}`)}
        confirmButton={t(
          viewMode === 'preview' ? 'demo-modal.activate-forum' : 'demo-modal.confirm',
        )}
        onConfirm={() => {
          closeModal(viewMode === 'preview');
        }}
        onCancel={closeModal}
      />
    </ModalCentered>
  );
};

DemoModal.propTypes = {
  viewMode: PropTypes.string.isRequired,
  isTemplate: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslate(DemoModal);
