import React from 'react';

const FacebookIcon = props => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Popups-.-desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Notifications-Settings-Copy-2"
        transform="translate(-528.000000, -489.000000)"
        fill="#333333"
      >
        <g id="Group-10" transform="translate(430.000000, 255.000000)">
          <g id="Group-3" transform="translate(80.000000, 132.000000)">
            <g id="Icons" transform="translate(18.000000, 102.000000)">
              <path
                d="M22.6754242,0 L1.32457577,0 C0.592866804,0 0,0.592956728 0,1.32457577 L0,22.6755142 C0,23.4070433 0.592866804,24.0001798 1.32457577,24.0001798 L12.8191597,24.0001798 L12.8191597,14.7060283 L9.69152432,14.7060283 L9.69152432,11.0839025 L12.8191597,11.0839025 L12.8191597,8.4127197 C12.8191597,5.3128707 14.7124128,3.62491335 17.4777458,3.62491335 C18.8023215,3.62491335 19.9408452,3.72346971 20.2726636,3.76753231 L20.2726636,7.0071227 L18.3546815,7.00802194 C16.8507979,7.00802194 16.5595351,7.7226454 16.5595351,8.77133533 L16.5595351,11.0839025 L20.1463208,11.0839025 L19.6792572,14.7060283 L16.5595351,14.7060283 L16.5595351,24.0001798 L22.6754242,24.0001798 C23.4068634,24.0001798 24,23.4070433 24,22.6755142 L24,1.32457577 C24,0.592956728 23.4068634,0 22.6754242,0"
                id="Fill-1"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default FacebookIcon;
