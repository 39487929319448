import { cloneDeep } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import produce from 'immer';
import ListItem from './list-item';
import ListItemPreview from './list-item-preview';

export const MOVE_TYPE_DRAG = 'drag';
export const MOVE_TYPE_THREE_DOT = 'three_dot';

// meta properties
export const IS_CHILD = 'tree-list/is-child';
export const IS_CHILDREN_USER_HIDDEN = 'tree-list/is-children-user-hidden';
export const IS_CHILDREN_DRAG_HIDDEN = 'tree-list/is-children-drag-hidden';
export const IS_DRAG_HIDDEN = 'tree-list/is-drag-hidden';
export const IS_USER_HIDDEN = 'tree-list/is-user-hidden';
export const metaProperties = [
  IS_CHILD,
  IS_CHILDREN_USER_HIDDEN,
  IS_CHILDREN_DRAG_HIDDEN,
  IS_DRAG_HIDDEN,
  IS_USER_HIDDEN,
];

const parentHideTypeByChildren = {
  [IS_DRAG_HIDDEN]: IS_CHILDREN_DRAG_HIDDEN,
  [IS_USER_HIDDEN]: IS_CHILDREN_USER_HIDDEN,
};

export function moveItemInArray(array, startIndex, itemsCount, endIndex) {
  let nextStartIndex = startIndex;
  let nextEndIndex = endIndex;
  const isEndAfter = startIndex > endIndex;

  for (let i = 0; i < itemsCount; i++) {
    const item = array.splice(nextStartIndex, 1);
    array.splice(nextEndIndex, 0, ...item);

    if (isEndAfter) {
      nextStartIndex++;
      nextEndIndex++;
    }
  }

  return array;
}

class ListContainer extends Component {
  constructor(props) {
    super(props);
    this.moveCard = this.moveCard.bind(this);
    this.state = {
      data: props.data,
      isDragging: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data.length !== this.props.data.length) {
      this.setState({ data: nextProps.data });
    }
  }

  moveCard = ({ dragIndex, hoverIndex, isChild, dragId, newIndexCallback, onDone }) => {
    this.setState(
      produce(prevState => {
        const { data } = prevState;
        const childCount = this.getChildrenCount(dragIndex);
        data[dragIndex][IS_CHILD] = isChild;
        moveItemInArray(data, dragIndex, 1 + childCount, hoverIndex);
        newIndexCallback && newIndexCallback(data.findIndex(item => item.id === dragId));
      }),
      () => {
        isChild && this.expandChildrenIfNeeded(hoverIndex);
        this.props.onChange && this.props.onChange(this.state.data);
        onDone && onDone();
      },
    );
  };

  onBeforeMove = (run, index) => {
    if (this.props.onBeforeMove) {
      this.props
        .onBeforeMove(this.getMovedItemData(index))
        .then(run, () => this.setState({ data: this.beforeDragDataCopy }));
    } else {
      run();
    }
  };
  getSiblingsBelow = index => this.state.data.slice(index + 1);

  moveToChild = (index, id) => {
    this.beforeDragDataCopy = cloneDeep(this.state.data);
    this.moveCard({
      dragIndex: index,
      hoverIndex: index,
      isChild: true,
      dragId: id,
      onDone: () => this.onBeforeMove(() => this.emitDragEnd(index, MOVE_TYPE_THREE_DOT), index),
    });
  };

  moveToParent = (index, id) => {
    let moveIndex = index;
    const siblingsBelow = this.getSiblingsBelow(index);
    const move = () => {
      return this.moveCard({
        dragIndex: index,
        hoverIndex: moveIndex,
        isChild: false,
        dragId: id,
        onDone: () => this.emitDragEnd(index, MOVE_TYPE_THREE_DOT),
      });
    };

    if (siblingsBelow.length === 0) {
      return move();
    }

    for (let i = 0; i < siblingsBelow.length; i++) {
      const sibling = siblingsBelow[i];
      if (!sibling[IS_CHILD]) {
        return move();
      }

      moveIndex++;

      if (i === siblingsBelow.length - 1) {
        return move();
      }
    }
  };

  getChildrenCount = index => {
    let childrenCount = 0;
    if (this.state.data[index][IS_CHILD]) {
      return childrenCount;
    }

    const siblingsBelow = this.getSiblingsBelow(index);

    siblingsBelow.every(item => {
      if (item[IS_CHILD]) {
        childrenCount++;
      }

      return item[IS_CHILD];
    });

    return childrenCount;
  };

  isFirstChild = index => {
    if (!this.state.data[index][IS_CHILD]) {
      return false;
    }

    const siblingsAbove = this.state.data[index - 1];
    if (siblingsAbove) {
      return !siblingsAbove[IS_CHILD];
    }
    return false;
  };

  canBeChildAtTarget = index => {
    const previousSibling = this.state.data[index - 1];
    const isPreviousSiblingChild = previousSibling && previousSibling[IS_CHILD];

    return isPreviousSiblingChild || index !== 0;
  };

  canBeParentAtTarget = index => {
    const isChild = this.state.data[index][IS_CHILD];
    const nextSiblingChild = this.state.data[index + 1];
    const isLastChild = !nextSiblingChild || !nextSiblingChild[IS_CHILD];

    return !isChild || isLastChild;
  };

  changeChildrenVisibility = (index, isHidden, hideType) => {
    const childrenCount = this.getChildrenCount(index);
    if (childrenCount > 0) {
      this.setState(
        produce(prevState => {
          prevState.data[index][parentHideTypeByChildren[hideType]] = isHidden;
          prevState.data
            .slice(index + 1, index + 1 + childrenCount)
            .forEach(item => (item[hideType] = isHidden));
        }),
      );
    }
  };

  hideChildrenDrag = parentIndex => {
    this.changeChildrenVisibility(parentIndex, true, IS_DRAG_HIDDEN);
  };

  showChildrenDrag = parentIndex => {
    this.changeChildrenVisibility(parentIndex, false, IS_DRAG_HIDDEN);
  };

  hideChildrenUser = parentIndex => {
    this.changeChildrenVisibility(parentIndex, true, IS_USER_HIDDEN);
  };

  showChildrenUser = parentIndex => {
    this.changeChildrenVisibility(parentIndex, false, IS_USER_HIDDEN);
  };

  expandChildrenIfNeeded = index => {
    const parentIndex = this.getParentIndex(index);
    this.showChildrenUser(parentIndex);
  };

  onDragStart = parentIndex => {
    this.beforeDragDataCopy = cloneDeep(this.state.data);
    this.hideChildrenDrag(parentIndex);
    this.setState({ isDragging: true });
    this.props.onDragStart && this.props.onDragStart();
  };

  onDragEnd = parentIndex => {
    const run = () => {
      this.showChildrenDrag(parentIndex);
      this.setState({ isDragging: false });
      this.emitDragEnd(parentIndex, MOVE_TYPE_DRAG);
    };
    this.onBeforeMove(run, parentIndex);
  };

  emitDragEnd = (index, method) =>
    this.props.onDragEnd && this.props.onDragEnd(this.state.data, index, method);

  getParentIndex = (index, data = this.state.data) => {
    const item = data[index - 1];
    return item[IS_CHILD] ? this.getParentIndex(index - 1, data) : index - 1;
  };

  getMovedItemData = index => {
    const getParentId = (index, data) => {
      const item = data[index];
      return item[IS_CHILD] && data[this.getParentIndex(index)].id;
    };

    return {
      newParentId: getParentId(index, this.state.data),
      oldParentId: getParentId(index, this.beforeDragDataCopy),
      movedItemId: this.state.data[index].id,
    };
  };

  render() {
    const { data, isDragging } = this.state;
    const {
      renderListItem,
      renderListItemPreview,
      childrenIndentation,
      withChildren,
      listClassName,
      listItemClassName,
      childConnectorColor,
      childConnectorMargin,
    } = this.props;
    const dataLength = data.length;

    return (
      <div className={classNames(listClassName, isDragging ? 'is-dragging' : 'is-not-dragging')}>
        <ListItemPreview data={data} renderListItemPreview={renderListItemPreview} />
        {data.map((item, i) => {
          const childrenCount = this.getChildrenCount(i);
          return item[IS_DRAG_HIDDEN] || item[IS_USER_HIDDEN] ? null : (
            <ListItem
              key={item.id}
              index={i}
              id={item.id}
              data={item}
              dataLength={dataLength}
              renderListItem={renderListItem}
              moveCard={this.moveCard}
              isChild={item[IS_CHILD]}
              isFirstChild={this.isFirstChild(i)}
              isHidden={item[IS_DRAG_HIDDEN]}
              isParent={childrenCount > 0}
              childrenCount={childrenCount}
              canBeChildAtTarget={withChildren && this.canBeChildAtTarget(i)}
              canBeParentAtTarget={this.canBeParentAtTarget(i)}
              hideChildrenUser={this.hideChildrenUser}
              showChildrenUser={this.showChildrenUser}
              onDragStart={this.onDragStart}
              moveToParent={this.moveToParent}
              moveToChild={this.moveToChild}
              onDragEnd={this.onDragEnd}
              isChildrenHiddenUser={item[IS_CHILDREN_USER_HIDDEN]}
              childrenIndentation={childrenIndentation}
              listItemClassName={listItemClassName}
              childConnectorColor={childConnectorColor}
              childConnectorMargin={childConnectorMargin}
            />
          );
        })}
      </div>
    );
  }
}

ListContainer.propTypes = {
  data: PropTypes.array.isRequired,
  renderListItem: PropTypes.func.isRequired,
  renderListItemPreview: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  onBeforeMove: PropTypes.func,
  childrenIndentation: PropTypes.number,
  withChildren: PropTypes.bool,
  listClassName: PropTypes.string,
  listItemClassName: PropTypes.string,
  childConnectorColor: PropTypes.string,
  childConnectorMargin: PropTypes.number,
  categories: PropTypes.array,
};

export default ListContainer;
