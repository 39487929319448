import PropTypes from 'prop-types';
import React from 'react';
import { ModalCentered } from '../base';
import ShareForm from '../../share-form';
import withTranslate from '../../../hoc/with-translate';
import { MODAL_TYPE_SHARE_POST } from './share-post-modal-type';

const SharePostModal = ({ closeModal, categorySlug, postSlug, isLinkShareFormVisible, t }) => (
  <ModalCentered onClose={closeModal}>
    <ShareForm
      closeModal={closeModal}
      isLinkShareFormVisible={isLinkShareFormVisible}
      title={t('share-post-modal.share-post')}
      path={`/${categorySlug}/${postSlug}`}
      modalType={MODAL_TYPE_SHARE_POST}
    />
  </ModalCentered>
);

SharePostModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  categorySlug: PropTypes.string.isRequired,
  postSlug: PropTypes.string.isRequired,
  isLinkShareFormVisible: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslate(SharePostModal);
