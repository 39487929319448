import PropTypes from 'prop-types';
import React from 'react';
import withTranslate from '../../hoc/with-translate';

const SymbolsLeftCounter = ({ value, limit, positiveClassName, zeroClassName, t }) => {
  if (!limit) {
    return null;
  }

  const left = limit - value.length;
  const className = left > 0 ? positiveClassName : zeroClassName;
  const role = left > 0 ? '' : 'alert';

  return (
    <div>
      <span
        aria-label={t('manage-categories.characters-left', { count: left, number: left })}
        className={className}
        role={role}
      >
        {left}/{limit}
      </span>
    </div>
  );
};

SymbolsLeftCounter.propTypes = {
  t: PropTypes.func,
  limit: PropTypes.number,
  value: PropTypes.string,
  positiveClassName: PropTypes.string,
  zeroClassName: PropTypes.string,
};

export default withTranslate(SymbolsLeftCounter);
