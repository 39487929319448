import PropTypes from 'prop-types';
import React from 'react';

import { TopCommentIcon } from '../icons/top-comment-icon';
import styles from './top-comment.scss';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_MARK_AS_SOLVED } from '../../constants/experiments';
import { SolvedIcon } from '../icons/solved-icon';

const TopCommentMobile = ({ isMarkAsSolvedExperimentEnabled }) => (
  <div className={styles.topComment} data-hook="top-comment">
    {isMarkAsSolvedExperimentEnabled ? (
      <SolvedIcon className="button-fill" />
    ) : (
      <TopCommentIcon className="button-fill" />
    )}
  </div>
);

TopCommentMobile.propTypes = {
  topCommentColor: PropTypes.string,
  t: PropTypes.func,
};

export default withExperiment({ isMarkAsSolvedExperimentEnabled: EXPERIMENT_MARK_AS_SOLVED })(
  TopCommentMobile,
);
