import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { ModalCentered } from '../base';
import DeleteConfirmation from '../../delete-confirmation';
import withTranslate from '../../../hoc/with-translate';

export const DeleteCategoryModal = ({ closeModal, deleteCategory, t }) => (
  <ModalCentered onClose={closeModal}>
    <DeleteConfirmation
      title={t('delete-category-modal.delete-category')}
      message={t('delete-category-modal.will-delete-posts-and-comments')}
      onConfirm={deleteCategory}
      onCancel={closeModal}
      dataHook="delete-category-modal"
    />
  </ModalCentered>
);

DeleteCategoryModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  categoryId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps) => ({
  deleteCategory: () => ownProps.closeModal(ownProps.categoryId),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(DeleteCategoryModal);
