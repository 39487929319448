import { IS_CHILD } from './list-container';

export function getParent(item, allItems) {
  const itemIndex = allItems.indexOf(item);
  const previousItem = allItems[itemIndex - 1];
  return previousItem[IS_CHILD] ? getParent(previousItem, allItems) : previousItem;
}

export function findById(categories, id) {
  return categories.find(c => c._id === id);
}
