import pica from 'pica/dist/pica';
import fixOrientation from './fix-orientation';

const MAX_WIDTH = 1920;
const MAX_HEIGHT = MAX_WIDTH;
const RESIZE_OPTIONS = {
  alpha: true,
  quality: 3,
};

export const fileToDataUrl = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = event =>
      resolve({
        dataUrl: event.target.result,
        file,
      });
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });

export const fixImageOrientation = imageData =>
  new Promise(resolve =>
    fixOrientation(imageData.dataUrl, dataUrl => resolve({ dataUrl, ...imageData })),
  );

export const resizeImage = (imageData, maxWidth = MAX_WIDTH, maxHeight = MAX_HEIGHT) => {
  const canvas = document.createElement('canvas');
  const img = document.createElement('img');
  let resolveFn;

  img.onload = () => {
    let width = img.width;
    let height = img.height;

    if (width > height && width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    } else if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }

    canvas.width = width;
    canvas.height = height;

    pica.resizeCanvas(img, canvas, RESIZE_OPTIONS, () =>
      resolveFn({
        ...imageData,
        dataUrl: canvas.toDataURL(imageData.file.type),
      }),
    );
  };

  return new Promise(resolve => {
    resolveFn = resolve;
    img.src = imageData.dataUrl;
  });
};

export default function processImage(file, maxWidth, maxHeight) {
  return fileToDataUrl(file)
    .then(fixImageOrientation)
    .then(imageData => resizeImage(imageData, maxWidth, maxHeight));
}
