import { map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { ModalHeader } from '../modals/base';
import InputRadio from '../input-radio';
import ButtonGroup from '../button-group';
import { HorizontalSeparator } from '../separator';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import styles from './report-abuse-form.scss';

export const REASONS = [
  {
    value: '1',
    text: 'report-abuse-form.offensive-content',
  },
  {
    value: '2',
    text: 'report-abuse-form.offensive-media',
  },
  {
    value: '3',
    text: 'report-abuse-form.spam',
  },
];

export class ReportAbuseForm extends React.Component {
  state = {
    reportType: REASONS[0],
  };

  render() {
    const { message, title, onSubmit, onCancel, contentFontClassName, t } = this.props;

    return (
      <div
        className={classNames(
          styles.container,
          'default-desktop-header-text-color',
          contentFontClassName,
          'report',
        )}
      >
        <ModalHeader className="confirmation__title" dataHook="confirmation__title">
          {title}
        </ModalHeader>
        <div className={styles.message}>{message}</div>
        <form
          className={classNames(styles.form, 'report__form')}
          onSubmit={ev => ev.preventDefault()}
        >
          <div className={styles.reasons} role="radiogroup" aria-labelledby={message} tabIndex="0">
            {map(REASONS, (reason, key) => (
              <div key={key} className={styles.reason}>
                <div className={styles.radioInput} data-hook="report-reason">
                  <InputRadio
                    name="reportType"
                    onChange={() => this.setState({ reportType: reason })}
                    checked={this.state.reportType.value === reason.value}
                    value={reason.value}
                    text={t(reason.text)}
                    tabIndex={key === 0 ? '0' : '-1'}
                  />
                </div>
                <HorizontalSeparator className={styles.separator} />
              </div>
            ))}
          </div>
          <ButtonGroup
            primaryButtonText={t('report-abuse-form.submit-button')}
            primaryButtonProps={{
              type: 'submit',
              onClick: ev => {
                ev.preventDefault();
                onSubmit(this.state.reportType);
              },
            }}
            secondaryButtonText={t('report-abuse-form.cancel-button')}
            secondaryButtonProps={{ onClick: () => onCancel() }}
          />
        </form>
      </div>
    );
  }
}

ReportAbuseForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  message: PropTypes.string,
  title: PropTypes.string,
};

export default withFontClassName(withTranslate(ReportAbuseForm));
