import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import CreatePostButton from '../create-post-button';
import EmptyStates from '../empty-states';
import withTranslate from '../../hoc/with-translate';
import styles from './no-posts.scss';

const NoPosts = ({ postTypes, showCreatePostAction, t, ...props }) => (
  <EmptyStates
    title={t('footer-posts.no-posts-yet')}
    content={t('footer-posts.create-new-post')}
    className={styles.container}
    {...props}
  >
    <CreatePostButton postTypes={postTypes} />
  </EmptyStates>
);

NoPosts.propTypes = {
  t: PropTypes.func,
};

export default flowRight(withTranslate)(NoPosts);
