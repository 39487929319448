import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { ModalCentered } from '../base';
import Confirmation from '../../confirmation';
import { DiscardIcon } from '../../icons/discard-icon';
import withTranslate from '../../../hoc/with-translate';

export const DiscardPostModal = (
  { closeModal, discardPost, t }, //TODO when discarding post should show different text
) => (
  <ModalCentered onClose={closeModal}>
    <Confirmation
      icon={DiscardIcon}
      title={t('discard-post-modal.discard-post')}
      confirm={t('discard-post-modal.confirm')}
      cancel={t('discard-post-modal.cancel')}
      onConfirm={discardPost}
      onCancel={closeModal}
    >
      <p>{t('discard-post-modal.are-you-sure')}</p>
    </Confirmation>
  </ModalCentered>
);

DiscardPostModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  discardPost: PropTypes.func.isRequired,
  categorySlug: PropTypes.string,
  postSlug: PropTypes.string,
  t: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps) => ({
  discardPost: () =>
    ownProps.closeModal({ categorySlug: ownProps.categorySlug, postSlug: ownProps.postSlug }),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(DiscardPostModal);
