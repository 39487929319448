import React from 'react';
import PropTypes from 'prop-types';
import styles from './container.scss';

const Container = ({ children }) => (
  <div className={styles.container} data-hook="post-list">
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.any,
};

export default Container;
