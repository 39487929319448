import React from 'react';

const TwitterIcon = props => (
  <svg width="27px" height="23px" viewBox="0 0 27 23" version="1.1" {...props}>
    <g id="Popups-.-desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Notifications-Settings-Copy-2"
        transform="translate(-642.000000, -490.000000)"
        fill="#333333"
        fillRule="nonzero"
      >
        <g id="Group-10" transform="translate(430.000000, 255.000000)">
          <g id="Group-3" transform="translate(80.000000, 132.000000)">
            <g id="Icons" transform="translate(18.000000, 102.000000)">
              <path
                d="M141,3.62929461 C139.993783,4.08331169 138.925781,4.38098519 137.832,4.51228 C138.984335,3.80911095 139.847284,2.70773401 140.262,1.41088503 C139.176603,2.06336373 137.989712,2.52418472 136.752,2.77367447 C135.19491,1.09375717 132.784136,0.546739109 130.668954,1.39339764 C128.553772,2.24005617 127.162344,4.30900791 127.158,6.61393119 C127.16017,7.04364567 127.208454,7.47184594 127.302,7.89097618 C122.849143,7.66425563 118.700799,5.5315191 115.89,2.02386662 C115.391429,2.87828223 115.130202,3.85348559 115.134,4.84613605 C114.975791,6.654652 115.74362,8.41906683 117.168,9.52012072 C116.171436,9.37436586 115.18959,9.1393202 114.234,8.81774597 L114.234,8.88889562 C114.424664,11.6392237 116.434315,13.9068024 119.112,14.3929595 C118.710752,14.5242241 118.291631,14.590713 117.87,14.5899893 C117.555333,14.5910488 117.241486,14.5574044 116.934,14.4896501 C117.677305,16.7832544 119.772393,18.3480022 122.154,18.388286 C120.20187,19.952186 117.784308,20.7991678 115.296,20.7909549 C114.862822,20.7917334 114.430007,20.765535 114,20.7125079 C118.851216,23.8616433 125.011077,24.0775815 130.065379,21.2756911 C135.11968,18.4738006 138.263233,13.1004444 138.264,7.26157544 C138.264,7.01893689 138.246,6.77629834 138.246,6.53183544 C139.327546,5.7386476 140.260258,4.75562869 141,3.62929461 L141,3.62929461 Z"
                id="Twitter"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default TwitterIcon;
