import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { isQACategoriesExperimentEnabled } from '../../selectors/experiments-selectors';
import { isMultiplePostTypeCategory } from '../../services/categories-service';
import PostListItem from '../post-list-item/index';
import CreatePostCta from '../create-post-cta';
import CreatePostCtaYak from '../create-post-cta-yak';
import Container from '../post-list-mobile-common/container';
import ItemContainer from '../post-list-mobile-common/item-container';

const PostListMobile = ({
  category,
  posts,
  query,
  onLikeClick,
  showCreatePostAction,
  showCategoryLink,
  isQACategoriesEnabled,
}) => {
  console.log(category);

  return (
    <Container>
      {showCreatePostAction && (
        <ItemContainer>
          {isQACategoriesEnabled ? (
            <CreatePostCtaYak
              categorySlug={category.slug}
              isMultiplePostTypeCategory={isMultiplePostTypeCategory(category)}
              postTypes={category.postTypes}
            />
          ) : (
            <CreatePostCta categorySlug={category.slug} />
          )}
        </ItemContainer>
      )}

      {posts.map(post => (
        <ItemContainer key={post._id}>
          <PostListItem
            post={post}
            query={query}
            type={PostListItem.MOBILE}
            onLikeClick={onLikeClick}
            showCategoryLink={showCategoryLink}
            isMultiplePostTypeCategory={isMultiplePostTypeCategory(category)}
          />
        </ItemContainer>
      ))}
    </Container>
  );
};

PostListMobile.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  showCreatePostAction: PropTypes.bool,
  showCategoryLink: PropTypes.bool,
};

const mapRuntimeToProps = state => ({
  isQACategoriesEnabled: isQACategoriesExperimentEnabled(state),
});

export default connect(mapRuntimeToProps)(PostListMobile);
