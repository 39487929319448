import { flowRight, omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withTranslate from '../../hoc/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './header-button.scss';

const HeaderButton = ({
  t,
  text,
  onClick,
  isMuted,
  contentFontClassName,
  dataHook,
  ...otherProps
}) => (
  <button
    className={classNames(
      styles.button,
      'forum-text-color',
      'header-button',
      contentFontClassName,
      {
        [styles.isMuted]: isMuted,
      },
    )}
    disabled={isMuted}
    onClick={onClick}
    {...omit(otherProps, ['tReady', 'i18n', 'titleFontClassName', 'dispatch'])}
    data-hook={dataHook}
  >
    {t && t(text)}
  </button>
);

HeaderButton.propTypes = {
  t: PropTypes.func.isRequired,
  text: PropTypes.string,
  contentFontClassName: PropTypes.string,
  onClick: PropTypes.func,
  isMuted: PropTypes.bool,
  dataHook: PropTypes.string,
};

export default flowRight(
  withTranslate,
  withFontClassName,
)(HeaderButton);
