import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import UserAvatarImage from '../user-avatar-image';
import QuickUserInfo from '../quick-user-info';
import styles from './comment-avatar.scss';
import MemberCardAsync from '../../containers/member-card-async';
import ProfileLink from '../link/profile-link';
import RoleBadge from '../role-badge';

function CommentAvatar({ type, user = {}, date, showDate }) {
  const wrapWithProfileLink = component => <ProfileLink user={user}>{component}</ProfileLink>;

  const wrapWithMemberCard = component => (
    <MemberCardAsync viewedMemberId={user.siteMemberId} className={styles.avatarImage}>
      {component}
    </MemberCardAsync>
  );

  const avatarImage = <UserAvatarImage type={type} user={user} />;
  const userName = (
    <span
      className={classNames(
        styles.profileName,
        'forum-text-color',
        'forum-link-hover-color',
        'comment-avatar__profile-name',
      )}
    >
      {user.name}
    </span>
  );

  const content = (
    <div className={classNames(styles.avatar, styles[type])}>
      {avatarImage}
      <div className={classNames(styles.profileInfo, 'forum-text-color')}>
        <div className={styles.user}>
          {userName}
          <RoleBadge user={user} className={classNames('forum-icon-fill')} />
        </div>
        <QuickUserInfo
          user={user}
          date={date}
          showDate={showDate}
          showBadges
          isTinyBadge
          hideBadgeBackground
        />
      </div>
    </div>
  );

  return wrapWithMemberCard(wrapWithProfileLink(content));
}

CommentAvatar.SEARCH = 'search';
CommentAvatar.REPLY = 'reply';

CommentAvatar.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
  date: PropTypes.string,
  showDate: PropTypes.bool,
};

export default CommentAvatar;
