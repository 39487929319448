const getIsLoaded = state => state.isLoaded;

export const getIsEntityLoaded = (state, entity, id = '') =>
  Boolean((getIsLoaded(state)[entity] || {})[id]);

export const getIsCategoryLoaded = createIsLoadedSelector('category');
export const getIsPostLoaded = createIsLoadedSelector('post');
export const getIsPostsLoaded = createIsLoadedSelector('posts');
export const getIsProfilePostsLoaded = createIsLoadedSelector('profile-posts');
export const getIsProfileCommentsLoaded = createIsLoadedSelector('profile-comments');
export const getIsProfileLikesLoaded = createIsLoadedSelector('profile-likes');
export const getIsCommentsLoaded = createIsLoadedSelector('comments');
export const getIsNotificationsLoaded = createIsLoadedSelector('notifications');
export const getIsSearchLoaded = createIsLoadedSelector('search');
export const getIsModalLoaded = createIsLoadedSelector('modal');

function createIsLoadedSelector(entity) {
  return (state, id) => getIsEntityLoaded(state, entity, id);
}
