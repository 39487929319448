import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Pagination from '../pagination';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withDividerColor from '../../hoc/with-divider-color';
import styles from './pagination-comments.scss';

const PaginationComments = props => (
  <div
    className={classNames(styles.container, props.className, 'forum-card-border-color', {
      [styles.isTop]: props.isTop,
    })}
    style={{
      borderWidth: props.borderWidth,
      [props.isTop ? 'borderBottomColor' : 'borderTopColor']: props.dividerColor,
      [props.isTop ? 'borderBottomWidth' : 'borderTopWidth']: 1,
    }}
    data-hook={props.isTop ? 'pagination-top' : 'pagination-bottom'}
  >
    <div className={styles.innerContainer}>
      {props.entityCount > props.showPerPage && (
        <Pagination
          className={styles.pagination}
          arrowClass={styles.arrow}
          innerClass={styles.page}
          activeClass={styles.active}
          changePageOnClick={false}
          {...props}
        />
      )}
    </div>
    {props.rightSideRender && props.rightSideRender()}
  </div>
);

PaginationComments.propTypes = {
  entityCount: PropTypes.number,
  showPerPage: PropTypes.number,
  rightSideRender: PropTypes.func,
  isTop: PropTypes.bool,
  borderWidth: PropTypes.number,
  dividerColor: PropTypes.string,
  className: PropTypes.string,
};

export default flowRight(withCardBorderWidth, withDividerColor)(PaginationComments);
