import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { QUESTION } from '../../constants/post-types';
import withTranslate from '../../hoc/with-translate';
import { ViewCountIcon } from '../icons/view-count-icon';
import { CommentIcon } from '../icons/comment-icon';
import { RepliesIcon } from '../icons/replies-icon';
import styles from './post-page-sidebar-stats.scss';

const PostPageSidebarStats = ({ viewCount, totalComments, totalReplies, postType, t }) => {
  const isQuestionType = postType === QUESTION;
  const commentCount = totalComments && totalReplies && totalComments - totalReplies;

  return (
    <section className={classNames(styles.container, 'forum-card-border-color')}>
      <div className={styles.iconColumn}>
        <div className={styles.row}>
          <ViewCountIcon className="forum-icon-fill" />
        </div>
        <div className={styles.row}>
          <CommentIcon className="forum-icon-stroke" />
        </div>
        <div className={styles.row}>
          <RepliesIcon className="forum-icon-fill" />
        </div>
      </div>
      <div className={classNames(styles.column, 'forum-text-color')}>
        <div className={styles.row}>
          {t('post-page-sidebar-stats.view-count', { count: viewCount })}
        </div>
        <div className={styles.row}>
          {t(
            isQuestionType
              ? 'post-page-sidebar-stats.answer-count'
              : 'post-page-sidebar-stats.comment-count',
            { count: commentCount },
          )}
        </div>
        <div className={styles.row}>
          {t('post-page-sidebar-stats.reply-count', { count: totalReplies })}
        </div>
      </div>
    </section>
  );
};

PostPageSidebarStats.propTypes = {
  viewCount: PropTypes.number.isRequired,
  totalComments: PropTypes.number.isRequired,
  totalReplies: PropTypes.number.isRequired,
  postType: PropTypes.string.isRequired,
  t: PropTypes.string.function,
};

export default flowRight(withTranslate)(PostPageSidebarStats);
