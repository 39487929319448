import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import ShareButton from '../share-button';
import ShareLink from '../share-link';
import share from '../../services/share';
import getOuterUrl from '../../services/get-outer-url';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import styles from './share-form.scss';
import ModalHeader from '../modals/base/modal-header';
import { connect } from '../../../common/components/runtime-context';
import { getSectionUrl } from '../../../common/store/location/location-selectors';

export class ShareForm extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    sectionUrl: PropTypes.string.isRequired,
    titleFontClassName: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    modalType: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLinkShareFormVisible: props.isLinkShareFormVisible,
    };
  }
  handleLinkButtonClick = () => {
    this.setState({
      isLinkShareFormVisible: true,
    });
  };

  handleShare = socialNetwork => {
    share(socialNetwork, getOuterUrl(this.props.path, this.props.sectionUrl));
  };

  handleCancel = () => {
    this.setState({
      isLinkShareFormVisible: false,
    });
  };

  renderShareButtons() {
    const { t } = this.props;

    return (
      <div className={classNames(styles.content, styles.links)}>
        <span className={styles.button}>
          <ShareButton
            type="facebook"
            title={t('share-button.via-facebook')}
            onClick={this.handleShare}
          />
        </span>
        <span className={styles.button}>
          <ShareButton
            type="twitter"
            title={t('share-button.via-twitter')}
            onClick={this.handleShare}
          />
        </span>
        <span className={styles.button}>
          <ShareButton
            type="link"
            title={t('share-button.via-link')}
            onClick={this.handleLinkButtonClick}
          />
        </span>
      </div>
    );
  }

  renderLinkShareForm() {
    const { isLinkShareFormVisible } = this.state;
    if (!isLinkShareFormVisible) {
      return null;
    }

    const { path, sectionUrl, closeModal } = this.props;
    return (
      <div className={styles.content}>
        <ShareLink
          url={getOuterUrl(path, sectionUrl)}
          onCancel={this.handleCancel}
          closeModal={closeModal}
        />
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { isLinkShareFormVisible } = this.state;
    const title = isLinkShareFormVisible ? t('share-form.share-link') : this.props.title;

    return (
      <div
        className={classNames(styles.container, 'forum-text-color', this.props.titleFontClassName)}
      >
        <ModalHeader>{title}</ModalHeader>
        {isLinkShareFormVisible ? this.renderLinkShareForm() : this.renderShareButtons()}
      </div>
    );
  }
}

const mapRuntimeToProps = state => ({
  sectionUrl: getSectionUrl(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withFontClassName,
  withTranslate,
)(ShareForm);
