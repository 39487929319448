import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import CreatePostButton from '../create-post-button';
import EmptyStates from '../empty-states';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../hoc/with-translate';
import { isPrivate } from '../../../common/services/category-privacy-utils';
import styles from './no-posts.scss';
import ensureAuth from '../../hoc/ensure-auth';
import Button from '../button/button';
import { MODAL_TYPE_CATEGORIES_MANAGER } from '../modals/categories-manager/categories-manager-modal-type';
import { MANAGE_MEMBERS } from '../../actions/categories-manager-actions';

const ButtonSecure = ensureAuth(Button);

class NoPosts extends React.Component {
  componentDidMount() {
    const { category, fetchMembersGroupsList } = this.props;
    if (isPrivate(category)) {
      fetchMembersGroupsList();
    }
  }

  openMembersManager = () => {
    const { openModal, category } = this.props;
    openModal(MODAL_TYPE_CATEGORIES_MANAGER, {
      categoryId: category._id,
      openPanels: [MANAGE_MEMBERS],
    });
  };

  render() {
    const { t, showAddMembersAction, showCreatePostAction, category, type, ...rest } = this.props;
    return (
      <EmptyStates
        title={t(
          showAddMembersAction
            ? 'category-page.private-empty-state.title'
            : 'category-page.no-posts-yet',
        )}
        content={t(
          showAddMembersAction
            ? 'category-page.private-empty-state.content'
            : 'category-page.create-new-post',
        )}
        type={type}
        {...rest}
      >
        <div className={styles.wrapper}>
          {showAddMembersAction && (
            <ButtonSecure
              isSecondary
              onClick={this.openMembersManager}
              className={styles.secondaryButton}
            >
              {t('category-page.private-empty-state.add-members')}
            </ButtonSecure>
          )}
          {showCreatePostAction && (
            <CreatePostButton
              className={styles.primaryButton}
              categorySlug={category.slug}
              postTypes={category.postTypes}
              isSmall={false}
              isDropdown={false}
            />
          )}
        </div>
      </EmptyStates>
    );
  }
}

NoPosts.propTypes = {
  category: PropTypes.object,
  showCreatePostAction: PropTypes.bool,
  showAddMembersAction: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  t: PropTypes.func,
  type: PropTypes.string,
};

const mapRuntimeToProps = (state, { category, canSee, can }, actions) => {
  const showCreatePostAction =
    category && (!category.isWriteProtected || canSee('create-post', 'category', category));

  return {
    fetchMembersGroupsList: actions.fetchMembersGroupsList,
    showCreatePostAction,
    openModal: actions.openModal,
    showAddMembersAction:
      showCreatePostAction && category && isPrivate(category) && can('edit', 'category', category),
  };
};

export default flowRight(withPermissions, connect(mapRuntimeToProps), withTranslate)(NoPosts);
