import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import CategoryListItem from '../category-list-item';
import styles from './category-list.scss';

const CategoryList = ({ categories = [], isViewsCountEnabled }) => {
  return (
    <div data-hook="category-list" className={styles.container}>
      <ul className={classNames(styles.list, 'category-list__list')}>
        {categories.map((category, index) => (
          <li className={classNames(styles.listItem, 'category-list__list-item')} key={index}>
            <CategoryListItem category={category} isViewsCountEnabled={isViewsCountEnabled} />
          </li>
        ))}
      </ul>
    </div>
  );
};

CategoryList.propTypes = {
  categories: PropTypes.array,
  isViewsCountEnabled: PropTypes.bool.isRequired,
};

export default CategoryList;
