import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { ModalCentered } from '../base';
import ReportAbuseForm from '../../report-abuse-form';
import withTranslate from '../../../hoc/with-translate';

export const ReportCommentModal = ({ closeModal, reportComment, t }) => (
  <ModalCentered onClose={closeModal}>
    <ReportAbuseForm
      onSubmit={reportComment}
      onCancel={closeModal}
      title={t('report-comment-modal.report-comment')}
      message={t('report-comment-modal.help-us-understand')}
    />
  </ModalCentered>
);

ReportCommentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  reportComment: PropTypes.func.isRequired,
  postId: PropTypes.string.isRequired,
  commentId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (dispatch, ownProps) => ({
  reportComment: report =>
    ownProps.closeModal({
      ...report,
      postId: ownProps.postId,
      commentId: ownProps.commentId,
    }),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(ReportCommentModal);
