import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from '../../../../common/components/runtime-context';
import withTranslate from '../../../hoc/with-translate';

import { HorizontalSeparator } from '../../separator';

import {
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_SM,
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_ROLES,
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_BADGES,
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_PLANS,
} from '../manage-category-members-modal/manage-category-members-modal-type';
import {
  getAllGroupsMembersCount,
  getEntitiesCountsWithAccess,
} from '../../../selectors/manage-category-members-selectors';
import withExperiment from '../../../hoc/with-experiment';
import { EXPERIMENT_PAID_PLANS } from '../../../constants/experiments';
import styles from './category-settings-form-manage-members-section.scss';

const ManageMembersButton = ({ translationKey, assigned, t, onClick, totalMembers }) => (
  <React.Fragment>
    <button
      className={classNames(styles.manageCategoryMembersButton, 'color-5-text')}
      onClick={onClick}
    >
      <span>
        {assigned > 0 ? (
          <span>{t(translationKey, { count: assigned })}</span>
        ) : (
          <span>{t(translationKey + '-empty')}</span>
        )}
        {totalMembers ? (
          <span>
            &nbsp;
            {t('category-actions.manage-category-members-total-members', { count: totalMembers })}
          </span>
        ) : null}
      </span>
      <span className={classNames('button-color', styles.manageCategoryMembersButtonAction)}>
        {t(`category-actions.manage-category-members-${assigned ? 'edit' : 'add'}-btn`)}
      </span>
    </button>
    <HorizontalSeparator />
  </React.Fragment>
);
const CategorySettingsFormManageMembersSection = ({
  t,
  openModal,
  entitiesCounts,
  categoryId,
  isPaidPlansEnabled,
  groupsMembersCount,
}) => {
  return (
    <React.Fragment>
      <ManageMembersButton
        translationKey="category-actions.manage-category-members-sm"
        assigned={entitiesCounts.assigned.siteMembers}
        t={t}
        onClick={() =>
          openModal(MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_SM, {
            categoryId: categoryId,
          })
        }
      />
      <ManageMembersButton
        translationKey="category-actions.manage-category-members-roles"
        assigned={entitiesCounts.assigned.roles}
        t={t}
        onClick={() =>
          openModal(MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_ROLES, {
            categoryId: categoryId,
          })
        }
        totalMembers={groupsMembersCount.roleMemberCount}
      />
      <ManageMembersButton
        translationKey="category-actions.manage-category-members-badges"
        assigned={entitiesCounts.assigned.badges}
        t={t}
        onClick={() =>
          openModal(MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_BADGES, {
            categoryId: categoryId,
          })
        }
        totalMembers={groupsMembersCount.badgeMemberCount}
      />
      {isPaidPlansEnabled && (
        <ManageMembersButton
          translationKey="category-actions.manage-category-members-plans"
          assigned={entitiesCounts.assigned.plans}
          t={t}
          onClick={() =>
            openModal(MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_PLANS, {
              categoryId: categoryId,
            })
          }
          totalMembers={groupsMembersCount.paidPlanMemberCount}
        />
      )}
      <div style={{ marginBottom: '18px' }} />
    </React.Fragment>
  );
};

CategorySettingsFormManageMembersSection.propTypes = {
  entitiesCounts: PropTypes.object,
  categoryId: PropTypes.string,
  isPaidPlansEnabled: PropTypes.bool,
  groupsMembersCount: PropTypes.object.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  entitiesCounts: getEntitiesCountsWithAccess(state, ownProps.categoryId),
  openModal: actions.openModal,
  groupsMembersCount: getAllGroupsMembersCount(state, ownProps.categoryId),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withExperiment({
    isPaidPlansEnabled: EXPERIMENT_PAID_PLANS,
  }),
  withTranslate,
)(CategorySettingsFormManageMembersSection);
