import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import MoreButton from '../more-button';
import CategoryActions from '../../containers/category-actions';
import Link from '../link/internal-link';
import FollowButton from '../follow-button/follow-button';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import withPermissions from '../../hoc/with-permissions';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import styles from './subcategory-list-item-desktop.scss';
import { isPrivate } from '../../../common/services/category-privacy-utils';
import { LockEmptyIcon } from '../icons/lock-empty-icon';

export class SubcategoryListItemDesktop extends Component {
  renderContentBlock = () => {
    const { titleFontClassName, category, t } = this.props;
    let categoryBadge = null;

    if (isPrivate(category)) {
      categoryBadge = (
        <span className={styles.categoryBadgeWrapper}>
          <LockEmptyIcon className="forum-icon-fill" />
        </span>
      );
    }
    const titleText = (
      <h3
        className={classNames(styles.title, titleFontClassName)}
        data-hook="subcategory-list-item__title"
      >
        {category.label} {categoryBadge}
      </h3>
    );
    return (
      <div className={styles.contentBlock}>
        {this.linkToPosts(titleText, 'subcategory-list-item__link')}
        <span className={styles.description} data-hook="subcategory-list-item__description">
          {category.description}
        </span>
      </div>
    );
  };

  renderNumbersElement = (number, text, dataHook) => (
    <div className={styles.numberElement} data-hook={dataHook}>
      <span className={classNames(styles.numberElementHeader, this.props.titleFontClassName)}>
        {number}
      </span>
      <span className={styles.numberElementText}>{text}</span>
    </div>
  );

  fetchCategoryPosts = () => {
    const { category, fetchCategoryPosts } = this.props;
    fetchCategoryPosts({ categoryId: category._id, page: 1 });
  };

  linkToPosts = (children, dataHook) => {
    const { category, fetchCategoryPosts } = this.props;
    return (
      <Link
        className={classNames('forum-text-color', 'forum-link-hover-color')}
        to={`/${category.slug}`}
        onMouseDown={() => fetchCategoryPosts({ categoryId: category._id, page: 1 })}
        data-hook={dataHook}
      >
        {children}
      </Link>
    );
  };

  render() {
    const {
      category,
      contentFontClassName,
      isViewsCountEnabled,
      showViewCount,
      t,
      can,
    } = this.props;
    const shouldShowMoreButton = can('edit', 'category', category);
    const moreButton = (
      <MoreButton size={MoreButton.SIZE_SMALL} data-hook="subcategory-list-item__category-actions">
        <CategoryActions hideFollowButton category={category} />
      </MoreButton>
    );
    const followButton = (
      <FollowButton
        category={category}
        className={shouldShowMoreButton ? styles.followButton : ''}
        data-hook="subcategory-list-item__follow-button"
        size={FollowButton.SIZE_SMALL}
      >
        {t(category.isSubscribed ? 'category-actions.unfollow' : 'category-actions.follow')}
      </FollowButton>
    );
    const categoryActions = (
      <div className={styles.actionsContainer}>
        {followButton}
        {shouldShowMoreButton && moreButton}
      </div>
    );
    const categoryContent = (
      <div className={styles.main}>
        <div className={classNames(styles.contentPreview, 'forum-text-color')}>
          {this.renderContentBlock()}
        </div>
        <div className={classNames(styles.numbers, { [styles.viewsHidden]: !isViewsCountEnabled })}>
          {isViewsCountEnabled &&
            this.renderNumbersElement(
              showViewCount ? category.totalViews : <span>&nbsp;</span>,
              t('subcategory-list-item.views'),
              'subcategory-list-item__total-views',
            )}
          {this.renderNumbersElement(
            category.totalPosts,
            t('subcategory-list-item.posts', 'subcategories-list-item__total-posts'),
            'subcategory-list-item__total-posts',
          )}
        </div>
      </div>
    );

    return (
      <div
        className={classNames(styles.container, contentFontClassName)}
        data-hook="subcategory-list-item"
      >
        {categoryContent}
        {categoryActions}
      </div>
    );
  }
}

SubcategoryListItemDesktop.propTypes = {
  category: PropTypes.object.isRequired,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  isViewsCountEnabled: PropTypes.bool,
  showViewCount: PropTypes.bool,
  fetchCategoryPosts: PropTypes.func,
  can: PropTypes.func,
  t: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  fetchCategoryPosts: actions.fetchCategoryPosts,
  showViewCount: !isCacheableRendering(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withFontClassName,
  withPermissions,
)(SubcategoryListItemDesktop);
