import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { GatewayProvider, GatewayDest } from 'react-gateway';
import AccessibilityListener from '../accessibility-listener';
// import WindowResizeListener from './ReactWindowResizeListener';
import MessageRoot from '../messages/message-root';
import ModalRoot from '../modals/modal-root';
import DemoMessageRoot from '../../containers/demo-message-root';
import ErrorState from '../error-state';
import { getLocation } from '../../../common/store/location/location-selectors';
import styles from './app.scss';

export class AppDesktop extends Component {
  static propTypes = {
    desktopHeader: PropTypes.node,
    main: PropTypes.node.isRequired,
    children: PropTypes.node,
    menu: PropTypes.node,
    isDebug: PropTypes.bool,
    isFullWidth: PropTypes.bool,
  };

  render() {
    const { desktopHeader, menu, main, children, isFullWidth, isDebug } = this.props;
    return (
      <GatewayProvider>
        <div
          className={classNames(styles.app, 'app-desktop', 'forum-background-color', {
            'app-full-width': isFullWidth,
          })}
        >
          <AccessibilityListener />
          <DemoMessageRoot />
          <ModalRoot />
          <MessageRoot />
          <div data-hook="forum-content-wrapper" id="content-wrapper">
            {desktopHeader && React.cloneElement(desktopHeader, { menu })}
            {React.cloneElement(main, { children })}
          </div>
          <GatewayDest name="floating-action-button" />
          <GatewayDest name="tooltip" />
          <GatewayDest name="rce-modal" />
          {isDebug && <ErrorState />}
        </div>
      </GatewayProvider>
    );
  }
}

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isFullWidth: !host.dimensions.width,
  isDebug: get(getLocation(state), 'query.debug'),
});

export default flowRight(connect(mapRuntimeToProps))(AppDesktop);
