import { get } from 'lodash';

export const getValidityUnit = unit => {
  switch (unit) {
    case 'DAY':
      return 'days';
    case 'WEEK':
      return 'weeks';
    case 'YEAR':
      return 'years';
    default:
      return 'months';
  }
};

export const getValidityLabel = paymentOptions => {
  const unit = getValidityUnit(get(paymentOptions, 'validFor.period.unit'));
  const amount = get(paymentOptions, 'validFor.period.amount');
  const size = amount === 1 ? 'one' : 'some';

  if (paymentOptions.recurring) {
    if (paymentOptions.validFor.forever) {
      return `paid-plan.validity.recurring-until-canceled-${unit}`;
    } else {
      return `paid-plan.validity.recurring-${size}-${unit}`;
    }
  }

  if (paymentOptions.validFor.forever) {
    return 'paid-plan.validity.valid-until-canceled';
  }

  return `paid-plan.validity.valid-${size}-${unit}`;
};
