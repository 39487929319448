import { flowRight, debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from '../../../../common/components/runtime-context';
import withTranslate from '../../../hoc/with-translate';
import { getCategoryById } from '../../../../recent-posts-widget/selectors/categories-selectors';
import { getMetaSiteId } from '../../../../common/store/instance-values/instance-values-selectors';
import EmptyState from './manage-category-members-empty-state';
import { fetchMembersBucketForIndex } from '../../../../common/store/site-members/site-members-service';
import {
  getSiteMemberAtIndex,
  getTotalSiteMembersCountForQuery,
  isFetchingForQuery,
} from '../../../../common/store/site-members/site-members-selectors';
import Loader from '../../loader';
import { TEMPLATE_CATEGORY_GROUP_ID } from '../../../constants/categories-manager';
import SiteMembersList from './manage-category-members-sm-list';
import ManageCategoryMembersModal from './manage-category-members-modal';

class ManageCategoryMembersSMModal extends Component {
  state = {
    searchQuery: '',
    addedMembers: [],
    removedMembers: [],
  };
  componentDidMount() {
    this.props.fetchMembersBucketForIndex(this.state.searchQuery, 0); // this probably need to be in dat fetcher
  }

  loadData = debounce(({ visibleStartIndex, visibleStopIndex }) => {
    this.lastIndexes = [visibleStartIndex, visibleStopIndex];
    this.props.fetchMembersBucketForIndex(this.state.searchQuery, visibleStartIndex);
    this.props.fetchMembersBucketForIndex(this.state.searchQuery, visibleStopIndex);
  }, 200);

  handleSearchQueryChange = debounce(searchQuery => {
    this.setState({ searchQuery });
    this.props.fetchMembersBucketForIndex(searchQuery, 0);
  }, 200);

  formToggleAddMember = smId =>
    this.setState(state => ({
      addedMembers: state.addedMembers.includes(smId)
        ? state.addedMembers.filter(id => id !== smId)
        : state.addedMembers.concat(smId),
    }));
  formToggleRemoveGroup = smId =>
    this.setState(state => ({
      removedMembers: state.removedMembers.includes(smId)
        ? state.removedMembers.filter(id => id !== smId)
        : state.removedMembers.concat(smId),
    }));
  formToggleCheckbox = (isMemberAssignedToGroup, smId) =>
    isMemberAssignedToGroup ? this.formToggleRemoveGroup(smId) : this.formToggleAddMember(smId);
  formGetCheckboxValue = (isMemberAssignedToGroup, smId) => {
    if (isMemberAssignedToGroup) {
      return !this.state.removedMembers.includes(smId);
    } else {
      return this.state.addedMembers.includes(smId);
    }
  };
  formSubmit = () => {
    const { addedMembers, removedMembers } = this.state;
    const { category, addSiteMembers, removeSiteMembers } = this.props;

    if (addedMembers.length > 0) {
      addSiteMembers(category.categoryGroupId, addedMembers);
    }
    if (removedMembers.length > 0) {
      removeSiteMembers(category.categoryGroupId, removedMembers);
    }
  };

  render() {
    const { closeModal, isFetchingForQuery, category, t } = this.props;
    const { searchQuery } = this.state;
    const totalSiteMembersCount = this.props.getTotalSiteMembersCount(searchQuery);

    let content;
    if (totalSiteMembersCount === null && isFetchingForQuery(searchQuery)) {
      content = <Loader />;
    } else if (totalSiteMembersCount === 0) {
      content = (
        <EmptyState
          title={t('manage-category-members-search-empty-state.no-results-found')}
          description={t('manage-category-members-search-empty-state.try-another-search')}
        />
      );
    } else {
      content = (
        <SiteMembersList
          loadData={this.loadData}
          getSiteMemberAtIndex={i => this.props.getSiteMemberAtIndex(searchQuery, i)}
          totalSiteMembersCount={totalSiteMembersCount}
          category={category}
          formToggleCheckbox={this.formToggleCheckbox}
          getCheckboxValue={this.formGetCheckboxValue}
        />
      );
    }
    return (
      <ManageCategoryMembersModal
        closeModal={closeModal}
        titleTextKey={'manage-category-members-sm-modal.title'}
        formSubmit={() => {
          this.formSubmit();
          closeModal();
        }}
        searchQueryValue={searchQuery}
        searchQueryOnChange={this.handleSearchQueryChange}
        searchQueryCount={totalSiteMembersCount}
      >
        {content}
      </ManageCategoryMembersModal>
    );
  }
}

ManageCategoryMembersSMModal.propTypes = {
  closeModal: PropTypes.func,
  category: PropTypes.object,
  showChange: PropTypes.func,
  metaSiteId: PropTypes.string,
  t: PropTypes.func,
};

function decorateCategoryWithTemplateGroupId(category) {
  // category can have following group ids
  // 1. Normal UID
  // 2. Template placeholder, if it's creation of new category
  // 3. Undefined if it's old category in edit, in that case we assign the template id

  return { ...category, categoryGroupId: category.categoryGroupId || TEMPLATE_CATEGORY_GROUP_ID };
}

const mapRuntimeToProps = (state, ownProps, actions) => {
  return {
    category: decorateCategoryWithTemplateGroupId(getCategoryById(state, ownProps.categoryId)),
    metaSiteId: getMetaSiteId(state),
    addSiteMembers: actions.manageCategoryMembersAddSiteMembers,
    removeSiteMembers: actions.manageCategoryMembersRemoveSiteMembers,
    fetchMembersBucketForIndex: fetchMembersBucketForIndex(actions.fetchSiteMembersList, state),
    getSiteMemberAtIndex: getSiteMemberAtIndex(state),
    getTotalSiteMembersCount: getTotalSiteMembersCountForQuery(state),
    isFetchingForQuery: isFetchingForQuery(state),
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(ManageCategoryMembersSMModal);
