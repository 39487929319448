import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProtectedButton from '../../containers/protected-button';
import { connect } from '../../../common/components/runtime-context';
import { CommentIcon } from '../icons/comment-icon';
import { RepliesIcon } from '../icons/replies-icon';
import withTranslate from '../../hoc/with-translate';
import withAuth from '../../hoc/with-auth';
import styles from './reply-button.scss';
import withFontClassName from '../../hoc/with-font-class-name';
import { isExperimentEnabled } from '../../selectors/experiments-selectors';
import { EXPERIMENT_WRITE_COMMENT_BEFORE_LOGIN } from '../../constants/experiments';

class ReplyButton extends Component {
  render() {
    const {
      t,
      onClick,
      forPublicUser,
      dataHook,
      type,
      contentFontClassName,
      userName,
      isWriteCommentBeforeLoginEnabled,
    } = this.props;

    const renderIcon = () =>
      type === 'reply' ? (
        <RepliesIcon className={styles.replyIcon} />
      ) : (
        <CommentIcon className={classNames(styles.replyIcon, 'forum-icon-stroke')} />
      );
    return (
      <>
        {isWriteCommentBeforeLoginEnabled ? (
          <button
            className={classNames(styles.replyButton, contentFontClassName, 'button-hover-color')}
            aria-label={`${t('reply-button.reply-to', { userName })}`}
            onClick={forPublicUser(onClick)}
          >
            {renderIcon()}
            <span data-hook={dataHook} className={classNames(styles.reply, dataHook)}>
              {t(`comment.${type}`)}
            </span>
          </button>
        ) : (
          <ProtectedButton
            className={classNames(styles.replyButton, contentFontClassName, 'button-hover-color')}
            onClick={forPublicUser(onClick)}
            aria-label={`${t('reply-button.reply-to', { userName })}`}
          >
            {renderIcon()}
            <span data-hook={dataHook} className={classNames(styles.reply, dataHook)}>
              {t(`comment.${type}`)}
            </span>
          </ProtectedButton>
        )}
      </>
    );
  }
}

ReplyButton.propTypes = {
  dataHook: PropTypes.string,
  mainColor: PropTypes.string,
  t: PropTypes.func,
  forPublicUser: PropTypes.func,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['reply', 'comment', 'answer']).isRequired,
  contentFontClassName: PropTypes.string,
  userName: PropTypes.string,
};

const mapRuntimeToProps = state => {
  return {
    isWriteCommentBeforeLoginEnabled: isExperimentEnabled(
      state,
      EXPERIMENT_WRITE_COMMENT_BEFORE_LOGIN,
    ),
  };
};

export default flowRight(
  withFontClassName,
  withTranslate,
  withAuth,
  connect(mapRuntimeToProps),
)(ReplyButton);
