import React from 'react';

export const MoveUpIcon = props => (
  <svg
    width="19px"
    height="19px"
    viewBox="0 0 19 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.26590215,13.0820568 C3.26566865,13.0815778 3.26543526,13.0810978 3.26520198,13.0806169 L3.26520198,3.44978703 C3.26520198,3.34784352 3.35474503,3.26520198 3.46520198,3.26520198 L4.06520198,3.26520198 C4.17565893,3.26520198 4.26520198,3.34784352 4.26520198,3.44978703 L4.26520198,12.265202 L13.8243995,12.265202 L11.5580952,9.9808875 C11.4799903,9.90278264 11.4799903,9.77614964 11.5580952,9.69804479 L11.9823593,9.27378072 C12.0604641,9.19567586 12.1870971,9.19567586 12.265202,9.27378072 L15.609062,12.6397187 C15.6481144,12.6787711 15.6676406,12.7299556 15.6676406,12.78114 C15.6676406,12.8323245 15.6481144,12.883509 15.609062,12.9225614 L12.265202,16.2737807 C12.1870971,16.3518856 12.0604641,16.3518856 11.9823593,16.2737807 L11.5580952,15.8495166 C11.4799903,15.7714118 11.4799903,15.6447788 11.5580952,15.5666739 L13.8534867,13.265202 L3.46520198,13.265202 C3.36042164,13.265202 3.27446082,13.184626 3.26590215,13.0820568 Z"
      transform="translate(9.466421, 9.798781) scale(-1, 1) rotate(-90.000000) translate(-9.466421, -9.798781) "
    />
  </svg>
);
