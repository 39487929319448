import PropTypes from 'prop-types';
import React from 'react';

import IconTooltip from '../../icon-tooltip';
import { HelpIcon } from '../../icons/help-icon';
import styles from './category-settings-label-with-tooltip.scss';

const CategorySettingsTooltip = ({ labelText, tooltipText }) => (
  <div className={styles.container}>
    <label className={styles.label}>{labelText}</label>
    <span className={styles.spacing} />
    {tooltipText && (
      <IconTooltip text={tooltipText} tooltipClassName={styles.tooltip} aria-label={tooltipText}>
        <HelpIcon className="icon-fill" />
      </IconTooltip>
    )}
  </div>
);

CategorySettingsTooltip.propTypes = {
  labelText: PropTypes.string,
  tooltipText: PropTypes.string,
};

export default CategorySettingsTooltip;
