import classNames from 'classnames';
import focusTheme from './focus-theme.scss';
import textEditorTheme from './text-editor-theme.scss';
import textAlignmentTheme from './text-alignment-theme.scss';
import mediaBlockTheme from './media-block-theme.scss';

export default function getTheme({
  cardBackgroundColor,
  titleFontClassName,
  contentFontClassName,
}) {
  const button = {
    icon: {
      normal: classNames('forum-icon-fill', 'forum-icon-stroke'),
      highlighted: classNames('button-fill', 'button-stroke'),
      hover: classNames('button-fill', 'button-stroke'),
    },
    tooltip: {
      container: classNames(
        'forum-card-color',
        'forum-text-background-color',
        contentFontClassName,
      ),
      containerStyle: {
        color: cardBackgroundColor,
      },
      triangle: 'forum-text-background-color',
    },
    dropdown: {
      container: 'default-forum-card-background-color',
      containerStyle: {
        backgroundColor: cardBackgroundColor,
      },
    },
  };

  return {
    container: classNames(textEditorTheme.container),
    types: {
      comment: textEditorTheme.comment,
    },
    placeholder: {
      right: textEditorTheme.placeholderRight,
      center: textEditorTheme.placeholderCenter,
      hidden: textEditorTheme.placeholderHidden,
    },
    blockStyleFn: contentBlock => {
      const {
        type,
        data: { textAlignment },
      } = contentBlock.toJS();
      const classList = [];

      switch (contentBlock.getType()) {
        case 'blockquote':
          classList.push(classNames(textEditorTheme.quote, 'forum-quote-border-color'));
          break;
        case 'header-one':
          classList.push(textEditorTheme.headerOne);
          break;
        case 'header-two':
          classList.push(textEditorTheme.headerTwo);
          break;
        case 'atomic':
          classList.push(textEditorTheme.atomic);
          break;
        default:
          classList.push(textEditorTheme.text);
          break;
      }

      if (type !== 'atomic') {
        classList.push(textAlignmentTheme[textAlignment]);
      }

      return classNames(...classList);
    },
    title: {
      container: titleFontClassName,
    },
    focus: {
      focused: classNames(focusTheme.focused, 'button-after-border-color'),
      unfocused: classNames(focusTheme.unfocused, 'button-hover-after-border-color'),
    },
    toolbar: {
      container: classNames('default-forum-card-background-color', 'forum-card-border-color'),
      containerStyle: {
        backgroundColor: cardBackgroundColor,
      },
      button,
    },
    button,
    mediaBlock: mediaBlockTheme,
    link: 'button-hover-color',
    hashtag: 'button-color',
  };
}
