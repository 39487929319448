import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../button';
import EmptyStates from '../empty-states';
import withTranslate from '../../hoc/with-translate';
import styles from './private-category-restriction.scss';
import { connect } from '../../../common/components/runtime-context';

const PrivateCategoryRestriction = ({ t, navigateWithinForum }) => (
  <EmptyStates
    title={t('private-category-restriction.title')}
    content={t('private-category-restriction.content')}
    type="specific_members_other"
  >
    <Button className={styles.cta} onClick={() => navigateWithinForum('/')}>
      {t('private-category-restriction.cta')}
    </Button>
  </EmptyStates>
);

PrivateCategoryRestriction.propTypes = {
  t: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  navigateWithinForum: actions.navigateWithinForum,
});

export default flowRight(
  withTranslate,
  connect(mapRuntimeToProps),
)(PrivateCategoryRestriction);
