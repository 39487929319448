import React from 'react';
import { MobileSelect } from '../dropdown-select';
import FilterSelectHeadless from './filter-select-headless';

export const FilterSelectPostTypeMobile = () => (
  <FilterSelectHeadless>
    {({ value, options, onChange }) => (
      <MobileSelect
        options={options}
        value={value}
        onChange={onChange}
        label="post-filter.label-mobile"
      ></MobileSelect>
    )}
  </FilterSelectHeadless>
);
