import { MODAL_TYPE_MOVE_POST } from './move-post-modal/move-post-modal-type';
import { MODAL_TYPE_DELETE_POST } from './delete-post-modal/delete-post-modal-type';
import { MODAL_TYPE_DELETE_COMMENT } from './delete-comment-modal/delete-comment-modal-type';
import { MODAL_TYPE_DISCARD_POST } from './discard-post-modal/discard-post-modal-type';
import { MODAL_TYPE_DISCARD_COMMENT } from './discard-comment-modal/discard-comment-modal-type';
import { MODAL_TYPE_REPORT_POST } from './report-post-modal/report-post-modal-type';
import { MODAL_TYPE_UNFOLLOW_CATEGORY } from './unfollow-category-modal/unfollow-category-modal-type';
import { MODAL_TYPE_CHOOSE_CATEGORY } from './choose-category-modal/choose-category-modal-type';
import { MODAL_TYPE_REPORT_COMMENT } from './report-comment-modal/report-comment-modal-type';
import { MODAL_TYPE_SHARE_COMMENT } from './share-comment-modal/share-comment-modal-type';
import { MODAL_TYPE_SHARE_POST } from './share-post-modal/share-post-modal-type';
import { MODAL_TYPE_CATEGORIES_MANAGER } from './categories-manager/categories-manager-modal-type';
import { MODAL_TYPE_CATEGORIES_MANAGER_CONFIRM_DISCARD } from './categories-manager/discard-changes-modal-type';
import { MODAL_TYPE_CREATE_POST } from './post-create-modal/post-create-modal-type';
import { MODAL_TYPE_CREATE_POST_MOBILE } from './create-post-modal/create-post-modal-type';
import { MODAL_TYPE_EDIT_POST } from './post-create-modal/post-edit-modal-type';
import { MODAL_TYPE_DELETE_CATEGORY } from './delete-category-modal/delete-category-modal-type';
import { MODAL_TYPE_EDIT_COMMENT } from './comment-edit-modal/comment-edit-modal-type';
import { MODAL_TYPE_DEMO_MODE } from './demo-modal/demo-modal-type';
import {
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_SM,
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_BADGES,
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_ROLES,
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_PLANS,
} from './manage-category-members-modal/manage-category-members-modal-type';
import { MODAL_TYPE_MOVE_CATEGORY_CONFIRMATION } from './move-category-confirmation-modal/move-category-confirmation-modal-type';
import { MODAL_TYPE_MOVE_POST_CONFIRMATION } from './move-post-confirmation-modal/move-post-confirmation-modal-type';
import MovePostModal from './move-post-modal/move-post-modal-component';
import DeletePostModal from './delete-post-modal/delete-post-modal-component';
import DeleteCommentModal from './delete-comment-modal/delete-comment-modal-component';
import DeleteCategoryModal from './delete-category-modal/delete-category-modal-component';
import DiscardPostModal from './discard-post-modal/discard-post-modal-component';
import DiscardCommentModal from './discard-comment-modal/discard-comment-modal-component';
import ReportCommentModal from './report-comment-modal/report-comment-modal-component';
import ReportPostModal from './report-post-modal/report-post-modal-component';
import UnfollowCategoryModal from './unfollow-category-modal/unfollow-category-modal-component';
import ChooseCategoryModal from './choose-category-modal/choose-category-modal-component';
import SharePostModal from './share-post-modal/share-post-modal-component';
import ShareCommentModal from './share-comment-modal/share-comment-modal-component';
import CategoriesManager from './categories-manager/categories-manager-modal-component';
import DiscardCategoryChangesModal from './categories-manager/discard-changes-modal-component';
import PostCreateModal from './post-create-modal/post-create-modal-component';
import PostCreateMobileModal from './create-post-modal/create-post-modal-component';
import PostEditModal from './post-create-modal/post-edit-modal-component';
import CommentEditModal from './comment-edit-modal/comment-edit-modal-component';
import DemoModal from './demo-modal/demo-modal-component';
import ManageCategoryMembersModal from './manage-category-members-modal/manage-category-members-sm-modal-component';
import ManageCategoryRolesModal from './manage-category-members-modal/manage-category-members-roles-modal-component';
import ManageCategoryMembersPlansModal from './manage-category-members-modal/manage-category-members-plans-modal-component';
import MovePostConfirmationModal from './move-post-confirmation-modal/move-post-confirmation-modal-component';
import ManageCategoryMembersBadgesModalComponent from './manage-category-members-modal/manage-category-members-badges-modal-component';
import MoveCategoryConfirmation from './move-category-confirmation-modal/move-category-confirmation-modal-component';

export const modalComponentMapByType = {
  [MODAL_TYPE_MOVE_POST]: MovePostModal,
  [MODAL_TYPE_DELETE_POST]: DeletePostModal,
  [MODAL_TYPE_DELETE_COMMENT]: DeleteCommentModal,
  [MODAL_TYPE_DELETE_CATEGORY]: DeleteCategoryModal,
  [MODAL_TYPE_DISCARD_POST]: DiscardPostModal,
  [MODAL_TYPE_DISCARD_COMMENT]: DiscardCommentModal,
  [MODAL_TYPE_REPORT_COMMENT]: ReportCommentModal,
  [MODAL_TYPE_REPORT_POST]: ReportPostModal,
  [MODAL_TYPE_UNFOLLOW_CATEGORY]: UnfollowCategoryModal,
  [MODAL_TYPE_CHOOSE_CATEGORY]: ChooseCategoryModal,
  [MODAL_TYPE_SHARE_POST]: SharePostModal,
  [MODAL_TYPE_SHARE_COMMENT]: ShareCommentModal,
  [MODAL_TYPE_CATEGORIES_MANAGER]: CategoriesManager,
  [MODAL_TYPE_CATEGORIES_MANAGER_CONFIRM_DISCARD]: DiscardCategoryChangesModal,
  [MODAL_TYPE_CREATE_POST]: PostCreateModal,
  [MODAL_TYPE_CREATE_POST_MOBILE]: PostCreateMobileModal,
  [MODAL_TYPE_EDIT_POST]: PostEditModal,
  [MODAL_TYPE_EDIT_COMMENT]: CommentEditModal,
  [MODAL_TYPE_DEMO_MODE]: DemoModal,
  [MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_SM]: ManageCategoryMembersModal,
  [MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_BADGES]: ManageCategoryMembersBadgesModalComponent,
  [MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_ROLES]: ManageCategoryRolesModal,
  [MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_PLANS]: ManageCategoryMembersPlansModal,
  [MODAL_TYPE_MOVE_POST_CONFIRMATION]: MovePostConfirmationModal,
  [MODAL_TYPE_MOVE_CATEGORY_CONFIRMATION]: MoveCategoryConfirmation,
};
