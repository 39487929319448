import { isNil } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ImgBackground, RENDER_IMAGE_WITH_PLACEHOLDER } from '@wix/communities-image-lib';
import { COVER_TYPE_IMAGE, COVER_TYPE_COLOR } from '../../../constants/cover-types';
import { CameraIcon } from '../../icons/camera-icon';
import { DeleteIcon } from '../../icons/delete-icon';
import { ReplaceIcon } from '../../icons/replace-icon';
import ImageLoader from '../../image-loader';
import IconTooltip from '../../icon-tooltip';
import withTranslate from '../../../hoc/with-translate';
import styles from './cover-image-input.scss';

class CoverImageInput extends Component {
  handleImageDelete = () => this.props.onChange({ coverType: COVER_TYPE_COLOR });
  handleImageUpload = ev =>
    this.props.onChange({ coverType: COVER_TYPE_IMAGE, file: ev.target.files[0] });

  renderLoader = () => {
    return (
      <div className={styles.loadingOverlay}>
        <ImageLoader type="mini" />
      </div>
    );
  };

  renderDeleteIcon = () => {
    return (
      <IconTooltip text={this.props.deleteTooltipText} tooltipClassName={styles.deleteTooltip}>
        <button
          aria-label={this.props.t('categories-manager.delete-category-img')}
          onClick={this.handleImageDelete}
          className={styles.deleteIconContainer}
        >
          <DeleteIcon className={styles.deleteIcon} />
        </button>
      </IconTooltip>
    );
  };

  renderUploadInput = isImage => {
    return (
      <div className={classNames(styles.fileInputContainer, this.props.fontClass)}>
        {isImage ? (
          <ReplaceIcon className={styles.fileInputIcon} />
        ) : (
          <CameraIcon className={styles.fileInputIcon} />
        )}
        {isImage ? this.props.changeText : this.props.uploadText}
        <input
          className={styles.fileInput}
          type="file"
          name="cover image"
          accept="image/*"
          onChange={this.handleImageUpload}
        />
      </div>
    );
  };

  render() {
    const { coverType, imgSrc, wrapperClassName, isLoading } = this.props;
    const isImage = coverType === COVER_TYPE_IMAGE && !isNil(imgSrc);

    return (
      <ImgBackground
        src={isImage ? imgSrc : ''}
        renderMode={RENDER_IMAGE_WITH_PLACEHOLDER}
        width={360}
        height={240}
        className={classNames(
          wrapperClassName,
          styles.container,
          isImage ? styles.image : styles.color,
          {
            'forum-profile-cover-background-color': !isImage,
          },
        )}
      >
        {isLoading && this.renderLoader()}
        {!isLoading && this.renderUploadInput(isImage)}
        {!isLoading && isImage && this.renderDeleteIcon()}
      </ImgBackground>
    );
  }
}

CoverImageInput.propTypes = {
  fontClass: PropTypes.string,
  wrapperClassName: PropTypes.string,
  uploadText: PropTypes.string,
  changeText: PropTypes.string,
  deleteTooltipText: PropTypes.string,
  coverType: PropTypes.string,
  imgSrc: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  t: PropTypes.func,
};

export default withTranslate(CoverImageInput);
