import PropTypes from 'prop-types';
import React from 'react';
import styles from './manage-category-members-empty-state.scss';

const EmptyState = ({ title, description, cta }) => (
  <div className={styles.container}>
    <h2 className={styles.title}>{title}</h2>
    <p className={styles.description}>{description}</p>
    {cta}
  </div>
);

EmptyState.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
};

export default EmptyState;
