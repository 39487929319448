import React from 'react';
import withSettingsColor from '../../../hoc/with-settings-color';

const HeaderBodySeparator = ({ backgroundColor }) => (
  <div style={{ height: '8px', backgroundColor }} />
);

export default withSettingsColor({
  propName: 'backgroundColor',
  siteColorFallback: 'color-5',
  siteColorAlpha: 0.05,
  fallbackColor: 'rgba(51, 51, 51, 0.05)',
})(HeaderBodySeparator);
