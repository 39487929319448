import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import { isPrivate } from '../../../common/services/category-privacy-utils';
import { compactNumber } from '../../services/format-number';
import { COVER_TYPE_IMAGE } from '../../constants/cover-types';
import { ImgBackground } from '@wix/communities-image-lib';
import { LockEmptyIcon } from '../icons/lock-empty-icon';
import MoreButton from '../more-button';
import CategoryActions from '../../containers/category-actions';
import Link from '../link/internal-link';
import FollowButton from '../follow-button/follow-button';
import {
  isCacheableRendering,
  getLanguage,
} from '../../../common/store/basic-params/basic-params-selectors';
import withFontClassName from '../../hoc/with-font-class-name';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../hoc/with-translate';
import styles from './category-list-item-desktop.scss';

export class CategoryListItemDesktop extends Component {
  hasImage = () => this.props.category.coverType === COVER_TYPE_IMAGE;

  renderImageIfNeeded = () => {
    const image = this.hasImage() && (
      <ImgBackground
        className={styles.coverImage}
        src={this.props.category.cover}
        width={100}
        height={60}
        aria-labelledby={`cl-${this.props.category._id}`}
      />
    );
    return image && this.linkToPosts(image);
  };

  renderContentBlock = () => {
    const { titleFontClassName, category, t } = this.props;
    let categoryBadge = null;
    if (isPrivate(category)) {
      categoryBadge = (
        <span className={styles.categoryBadgeWrapper}>
          <LockEmptyIcon className={'forum-icon-fill'} />
        </span>
      );
    }
    const titleText = (
      <h3
        className={classNames(styles.title, titleFontClassName)}
        data-hook="category-list-item__title"
        id={`cl-${this.props.category._id}`}
      >
        {category.label} {categoryBadge}
      </h3>
    );
    return (
      <div className={styles.contentBlock}>
        {this.linkToPosts(titleText, 'category-list-item__link')}
        <span className={styles.description} data-hook="category-list-item__description">
          {category.description}
        </span>
      </div>
    );
  };

  renderNumbersElement = (number, text, dataHook) => (
    <div className={styles.numberElement} data-hook={dataHook}>
      <span className={classNames(styles.numberElementHeader, this.props.titleFontClassName)}>
        {number}
      </span>
      <span className={styles.numberElementText}>{text}</span>
    </div>
  );

  fetchCategoryPosts = () => {
    const { category, fetchCategoryPosts } = this.props;
    fetchCategoryPosts({ categoryId: category._id, page: 1 });
  };

  linkToPosts = (children, dataHook) => {
    const { category, fetchCategoryPosts } = this.props;
    return (
      <Link
        className={classNames('forum-text-color', 'forum-link-hover-color')}
        to={`/${category.slug}`}
        onMouseDown={() => fetchCategoryPosts({ categoryId: category._id, page: 1 })}
        data-hook={dataHook}
      >
        {children}
      </Link>
    );
  };

  render() {
    const {
      category,
      contentFontClassName,
      isViewsCountEnabled,
      showViewCount,
      language,
      t,
      can,
    } = this.props;
    const shouldShowMoreButton = can('edit', 'category', category);
    const compactNumberLocal = compactNumber(language);

    const moreButton = (
      <MoreButton size={MoreButton.SIZE_SMALL} data-hook="category-list-item__category-actions">
        <CategoryActions hideFollowButton category={category} />
      </MoreButton>
    );
    const followButton = (
      <FollowButton
        category={category}
        className={shouldShowMoreButton ? styles.followButton : ''}
        data-hook="category-list-item__follow-button"
      >
        {t(category.isSubscribed ? 'category-actions.unfollow' : 'category-actions.follow')}
      </FollowButton>
    );
    const categoryActions = (
      <div className={styles.actionsContainer}>
        {followButton}
        {shouldShowMoreButton && moreButton}
      </div>
    );
    const categoryContent = (
      <div className={classNames(styles.main, { [styles.hasImage]: this.hasImage() })}>
        <div className={classNames(styles.contentPreview, 'forum-text-color')}>
          {this.renderImageIfNeeded()}
          {this.renderContentBlock()}
        </div>
        <div className={classNames(styles.numbers, { [styles.viewsHidden]: !isViewsCountEnabled })}>
          {isViewsCountEnabled &&
            this.renderNumbersElement(
              showViewCount ? compactNumberLocal(category.totalViews) : <span>&nbsp;</span>,
              t('subcategory-list-item.views'),
              'category-list-item__total-views',
            )}
          {this.renderNumbersElement(
            compactNumberLocal(category.totalPosts),
            t('subcategory-list-item.posts', 'subcategories-list-item__total-posts'),
            'category-list-item__total-posts',
          )}
        </div>
      </div>
    );

    return (
      <div
        className={classNames(styles.container, contentFontClassName)}
        data-hook="category-list-item"
      >
        {categoryContent}
        {categoryActions}
      </div>
    );
  }
}

CategoryListItemDesktop.propTypes = {
  category: PropTypes.object.isRequired,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  isViewsCountEnabled: PropTypes.bool,
  fetchCategoryPosts: PropTypes.func,
  can: PropTypes.func,
  t: PropTypes.func,
  showViewCount: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  fetchCategoryPosts: actions.fetchCategoryPosts,
  showViewCount: !isCacheableRendering(state),
  language: getLanguage(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withFontClassName,
  withPermissions,
)(CategoryListItemDesktop);
