import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../header';
import HeaderBackButton from '../../header-back-button';
import { TextEditorMobileToolbar } from '../../styled-text-editor';
import { PostPublishButton } from '../../../containers/publish-button/publish-button';

const PostEditModalHeader = ({ onBack }) => (
  <Header
    left={<HeaderBackButton onClick={onBack} buttonComponent="button" />}
    right={<PostPublishButton text="post-edit-page-header.update" formName="post" />}
    menu={<TextEditorMobileToolbar identifier="post" />}
  />
);

PostEditModalHeader.propTypes = {
  onBack: PropTypes.func,
};

export default PostEditModalHeader;
