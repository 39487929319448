import React from 'react';

export const DiscardIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    viewBox="0 0 16 16"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path d="M11.23,0H4.68L0,4.71v6.67L4.68,16h6.63L16,11.29V4.71Zm.59,10.75L10.57,12,8,9.38,5.35,12,4.1,10.75,6.71,8.13,4.1,5.52,5.35,4.27,8,6.88l2.61-2.61,1.25,1.25L9.21,8.13Z" />
  </svg>
);
