import React from 'react';

export const CollapseIcon = props => (
  <svg
    width="15px"
    height="15px"
    viewBox="0 0 15 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 Z M7.5,14 C11.0898509,14 14,11.0898509 14,7.5 C14,3.91014913 11.0898509,1 7.5,1 C3.91014913,1 1,3.91014913 1,7.5 C1,11.0898509 3.91014913,14 7.5,14 Z M4,7 L11,7 L11,8 L4,8 L4,7 Z" />
  </svg>
);
