import React from 'react';

export const EyeIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18px"
    height="15px"
    viewBox="0 0 18 15"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <g
      transform="translate(-134.000000, -16.000000)"
      stroke="none"
      strokeWidth="1"
      fillRule="evenodd"
      fill="#345572"
    >
      <g>
        <g>
          <g transform="translate(133.000000, 16.000000)">
            <g>
              <path d="M18,7.75 L18.08,7.56 L18,7.37 C17.9,7.12 15.36,1.29 9.54,1.29 C8.0431704,1.2821901 6.57417316,1.69447942 5.3,2.48 L2.82,0 L2.25,0.57 L4.61,2.93 C3.08829914,4.09783171 1.87339776,5.61817439 1.07,7.36 L0.99,7.55 L1.07,7.74 C1.17,7.99 3.71,13.82 9.53,13.82 C11.1628819,13.8256458 12.7593463,13.3376436 14.11,12.42 L16.25,14.56 L16.82,13.99 L14.77,11.94 C16.1484163,10.8043497 17.2525505,9.37205188 18,7.75 L18,7.75 Z M9.54,12.83 C4.95,12.83 2.54,8.53 2.09,7.56 C2.85191213,6.02273622 3.96533126,4.68663327 5.34,3.66 L7.28,5.6 C6.35068816,6.79425986 6.45629256,8.49367141 7.52631058,9.56368942 C8.59632859,10.6337074 10.2957401,10.7393118 11.49,9.81 L13.37,11.69 C12.2300025,12.4314377 10.899896,12.8273441 9.54,12.83 L9.54,12.83 Z M11.3,8.48 L8.61,5.79 C8.89291588,5.63720948 9.20849962,5.55488329 9.53,5.55 C10.6345695,5.55 11.53,6.4454305 11.53,7.55 C11.5298084,7.87405532 11.4508774,8.19321105 11.3,8.48 L11.3,8.48 Z M10.79,9.1 C10.4367808,9.38804966 9.99576999,9.54681354 9.54,9.55 C8.4354305,9.55 7.54,8.6545695 7.54,7.55 C7.54318646,7.09423001 7.70195034,6.65321923 7.99,6.3 L10.79,9.1 Z M14.04,11.22 L12.04,9.22 C12.8326969,8.03017619 12.6756193,6.4462853 11.664667,5.43533299 C10.6537147,4.42438068 9.06982381,4.26730306 7.88,5.06 L6.04,3.22 C7.1039235,2.60486054 8.31104543,2.28066208 9.54,2.28 C14.13,2.28 16.54,6.58 16.99,7.55 C16.2804385,8.97022273 15.2744745,10.22171 14.04,11.22 L14.04,11.22 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
