import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import PostListItem from '../post-list-item/post-list-item';
import CommentListItem from '../comment-search-list-item';
import Pagination from '../pagination-regular';
import { HorizontalSeparator } from '../separator';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import styles from './search-results-list.scss';
import { SortingSelectSearchPageDesktop } from '../sorting-select-search';
import { scrollBy } from '../../services/scroll-by';
import { getElementsPositionToRootWindow } from '../../services/get-elements-position';

const POST = 'post';
const COMMENT = 'comment';
const ITEMS_PER_PAGE = 20;

class SearchResultsDesktopList extends Component {
  state = {
    page: 1,
    from: 0,
    to: ITEMS_PER_PAGE,
  };

  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  //temporary fix - scroll to top on search result page switching
  scrollToTop() {
    const containerTop = this.containerRef.current.getBoundingClientRect().top;
    if (containerTop < 0) {
      getElementsPositionToRootWindow(this.containerRef.current, { top: true }, y => {
        scrollBy(0, y);
      });
    }
  }

  handlePageChange({ page, from, to }) {
    this.scrollToTop();
    this.setState({ page, from, to });
  }

  renderItem(post, query, onPostLikeClick, onCommentLikeClick) {
    const separator = (
      <div className={styles.separator} key={`${post._id}-separator`}>
        <HorizontalSeparator />
      </div>
    );
    switch (post.type) {
      case POST:
        return [
          <PostListItem
            key={post._id}
            type={PostListItem.SEARCH}
            post={post}
            query={query}
            onLikeClick={onPostLikeClick}
            origin="search_results"
          />,
          separator,
        ];
      case COMMENT:
        return [
          <CommentListItem
            key={post._id}
            type={PostListItem.SEARCH}
            comment={post}
            query={query}
            onCommentLikeClick={onCommentLikeClick}
            onPostLikeClick={onPostLikeClick}
          />,
          separator,
        ];
      default:
        return null;
    }
  }

  renderPagination() {
    const { posts } = this.props;
    const { page } = this.state;

    return (
      <Pagination
        className={classNames(styles.pagination, 'forum-card-background-color')}
        page={page}
        entityCount={posts.length}
        showPerPage={ITEMS_PER_PAGE}
        onChange={this.handlePageChange}
      />
    );
  }

  render() {
    const { posts, query, onPostLikeClick, onCommentLikeClick, borderWidth } = this.props;
    const { from, to } = this.state;
    const style = {
      borderWidth,
      marginTop: -borderWidth,
    };

    return (
      <div
        className={classNames(styles.container, 'search-results-list', 'forum-card-border-color')}
        style={style}
        ref={this.containerRef}
      >
        <div className={classNames(styles.header)}>
          {this.renderPagination()}
          <div className={styles.sortSelectContainer}>
            <div className={styles.sortSelect}>
              <SortingSelectSearchPageDesktop />
            </div>
          </div>
        </div>
        <HorizontalSeparator />
        <div className={classNames(styles.wrapper)}>
          {posts
            .slice(from, to)
            .map(post => this.renderItem(post, query, onPostLikeClick, onCommentLikeClick))}
          {this.renderPagination()}
        </div>
      </div>
    );
  }
}

SearchResultsDesktopList.propTypes = {
  posts: PropTypes.array.isRequired,
  borderWidth: PropTypes.number.isRequired,
  query: PropTypes.string,
  onPostLikeClick: PropTypes.func.isRequired,
  onCommentLikeClick: PropTypes.func.isRequired,
};

export default withCardBorderWidth(SearchResultsDesktopList);
