import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import SymbolsLeftCounter from '../../symbols-left-counter';
import PostSettingsLabelWithTooltip from './category-settings-label-with-tooltip';
import styles from './category-settings-input-label.scss';

const CategorySettingsInputLabel = ({
  htmlFor,
  labelText,
  tooltipText,
  value,
  limit,
  showInfo,
}) => (
  <div className={styles.container}>
    <PostSettingsLabelWithTooltip
      labelProps={{ htmlFor }}
      labelText={labelText}
      tooltipText={tooltipText}
    />
    {limit && showInfo && (
      <div className={styles.counter} data-hook="post-settings-input-label__tooltip-counter">
        <SymbolsLeftCounter
          value={value}
          limit={limit}
          positiveClassName={classNames(styles.positive)}
          zeroClassName={classNames(styles.zero)}
        />
      </div>
    )}
  </div>
);

CategorySettingsInputLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  tooltipText: PropTypes.string,
  value: PropTypes.string,
  limit: PropTypes.number,
  showInfo: PropTypes.bool,
};

export default CategorySettingsInputLabel;
