import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withTranslate from '../../hoc/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import BackButton from '../../containers/back-button';
import ArrowBackIcon from '../icons/arrow-back-icon';
import styles from './header-back-button.scss';

const HeaderBackButton = ({ text, t, buttonComponent, onClick, contentFontClassName }) => {
  const Btn = buttonComponent ? buttonComponent : BackButton;
  return (
    <Btn onClick={onClick} className={classNames(styles.button, 'header-back-button')}>
      <span className={styles.wrapper}>
        <ArrowBackIcon className="forum-icon-fill" />
        <span>
          <span className={classNames(styles.label, 'forum-text-color', contentFontClassName)}>
            {text && t(text)}
          </span>
        </span>
      </span>
    </Btn>
  );
};

HeaderBackButton.propTypes = {
  text: PropTypes.string,
  t: PropTypes.func,
  onClick: PropTypes.func,
  buttonComponent: PropTypes.any,
  contentFontClassName: PropTypes.string,
};

HeaderBackButton.defaultProps = {
  text: 'header-back-button.back',
};

export default flowRight(
  withTranslate,
  withFontClassName,
)(HeaderBackButton);
