import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { GROUP_TYPE_ROLE } from '../../../constants/manage-category-members';
import withTranslate from '../../../hoc/with-translate';
import withFontClassName from '../../../hoc/with-font-class-name';
import { getCategoryById } from '../../../../recent-posts-widget/selectors/categories-selectors';
import { getMetaSiteId } from '../../../../common/store/instance-values/instance-values-selectors';
import ManageCategoryMembersListItem from './manage-category-members-list-item';
import ManageCategoryMembersBaseModal from './manage-category-members-base-modal-component';

class ManageCategoryMembersRolesModal extends Component {
  render() {
    const { category, metaSiteId, closeModal } = this.props;
    const manageRolesLink = `https://www.wix.com/dashboard/${metaSiteId}/member-permissions/roles`;
    const createNewGroupLink = `https://www.wix.com/dashboard/${metaSiteId}/member-permissions/roles/new`;

    return (
      <ManageCategoryMembersBaseModal
        closeModal={closeModal}
        category={category}
        groupType={GROUP_TYPE_ROLE}
        createNewGroupLink={createNewGroupLink}
        manageGroupsLink={manageRolesLink}
        titleTextKey="manage-category-members-roles-modal.title"
        bottomCtaTextKey="manage-category-members-modal.manage-roles-link"
        emptyStateTitleTextKey="manage-category-members-roles-empty-state.you-dont-have-any-roles"
        emptyStateDescriptionTextKey="manage-category-members-roles-empty-state.to-create-roles-go-to"
        emptyStateCtaTextKey="manage-category-members-roles-empty-state.cta"
        searchEmptyStateTextKey="manage-category-members-roles-search-empty-state.no-results-found"
        renderListItem={(group, formApi) => (
          <ManageCategoryMembersListItem
            isRole
            group={group}
            formApi={formApi}
            category={category}
          />
        )}
      />
    );
  }
}

ManageCategoryMembersRolesModal.propTypes = {
  closeModal: PropTypes.func,
  category: PropTypes.object,
  metaSiteId: PropTypes.string,
  t: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps) => ({
  category: getCategoryById(state, ownProps.categoryId),
  metaSiteId: getMetaSiteId(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withFontClassName,
  withTranslate,
)(ManageCategoryMembersRolesModal);
