import { get, find } from 'lodash';
import tinyColor from 'tinycolor2';

const throwRequired = name => {
  throw new Error(`${name} is required`);
};

const getStyleColor = (style, wixParam) => get(style, `styleParams.colors[${wixParam}].value`);

export const getPresetColor = (siteColors, presetName) =>
  get(find(siteColors, ({ reference }) => reference === presetName), 'value');

export const setColorOpacity = (color, alpha = 1) =>
  tinyColor(color)
    .setAlpha(alpha)
    .toRgbString();

export const getSiteColor = (
  style,
  { wixParam, presetFallback, colorFallback, opacity = undefined },
) => {
  if (!wixParam) throwRequired('wixParam');
  if (!presetFallback) throwRequired('presetFallback');
  if (!colorFallback) throwRequired('colorFallback');
  const color =
    getStyleColor(style, wixParam) ||
    getPresetColor(style.siteColors, presetFallback) ||
    colorFallback;
  return opacity !== undefined ? setColorOpacity(color, opacity) : tinyColor(color).toRgbString();
};
