import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { ModalCentered } from '../base';
import Confirmation from '../../confirmation/confirmation';
import withTranslate from '../../../hoc/with-translate';

export const UnfollowCategoryModal = ({ closeModal, unfollowCategory, t }) => (
  <ModalCentered onClose={closeModal}>
    <Confirmation
      confirm={t('unfollow-category-modal.unfollow')}
      cancel={t('unfollow-category-modal.cancel')}
      title={t('unfollow-category-modal.title')}
      onConfirm={unfollowCategory}
      onCancel={closeModal}
    >
      <p>{t('unfollow-category-modal.are-you-sure')}</p>
    </Confirmation>
  </ModalCentered>
);

UnfollowCategoryModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  unfollowCategory: PropTypes.func.isRequired,
  t: PropTypes.func,
  categoryId: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, ownProps) => ({
  unfollowCategory: () => ownProps.closeModal(ownProps.categoryId),
});

export default connect(mapRuntimeToProps)(withTranslate(UnfollowCategoryModal));
