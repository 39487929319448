import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { DragDropContext } from 'react-dnd';
import forDevice from '../../hoc/for-device';

import { default as ListContainerRaw, metaProperties, IS_CHILD } from './list-container';
import * as utils from './utils.js';

/* eslint-disable new-cap */
const ListContainer = forDevice(
  DragDropContext(TouchBackend)(ListContainerRaw),
  DragDropContext(HTML5Backend)(ListContainerRaw),
);

export { ListContainer, metaProperties, IS_CHILD, utils };
