import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import { APP_TEXT_COLOR_PATH } from '../../constants/wix-params';
import withSettingsColor from '../../hoc/with-settings-color';
import withTranslate from '../../hoc/with-translate';
import styles from './input-radio.scss';

// eslint-disable-next-line
const InputRadio = ({ text, tabIndex, disabledRadioColor, siteColors, isNewFeature, t, ...props }) => (
  <label
    for={props.value || text}
    className={classNames(styles.label, { [styles.disabled]: props.disabled })}
  >
    <input id={props.value || text} className={styles.radio} type="radio" {...props} />
    <span
      style={{ borderColor: props.disabled ? disabledRadioColor : '' }}
      className={classNames(styles.fakeRadio, { 'button-border-color': !props.disabled })}
      data-hook="input-radio"
    />
    <span
      style={{ borderColor: props.disabled ? disabledRadioColor : '' }}
      className={classNames(styles.fakeRadioChecked, { 'button-border-color': !props.disabled })}
    >
      <span
        style={{ backgroundColor: props.disabled ? disabledRadioColor : '' }}
        className={classNames(styles.innerCircle, { 'button-background-color': !props.disabled })}
      />
    </span>
    <span role="radio" aria-checked={props.checked} tabIndex={tabIndex} className={styles.text}>
      {text}
    </span>
    {isNewFeature && (
      <span
        className={classNames(
          styles.newFeature,
          'button-background-color',
          'button-primary-text-color',
        )}
      >
        {t('new-feature-indicator.radio-option')}
      </span>
    )}
  </label>
);

InputRadio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  text: PropTypes.node,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  tabIndex: PropTypes.string.isRequired,
  disabledRadioColor: PropTypes.string,
  siteColors: PropTypes.object,
  isNewFeature: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

InputRadio.defaultProps = {
  isNewFeature: false,
};

export default flowRight(
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'disabledRadioColor',
    siteColorFallback: 'color-5',
  }),
  withTranslate,
)(InputRadio);
