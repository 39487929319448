import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { ModalCentered } from '../base';
import ReportAbuseForm from '../../report-abuse-form';
import withTranslate from '../../../hoc/with-translate';

export const ReportPostModal = ({ closeModal, reportPost, t }) => (
  <ModalCentered onClose={closeModal}>
    <ReportAbuseForm
      onSubmit={reportPost}
      onCancel={closeModal}
      title={t('report-post-modal.report-post')}
      message={t('report-post-modal.help-us-understand')}
    />
  </ModalCentered>
);

ReportPostModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  reportPost: PropTypes.func.isRequired,
  postId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (dispatch, ownProps) => ({
  reportPost: report => ownProps.closeModal({ reportType: report.value, postId: ownProps.postId }),
});

export default connect(mapRuntimeToProps)(withTranslate(ReportPostModal));
