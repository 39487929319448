import { flowRight, omit, isEqual, isNil } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COVER_TYPE_IMAGE } from '../../../constants/cover-types';
import { EXPERIMENT_PRIVATE_CATEGORIES } from '../../../constants/experiments';
import IconTooltip from '../../icon-tooltip';
import { ListContainer, metaProperties, IS_CHILD, utils } from '../../tree-list-dnd';
import ManageCategoriesCard from './manage-categories-card.js';
import ManageCategoriesCardDndPart from './manage-categories-card-dnd-part';
import { LockEmptyIcon } from '../../icons/lock-empty-icon';
import { MembersIcon } from '../../icons/members-icon';
import withSettingsColor from '../../../hoc/with-settings-color';
import withDeviceType from '../../../hoc/with-device-type';
import withTranslate from '../../../hoc/with-translate';
import withExperiment from '../../../hoc/with-experiment';
import styles from './manage-categories-list.scss';
import { connect } from '../../../../common/components/runtime-context';
import { MODAL_TYPE_MOVE_CATEGORY_CONFIRMATION } from '../move-category-confirmation-modal/move-category-confirmation-modal-type';
import { isMembersOnly, isPrivate } from '../../../../common/services/category-privacy-utils';
import { findById } from '../../tree-list-dnd/utils';

export function transformDataIn(categories) {
  return categories.map(({ _id, parentId }) => ({
    id: _id,
    [IS_CHILD]: !isNil(parentId),
  }));
}

export function transformDataOut(data, categories) {
  return data.map((item, i) => {
    const { id } = item;
    const category = categories.find(c => c._id === id);

    return omit(
      {
        ...category,
        _id: id,
        rank: i,
        parentId: item[IS_CHILD] ? utils.getParent(item, data).id : null,
      },
      metaProperties,
    );
  });
}

function createListItemRenderer({
  categories,
  onEditCategory,
  onDeleteCategory,
  iconsColor,
  darkIconColor,
  iconActiveColor,
  isMobile,
  t,
  isPrivateCategoriesEnabled,
}) {
  return ({ id }, props) => {
    const category = findById(categories, id);
    const isOneRemainingCategory = categories.length <= 1;

    if (!category) {
      return null;
    }

    const { coverType, label, cover } = category;
    const showCollapsed = props.isChildrenHidden && !props.isDragPreview;
    let icon = null;
    if (isPrivateCategoriesEnabled) {
      if (isPrivate(category)) {
        icon = (
          <IconTooltip
            text={t('manage-categories-list.specific-members-only-tooltip')}
            placement="left"
          >
            <LockEmptyIcon style={{ fill: darkIconColor, marginRight: '8px' }} />
          </IconTooltip>
        );
      } else if (isMembersOnly(category)) {
        icon = (
          <IconTooltip text={t('manage-categories-list.members-only-tooltip')} placement="left">
            <MembersIcon style={{ fill: darkIconColor, marginRight: '8px' }} />
          </IconTooltip>
        );
      }
    } else if (isMembersOnly(category)) {
      icon = (
        <IconTooltip text={t('manage-categories-list.members-only-tooltip')}>
          <LockEmptyIcon style={{ fill: darkIconColor, marginRight: '8px' }} />
        </IconTooltip>
      );
    }

    const listItem = (
      <div className={styles.listItemContainer}>
        <div
          className={styles.onTop}
          style={{
            display: props.showDropPlaceholder ? 'block' : 'none',
          }}
        >
          <div className={classNames(styles.onTop, styles.dropPlaceholder)} />
          <div className={classNames(styles.onTop, styles.dropPlaceholderBackdrop)} />
        </div>

        <ManageCategoriesCard
          onEdit={() => onEditCategory(id)}
          onDelete={isOneRemainingCategory ? null : () => onDeleteCategory(id)}
          onMoveToChild={props.moveToChild}
          onMoveToTop={props.moveToParent}
          image={coverType === COVER_TYPE_IMAGE ? cover : null}
          title={label}
          isCollapsed={showCollapsed}
          frontComponent={
            <ManageCategoriesCardDndPart
              {...props}
              iconsColor={iconsColor}
              darkIconColor={darkIconColor}
              iconActiveColor={iconActiveColor}
            />
          }
          backComponent={icon}
        />
      </div>
    );

    return isMobile ? listItem : props.connectDragSource(listItem);
  };
}

function createListItemPreviewRenderer({ categories, iconsColor, iconActiveColor, isMobile }) {
  return (
    { id }, // eslint-disable-line
    props,
  ) => {
    const { coverType, label, cover } = findById(categories, id);
    return (
      <div style={{ width: isMobile ? '260px' : '345px' }}>
        <ManageCategoriesCard
          image={coverType === COVER_TYPE_IMAGE ? cover : null}
          title={label}
          frontComponent={
            <ManageCategoriesCardDndPart
              {...props}
              iconsColor={iconsColor}
              iconActiveColor={iconActiveColor}
              isDragPreview
            />
          }
        />
      </div>
    );
  };
}

class ManageCategoriesList extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps.categories, this.props.categories);
  }
  reorderCategories = (data, movedItemIndex, method) => {
    this.props.onReorderCategories(
      transformDataOut(data, this.props.categories),
      movedItemIndex,
      method,
    );
  };

  maybeShowConfirmationModal = ({ newParentId, movedItemId }) => {
    const newParent = this.findCategory(newParentId);
    const movedCategory = this.findCategory(movedItemId);

    return newParent && isPrivate(newParent) && movedCategory.parentId !== newParent._id
      ? new Promise((resolve, reject) =>
          this.props
            .openModal(MODAL_TYPE_MOVE_CATEGORY_CONFIRMATION, {
              parent: newParent.label,
              child: movedCategory.label,
            })
            .then(res => (res ? resolve() : reject())),
        )
      : Promise.resolve();
  };

  findCategory = id => this.props.categories.find(c => c._id === id);

  render() {
    const {
      onEditCategory,
      onDeleteCategory,
      categories,
      iconsColor,
      cardBorderColor,
      iconActiveColor,
      darkIconColor,
      isMobile,
      t,
      isPrivateCategoriesEnabled,
    } = this.props;

    return (
      <ListContainer
        data={transformDataIn(categories)}
        renderListItem={createListItemRenderer({
          categories,
          onEditCategory,
          onDeleteCategory,
          iconsColor,
          darkIconColor,
          iconActiveColor,
          isMobile,
          t,
          isPrivateCategoriesEnabled,
        })}
        renderListItemPreview={createListItemPreviewRenderer({
          categories,
          iconsColor,
          iconActiveColor,
          isMobile,
        })}
        onDragEnd={this.reorderCategories}
        onBeforeMove={isPrivateCategoriesEnabled && this.maybeShowConfirmationModal}
        childrenIndentation={24}
        listItemClassName={styles.listItemClassName}
        childConnectorColor={cardBorderColor}
        childConnectorMargin={isMobile ? 12 : 6}
        withChildren
        categories={categories}
      />
    );
  }
}

ManageCategoriesList.propTypes = {
  isMobile: PropTypes.bool,
  categories: PropTypes.array,
  onEditCategory: PropTypes.func,
  onDeleteCategory: PropTypes.func,
  onReorderCategories: PropTypes.func,
  iconsColor: PropTypes.string,
  cardBorderColor: PropTypes.string,
  iconActiveColor: PropTypes.string,
  darkIconColor: PropTypes.string,
  siteColors: PropTypes.object,
  t: PropTypes.func,
  isPrivateCategoriesEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  openModal: actions.openModal,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withDeviceType,
  withSettingsColor({
    propName: 'cardBorderColor',
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.2,
    fallbackColor: 'rgba(51, 51, 51, 0.2)',
  }),
  withSettingsColor({
    propName: 'iconsColor',
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.4,
    fallbackColor: 'rgba(51, 51, 51, 0.4)',
  }),
  withSettingsColor({
    propName: 'darkIconColor',
    siteColorFallback: 'color-5',
    fallbackColor: 'rgba(51, 51, 51, 1)',
  }),
  withSettingsColor({
    propName: 'iconActiveColor',
    siteColorFallback: 'color-5',
    fallbackColor: 'rgba(51, 51, 51, 1)',
  }),
  withTranslate,
  withExperiment({
    isPrivateCategoriesEnabled: EXPERIMENT_PRIVATE_CATEGORIES,
  }),
)(ManageCategoriesList);
