import { flowRight, forOwn } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import HeaderButton from '../../components/header-button';
import postFormSettings from '../../services/post-form-settings';
import commentFormSettings from '../../services/comment-form-settings';
import { REQUIRE_POST_FIELDS, ASSETS_ARE_UPLOADING } from '../../components/messages/message-types';
import { connect } from '../../../common/components/runtime-context';
import { withFastForm } from '../../../common/components/fast-form';

export class PublishButton extends Component {
  handleClick = () => {
    const {
      submit,
      showMessage,
      fastForm: { isValid, errors },
    } = this.props;

    if (isValid) {
      return submit();
    }

    const emptyFields = [];
    forOwn(errors, (error, field) => {
      if (error === 'required') {
        emptyFields.push(field);
      }
    });

    if (emptyFields.length) {
      showMessage(REQUIRE_POST_FIELDS);
    }

    if (errors.content === 'uploading') {
      showMessage(ASSETS_ARE_UPLOADING);
    }
  };

  render = () => {
    const {
      text,
      fastForm: { errors, isSubmitting },
    } = this.props;

    return (
      <HeaderButton
        text={text}
        onClick={this.handleClick}
        isMuted={errors.content === 'uploading' || isSubmitting}
        data-role="publish"
      />
    );
  };
}

PublishButton.propTypes = {
  text: PropTypes.string,
  isValid: PropTypes.bool,
  submit: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  fastForm: PropTypes.object,
  formName: PropTypes.string,
};

PublishButton.defaultProps = {
  text: 'publish-button.publish',
  formName: 'post',
};

const mapRuntimeToProps = (state, { fastForm: { submit }, overrideSubmit }, actions) => ({
  submit: () => {
    overrideSubmit ? overrideSubmit() : submit();
  },
  showMessage: actions.showMessage,
});

export const PostPublishButton = flowRight(
  withFastForm(postFormSettings, { skipInitialize: true }),
  connect(mapRuntimeToProps),
)(PublishButton);

export const CommentPublishButton = flowRight(
  withFastForm(commentFormSettings, { skipInitialize: true }),
  connect(mapRuntimeToProps),
)(PublishButton);
