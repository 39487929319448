import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../header';
import HeaderBackButton from '../../header-back-button';
import { TextEditorMobileToolbar } from '../../styled-text-editor';
import { PostPublishButton } from '../../../containers/publish-button/publish-button';

const PostCreateModalHeader = ({ onBack, isInCategory, overrideSubmit }) => (
  <Header
    left={<HeaderBackButton onClick={onBack} buttonComponent="button" />}
    right={
      <PostPublishButton
        text={isInCategory ? undefined : 'publish-button.continue'}
        overrideSubmit={overrideSubmit}
        formName="post"
      />
    }
    menu={<TextEditorMobileToolbar identifier="post" />}
  />
);

PostCreateModalHeader.propTypes = {
  onBack: PropTypes.func,
  isInCategory: PropTypes.bool,
  overrideSubmit: PropTypes.func,
};

export default PostCreateModalHeader;
