import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { TopCommentIcon } from '../icons/top-comment-icon';
import withTranslate from '../../hoc/with-translate';
import styles from './top-comment.scss';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_MARK_AS_SOLVED } from '../../constants/experiments';
import { flowRight } from 'lodash';
import { SolvedIcon } from '../icons/solved-icon';

const TopCommentDesktop = ({ t, isMarkAsSolvedExperimentEnabled }) => (
  <div className={styles.topComment} data-hook="top-comment">
    {isMarkAsSolvedExperimentEnabled ? (
      <SolvedIcon className="button-fill" />
    ) : (
      <TopCommentIcon className="button-fill" />
    )}
    <span className={classNames(styles.topCommentLabel, 'button-color')}>
      {t(isMarkAsSolvedExperimentEnabled ? 'comment.solved.best' : 'comment.top-comment')}
    </span>
  </div>
);

TopCommentDesktop.propTypes = {
  t: PropTypes.func,
};

export default flowRight(
  withTranslate,
  withExperiment({ isMarkAsSolvedExperimentEnabled: EXPERIMENT_MARK_AS_SOLVED }),
)(TopCommentDesktop);
